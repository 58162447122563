<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent :max-width="dialogMaxWidth">
      <v-card>
        <ModalTitle :title="title" />

        <WaitModal :show="isDataLoading" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <v-form ref="userModalForm" lazy-validation>
            <v-row>
              <v-col cols="12" :md="userId != null ? 8 : 12">
                <v-text-field
                  ref="firstName"
                  v-model="formData.firstName"
                  filled
                  :label="$t('firstName')"
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />
                <v-text-field
                  ref="lastName"
                  v-model="formData.lastName"
                  filled
                  :label="$t('lastName')"
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />

                <v-autocomplete
                  ref="accessGroups"
                  v-model="formData.accessGroups"
                  :items="allAccessGroups"
                  deletable-chips
                  small-chips
                  item-text="name"
                  item-value="id"
                  multiple
                  filled
                  :label="$t('user.accessGroups')"
                />

                <v-text-field
                  ref="email"
                  v-model="formData.email"
                  filled
                  :label="$t('user.email')"
                  class="required-indicator"
                  :rules="[validationRules.required, validationRules.email]"
                  validate-on-blur
                  :disabled="!!userId"
                />
              </v-col>
              <v-col v-if="userId != null" cols="12" md="4">
                <detail-field
                  :id="getIdByName('createdAt')"
                  ref="createdAt"
                  v-model="formData.createdAt"
                  :label="$t('user.createdAt')"
                />

                <detail-field
                  :id="getIdByName('lastLogin')"
                  ref="lastLogin"
                  v-model="formData.lastLogin"
                  :label="$t('user.lastLogin')"
                />
              </v-col>
            </v-row>
            <RequiredFieldsLegend />
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            :id="getIdByName('save')"
            ref="save"
            text
            color="primary"
            :disabled="isDataLoading"
            @click.stop="createOrUpdateAction()"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import userService from '@/services/userService';
import { UserFormData } from './UserFormData';

export default {
  name: 'UserModal',

  components: {},
  mixins: [translation, accessibility, validationRulesMixin],

  props: {
    userId: {
      type: Number,
      required: false,
      default: null,
    },
    isCurrentUser: {
      type: Boolean,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      isDataLoading: false,
      title: '',
      formData: new UserFormData(),
      originalFormData: {},
      allAccessGroups: [],
    };
  },
  computed: {
    dialogMaxWidth: function () {
      return this.userId !== null ? '1200px' : '800px';
    },
  },
  watch: {
    show: async function () {
      if (this.show) {
        await this.getAllAccessGroups();
        if (this.userId != null) {
          await this.editClicked();
        } else {
          this.init();
        }
      } else {
        this.formData.reset();
      }
    },
  },
  methods: {
    init: function () {
      this.title = this.$t('user.createNewUser');
      this.formData.reset();
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
    },

    async editClicked() {
      this.title = this.$t('user.editUser');
      this.isDataLoading = true;

      try {
        let user = await userService.getUserById(this.userId);
        this.formData.setData(user);
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      } catch (error) {
        this.error = error;
      }
      this.isDataLoading = false;
    },

    async createOrUpdateAction() {
      var isValid = this.$refs.userModalForm.validate();

      if (isValid) {
        this.isDataLoading = true;

        var data = {
          firstName: this.formData.firstName,
          lastName: this.formData.lastName,
          email: this.formData.email,
          accessGroups: this.formData.accessGroups,
        };

        try {
          if (this.userId === null) {
            await userService.createUser(data);
          } else {
            await userService.updateUser(this.userId, data);
          }
          this.closeDialog();
          this.$emit('refresh', true);
        } catch (error) {
          this.error = error;
        }
        this.isDataLoading = false;
      }
    },

    async getAllAccessGroups() {
      try {
        this.allAccessGroups = await userService.getAllAccessGroups();
      } catch (error) {
        this.error = error;
      }
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
  },
};
</script>

<style scoped>
.dialog-tabs.v-tabs-items {
  overflow: visible;
}
</style>
