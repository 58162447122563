<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog
      v-if="show"
      ref="dialogRef"
      value="show"
      scrollable
      persistent
      :max-width="monitoringTypeId !== null ? '1000px' : '800px'"
    >
      <v-card>
        <ModalTitle :title="title" />

        <WaitModal :show="showWaitModal" />

        <v-card-text>
          <v-form ref="MonitoringTypeModalForm">
            <v-row class="mt-3">
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                <v-text-field
                  :id="getIdByName('name')"
                  ref="monitoringTypeName"
                  v-model="formData.monitoringTypeName"
                  :label="$t('name')"
                  filled
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />

                <v-select
                  :id="getIdByName('activityType')"
                  ref="monitoringTypeActivityType"
                  v-model="formData.activityType"
                  :label="$t('monitoringTypeActivityType')"
                  :items="activityTypes"
                  item-text="name"
                  item-value="id"
                  filled
                  :no-data-text="$t('noDataAvailable')"
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />
              </v-col>

              <v-col v-if="monitoringTypeId !== null" :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'">
                <detail-field
                  v-if="monitoringTypeId !== null"
                  :id="getIdByName('createdDate')"
                  ref="createdDate"
                  v-model="formData.createdDate"
                  :label="$t('createdDate')"
                />

                <detail-field
                  v-if="monitoringTypeId !== null"
                  :id="getIdByName('createdBy')"
                  ref="createdBy"
                  v-model="formData.createdBy"
                  :label="$t('createdBy')"
                />

                <detail-field
                  v-if="monitoringTypeId !== null"
                  :id="getIdByName('modifiedDate')"
                  ref="modifiedDate"
                  v-model="formData.modifiedDate"
                  :label="$t('modifiedDate')"
                />

                <detail-field
                  v-if="monitoringTypeId !== null"
                  :id="getIdByName('modifiedBy')"
                  ref="modifiedBy"
                  v-model="formData.modifiedBy"
                  :label="$t('modifiedBy')"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            v-if="showWaitModal == false"
            :id="getIdByName('save')"
            ref="save"
            text
            color="primary"
            @click.stop="createOrUpdateAction()"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import MonitoringTypeService from '@/services/monitoringTypeService';
import ActivityService from '@/services/activityService';

export default {
  name: 'MonitoringTypeModal',

  components: {},
  mixins: [translation, accessibility, validationRulesMixin],

  props: {
    monitoringTypeId: {
      type: Number,
      required: false,
      default: null,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      title: '',
      showWaitModal: false,
      activityTypes: [],
      formData: {
        monitoringTypeName: '',
        activityType: null,
      },
      originalFormData: {},
    };
  },

  watch: {
    show: function () {
      if (this.show) {
        if (this.monitoringTypeId != null) {
          this.editClicked();
        } else {
          this.init();
        }
      }
    },
  },

  methods: {
    init: function () {
      this.title = this.$t('createMonitoringType');

      this.formData = {
        monitoringTypeName: '',
        activityType: null,
      };
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      this.getAvailableActivityTypes();
    },

    async editClicked() {
      this.title = this.$t('editMonitoringType');
      this.showWaitModal = true;

      try {
        let monitoringType = await MonitoringTypeService.getMonitoringTypeById(this.monitoringTypeId);
        this.formData.monitoringTypeName = monitoringType.name;
        this.formData.activityType = monitoringType.activityType.id;
        this.formData.createdBy = monitoringType.createdBy.username;
        this.formData.createdDate = monitoringType.createdAt;
        this.formData.modifiedBy = monitoringType.updatedBy?.username;
        this.formData.modifiedDate = monitoringType?.updatedAt;

        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
        this.getAvailableActivityTypes(monitoringType.activityType);
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    async createOrUpdateAction() {
      let isValid = this.$refs.MonitoringTypeModalForm.validate();

      if (!isValid) return;

      let data = {
        name: this.formData.monitoringTypeName,
        activityType: this.formData.activityType,
      };
      this.showWaitModal = true;

      try {
        if (this.monitoringTypeId === null) {
          await MonitoringTypeService.createMonitoringType(data);
        } else {
          await MonitoringTypeService.updateMonitoringType(this.monitoringTypeId, data);
        }

        this.closeDialog();
        this.$emit('refresh');
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    async getAvailableActivityTypes(currentActivityType = null) {
      this.showWaitModal = true;

      try {
        let availableActivityTypes = await ActivityService.getAvailableActivityTypes(this.formData.activityType);
        if (currentActivityType) {
          availableActivityTypes.push(currentActivityType);
        }

        this.activityTypes = availableActivityTypes.sort((a, b) => a.name.localeCompare(b.name));
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
  },
};
</script>

<style scoped></style>
