import api from './baseApiService';

export class ProtocolsAndActivitiesService {
  async getProtocols() {
    return await api.get('protocols');
  }
  async getActivities() {
    return await api.get('activities');
  }
  async getPatientActivities(patientId) {
    return await api.get(`patientactivities/${patientId}`);
  }
  async getPatientProtocols(patientId) {
    return await api.get(`patientprotocols/${patientId}`);
  }
  async getProtocolActivities(protocolId) {
    return await api.get(`protocolactivities/${protocolId}`);
  }

  async updatePatientActivitiesAndProtocols(patientId, data) {
    return await api.put(`patientactivitiesprotocols/patient/${patientId}/`, data);
  }

  async testCodeExecutorForPatient(patientId, data) {
    return await api.post(`codexecutor/test/${patientId}/`, data);
  }
}

export default new ProtocolsAndActivitiesService();
