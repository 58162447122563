export const ActivityTypes = {
  /** Weight */
  WEI: 'WEI',
  /** Medication adherence */
  RXA: 'RXA',
  /** Arterial pressure */
  APR: 'APR',
  /** Saturation */
  SAT: 'SAT',
  /** Cardiac frequency */
  CFR: 'CFR',
  /** Incontinence */
  INC: 'INC',
  /** Fall detection */
  FDE: 'FDE',
  /** Body temperature */
  BDT: 'BDT',
  /** Respiratory rate */
  RES: 'RES',
  /** Dialogs (Anamnesis) */
  DLG: 'DLG',
};

export const StatusLevels = {
  /** Must change */
  CH: 'CH',
  /** More than half filled */
  MH: 'MH',
  /** Less than half filled */
  LH: 'LH',
  /** Wet */
  WE: 'WE',
  /** Dry */
  DR: 'DR',
  /** No following */
  NF: 'NF',
  /** Alert */
  AL: 'AL',
  /** Warning */
  WA: 'WA',
  /** Normal */
  NO: 'NO',
};

export const IncontinenceSet = new Set([
  StatusLevels.CH,
  StatusLevels.MH,
  StatusLevels.LH,
  StatusLevels.WE,
  StatusLevels.DR,
]);

export const StandardSet = new Set([StatusLevels.NF, StatusLevels.AL, StatusLevels.WA, StatusLevels.NO]);

export const TimeFilterType = {
  LAST24: '24h',
  DAYS: 'days',
  HOURS: 'hours',
  RANGE: 'range',
};

export const StatsDuration = {
  HOUR: 'hour',
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};
