import MonitoringTypeHome from './MonitoringTypeHome';

export default {
  moduleName: 'monitoringtype',
  routes: [
    {
      path: '/monitoringtype',
      name: 'MonitoringTypeHome',
      component: MonitoringTypeHome,
    },
  ],
};
