export default {
  en: {
    employeeNumber: 'Identification number',
    healthworkers: 'Healthcare Professionals',
    createProfessional: 'Add Healthcare Professional',
    editProfessional: 'Modify Healthcare Professional',
    healthWorkerTypes: 'Healthcare Professional Type',
    errorHealthWorkerTypeDoesNotExist: 'The healthcare professional type does not exist',
    deleteProfessionalQuestion: 'Are you sure you want to delete this healthcare professional?',

    patientHealthcareProfessionals: 'Assign Healthcare Professional(s)',

    currentPatientHealthcareProfessionals: 'Patient Healthcare Professional(s)',
    availableHealthcareProfessionals: 'Available Healthcare Professional(s)',

    errorEmployeeNumberMustBeProvided: 'The identification number must be provided',
    errorFirstNameMustBeProvided: 'First name must be provided',
    errorLastNameMustBeProvided: 'Last name must be provided',
    errorEmailMustBeProvided: 'Email must be provided',
    errorPhoneMustBeProvided: 'Phone number must be provided',
  },
  fr: {
    employeeNumber: 'Matricule',
    healthworkers: 'Intervenants',
    createProfessional: 'Ajouter un intervenant',
    editProfessional: "Modifier l'intervenant",
    healthWorkerTypes: "Type d'intervenant",
    errorHealthWorkerTypeDoesNotExist: "Le type d'intervenant n'existe pas",
    deleteProfessionalQuestion: 'Êtes-vous certain de vouloir supprimer cet intervenant ?',

    patientHealthcareProfessionals: 'Assignation intervenant(s)',

    currentPatientHealthcareProfessionals: 'Intervenant(s) du patient',
    availableHealthcareProfessionals: 'Intervenant(s) disponible(s)',

    errorEmployeeNumberMustBeProvided: 'Le matricule doit être spécifié',
    errorFirstNameMustBeProvided: 'Le prénom doit être spécifié',
    errorLastNameMustBeProvided: 'Le nom doit être spécifié',
    errorEmailMustBeProvided: 'Le courriel doit être spécifié',
    errorPhoneMustBeProvided: 'Le numéro de téléphone doit être spécifié',
  },
};
