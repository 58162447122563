import api from './baseApiService';

class NotificationService {
  async getNotifications() {
    return await api.get('notifications');
  }

  async getNotificationById(notificationId) {
    return await api.get(`notification/${notificationId}`);
  }

  async getUnreadNotificationCount() {
    return await api.get('notifications/unread_count');
  }

  async updateNotificationReadStatus(notificationId, data) {
    return await api.put(`notification/${notificationId}/read_status`, data);
  }
}

export default new NotificationService();
