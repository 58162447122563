import api from './baseApiService';

class MonitoringTypeService {
  async getMonitoringTypes() {
    return await api.get('monitoringtypes');
  }

  async getMonitoringTypeById(monitoringTypeId) {
    return await api.get(`monitoringtype/${monitoringTypeId}`);
  }

  async createMonitoringType(data) {
    return await api.post('monitoringtype/', data);
  }

  async updateMonitoringType(monitoringTypeId, data) {
    return await api.put(`monitoringtype/${monitoringTypeId}/`, data);
  }

  async deleteMonitoringType(monitoringTypeId) {
    return await api.delete(`monitoringtype/${monitoringTypeId}/delete/`);
  }
}

export default new MonitoringTypeService();
