<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <WaitModal :show="showWaitModal" />

    <div v-if="$vuetify.breakpoint.smAndUp" class="legend my-4">
      <div class="legend-section">
        <div class="font-weight-bold text--secondary text-body-2 mb-1">{{ $t('standardValues') }}</div>
        <div class="legend-items-container">
          <div v-for="definition in standardDefinitions" :key="definition.id" class="legend-item">
            <v-icon class="mr-2" small :color="definition.iconColor">{{ definition.iconName }}</v-icon>
            <span class="text-body-2">{{ definition.label }}</span>
          </div>
        </div>
      </div>
      <div v-if="showIncontinence" class="legend-section">
        <div class="font-weight-bold text--secondary text-body-2 mb-1">{{ $t('incontinence') }}</div>
        <div class="legend-items-container">
          <div v-for="definition in incontinenceDefinitions" :key="definition.id" class="legend-item">
            <v-icon class="mr-2" small :color="definition.iconColor">{{ definition.iconName }}</v-icon>
            <span class="text-body-2">{{ definition.label }}</span>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <v-row>
        <v-col>
          <div class="d-flex flex-column align-start row-gap-2">
            <div class="text--secondary text-body-2 mb-1">{{ $t('standardValues') }}</div>
            <div v-for="definition in standardDefinitions" :key="definition.id">
              <v-icon class="mr-2" small :color="definition.iconColor">{{ definition.iconName }}</v-icon>
              <span class="text-body-1 text--primary">{{ definition.label }}</span>
            </div>
          </div>
        </v-col>
        <v-col v-if="showIncontinence">
          <div class="d-flex flex-column align-start row-gap-2">
            <div class="text--secondary text-body-2 mb-1">{{ $t('incontinence') }}</div>
            <div v-for="definition in incontinenceDefinitions" :key="definition.id">
              <v-icon class="mr-2" small :color="definition.iconColor">{{ definition.iconName }}</v-icon>
              <span class="text-body-1 text--primary">{{ definition.label }}</span>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { StandardSet, IncontinenceSet } from './constants';
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import virtuoseMixin from '@/virtuoseMixin';
import iconMixin from './iconMixin';

export default {
  name: 'StatusLevelLegend',
  components: {},
  mixins: [translationMixin, accessibility, virtuoseMixin, iconMixin],

  props: {
    showIncontinence: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  data() {
    return {
      error: null,
      showWaitModal: false,
      standardDefinitions: [],
      incontinenceDefinitions: [],
    };
  },

  created: function () {
    this.init();
  },

  methods: {
    init: function () {
      const success = (response) => {
        const statusLevels = response.data;
        const self = this;

        const definitions = statusLevels.map((item) => {
          return {
            id: item.id,
            iconName: self.getIconName(item.code),
            iconColor: self.getIconColor(item.code),
            label: item.name,
            isIncontinence: IncontinenceSet.has(item.code),
            isStandard: StandardSet.has(item.code),
          };
        });

        this.standardDefinitions = definitions.filter((item) => item.isStandard);
        this.incontinenceDefinitions = definitions.filter((item) => item.isIncontinence);

        this.showWaitModal = false;
      };

      this.showWaitModal = true;
      this.getStatusLevels(success, this.defaultErrorCallBack);
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
      this.showWaitModal = false;
    },
  },
};
</script>

<style scoped>
.legend {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 80px;
  row-gap: 16px;
}
.legend-items-container {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  column-gap: 24px;
}
</style>
