export default {
  en: {
    alertNotificationTriggered: 'An alert has been triggered for the activity',
    notificationAlertType: 'Alert',
    notificationDialog: 'Dialog:',
    alertTypeData: 'Alert Type:',
    openNotification: 'View',

    expectedValue: 'the expected value',
    abnormalValue: 'is abnormal',

    notificationMonitoringType_FDE: 'Fall detected',
    notificationMonitoringType_SAPR: 'Systolic',
    notificationMonitoringType_DAPR: 'Diastolic',

    notificationType_biggerThan: 'is greater than',
    notificationType_lesserThan: 'is less than',
    notificationType_variation: 'Variation (%)',
    notificationType_biggerThanHalf: 'is greater than (>) 1/2 full',
    notificationType_lesserThanHalf: 'is less than (<) 1/2 full',
    notificationType_wet: 'Wet',
    notificationType_toChange: 'Must change',
    notificationType_nottaken: 'Medication not taken',
    notificationType_abnormalvalue: 'Abnormal Value',
    notificationUnitType_percent: '%',
    notificationUnitType_mmhg: 'mmhg',
    notificationUnitType_breathingCycles: 'breathing cycle',
    notificationUnitType_bpm: 'bpm',
    notificationUnitType_fahrenheit: '°F',
    notificationUnitType_celsius: '°C',
    notificationUnitType_kg: 'kg',
    notificationUnitType_lbs: 'lb',

    notification_redirecting: 'Redirecting to notification details',
  },
  fr: {
    alertNotificationTriggered: "Une alerte a été déclenchée pour l'activité",
    notificationAlertType: 'Alerte',
    notificationDialog: 'Dialogue:',
    alertTypeData: "Type d'alerte:",
    openNotification: 'Consulter',

    expectedValue: 'la valeur attendue',
    abnormalValue: 'est anormale',

    notificationMonitoringType_FDE: 'Chute détectée',
    notificationMonitoringType_SAPR: 'Systolique',
    notificationMonitoringType_DAPR: 'Diastolique',

    notificationType_biggerThan: 'est plus grande que',
    notificationType_lesserThan: 'est plus petite que',
    notificationType_variation: 'Variation (%)',
    notificationType_biggerThanHalf: 'plus grande que (>) 1/2 plein',
    notificationType_lesserThanHalf: 'plus petite que (<) 1/2 plein',
    notificationType_wet: 'Humide',
    notificationType_toChange: 'À changer',
    notificationType_nottaken: 'Médicament non pris',
    notificationType_abnormalvalue: 'Valeur anormale',
    notificationUnitType_percent: '%',
    notificationUnitType_mmhg: 'mmhg',
    notificationUnitType_breathingCycles: 'cycle de resp.',
    notificationUnitType_bpm: 'bpm',
    notificationUnitType_fahrenheit: '°F',
    notificationUnitType_celsius: '°C',
    notificationUnitType_kg: 'kg',
    notificationUnitType_lbs: 'lb',

    notification_redirecting: 'Redirection vers le détail de la notification',
  },
};
