export const chartColor = {
  correctFirstDataColor: '#1E74D4',
  correctSecondaryDataColor: '#F39308',
  incorrectDataColor: '#ff0000',
  hoveredCorrectFirstDataColor: '#005BBF',
  hoveredCorrectSecondaryDataColor: '#E38A07',
  hoveredIncorrectDataColor: '#DE0000',
  textLabel: '#fff',
  mainBorderLegendColor: '#4A94E6',
  secondaryBorderLegendColor: '#F2B04E',
  backgroundThreshold: 'transparent',
  delimiterBorderColor: 'rgba(0, 0, 0, 0.25)',
  delimiterBackgroundColor: 'rgba(0, 0, 0, 0.05)',
};
