d
<template>
  <v-container>
    <ConfirmationModal
      :question="$t('confirmDeleteProtocol')"
      :title="$t('delete')"
      :show="showDelete"
      @cancel="showDelete = false"
      @ok="deleteSelectedProtocol"
    />

    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row>
      <v-col>
        <div class="page-title">
          {{ $t('protocols') }}
        </div>
      </v-col>
    </v-row>

    <v-row v-if="$can(permissions.actions.add, permissions.subjects.protocol)">
      <v-col>
        <v-btn ref="addProtocol" color="primary" @click="editProtocolAction(null)">
          {{ $t('add') }}
        </v-btn>
      </v-col>
    </v-row>

    <WaitModal :show="showWaitModal" />

    <v-row>
      <v-col>
        <DataGrid
          :items="protocols"
          :columns="columns"
          grid-name="protocols_grid"
          :show-select="false"
          :items-per-page="25"
        >
          <template #[`item.active`]="{ item }">
            <v-simple-checkbox v-model="item.active" disabled />
          </template>
          <template #[`item.normative`]="{ item }">
            <v-simple-checkbox v-model="item.normative" disabled />
          </template>

          <template #item.actions="{ item }">
            <v-menu v-if="!item.normative || $can(permissions.actions.manage, permissions.subjects.normative)">
              <template #activator="{ on }">
                <v-btn :ref="`actions_${item.id}`" icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <template v-if="$can(permissions.actions.change, permissions.subjects.protocol)">
                  <v-list-item :ref="`editProtocol_${item.id}`" @click="editProtocolAction(item.id)">
                    <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                    <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider>

                  <v-list-item :ref="`editActivities_${item.id}`" @click="goToProtocolActivities(item)">
                    <v-list-item-icon><v-icon>mdi-clipboard-pulse-outline</v-icon></v-list-item-icon>
                    <v-list-item-title>{{ $t('assignActivities') }}</v-list-item-title>
                  </v-list-item>
                  <v-divider v-if="$can(permissions.actions.delete, permissions.subjects.protocol)"></v-divider>
                </template>

                <v-list-item
                  v-if="$can(permissions.actions.delete, permissions.subjects.protocol)"
                  :ref="`deleteProtocol_${item.id}`"
                  @click="deleteAction(item.id)"
                >
                  <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </DataGrid>
      </v-col>
    </v-row>

    <ProtocolModal :show.sync="showEditModal" :protocol-id="selectedProtocolId" @refresh="init()" />

    <ProtocolActivityAssign
      :protocol-id="selectedProtocolId"
      :is-protocol-normative="selectedProtocolNormative"
      :show="showAssignActivityModal"
      @close="ProtocolActivitiesClose"
    />
  </v-container>
</template>

<script>
import DataGrid from '@/components/DataGrid.vue';
import ProtocolModal from './ProtocolModal';
import ProtocolActivityAssign from './ProtocolActivityAssign';

import translationMixin from '@/translationMixin';
import virtuoseMixin from '@/virtuoseMixin';
import accessibility from '@/accessibilityMixin';
import permissionMixin from '@/auth/permissionMixin';

export default {
  name: 'ProtocolGrid',
  components: { DataGrid, ProtocolModal, ProtocolActivityAssign },
  mixins: [translationMixin, virtuoseMixin, accessibility, permissionMixin],
  props: {},
  data() {
    return {
      showDelete: false,
      showEditModal: false,
      error: null,
      selectedProtocolId: null,
      selectedProtocolNormative: false,
      showAssignActivityModal: false,
      showWaitModal: false,

      protocols: [],
    };
  },
  computed: {
    columns() {
      let columns = [
        {
          text: 'protocolName',
          value: 'name',
          filterable: true,
        },
        {
          text: 'description',
          value: 'description',
          filterable: true,
        },
        {
          text: 'active',
          value: 'active',
          filterable: true,
        },
        {
          text: 'normative',
          value: 'normative',
          filterable: true,
        },
        {
          text: 'createdDate',
          value: 'createdAt',
          filterable: true,
          visible: true,
        },
        {
          text: 'createdBy',
          value: 'createdBy',
          filterable: true,
          visible: true,
        },
        {
          text: 'modifiedDate',
          value: 'updatedAt',
          filterable: true,
          visible: true,
        },
        {
          text: 'modifiedBy',
          value: 'updatedBy',
          filterable: true,
          visible: true,
        },
      ];

      if (
        this.$can(this.permissions.actions.change, this.permissions.subjects.protocol) ||
        this.$can(this.permissions.actions.delete, this.permissions.subjects.protocol)
      ) {
        columns.push({
          text: 'Actions',
          value: 'actions',
          filterable: false,
          sortable: false,
        });
      }

      return columns;
    },
  },
  created: function () {
    this.init();
  },

  methods: {
    init: function () {
      this.protocols = [];
      this.showWaitModal = true;
      this.showDelete = false;
      this.showEditModal = false;

      var success = function (response) {
        this.showWaitModal = false;
        this.protocols = response.data.map((item) => {
          return {
            id: item.id,
            name: item.name,
            description: item.description,
            active: item.active,
            normative: item.normative,
            createdAt: item.createdAt,
            createdBy: item.createdBy != null ? item.createdBy.username : '',
            updatedAt: item.updatedAt,
            updatedBy: item.updatedBy != null ? item.updatedBy.username : '',
          };
        });
      };

      this.getProtocols(success, this.defaultErrorCallBack);
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
      this.showWaitModal = false;
    },

    deleteSelectedProtocol: function () {
      this.showDelete = false;
      this.showWaitModal = true;

      var success = function () {
        this.showWaitModal = false;
        this.init();
      };

      this.deleteProtocol(this.selectedProtocolId, success, this.defaultErrorCallBack);
    },

    setResidentProtocolId: function (id) {
      this.protocolResidentId = id;
    },

    onModalClose: function (refresh) {
      this.showEditModal = false;

      if (refresh) {
        this.getProtocolActions();
      }
    },

    editProtocolAction: function (id) {
      this.selectedProtocolId = id;
      this.showEditModal = true;
    },

    deleteAction: function (id) {
      this.selectedProtocolId = id;
      this.showDelete = true;
    },

    goToProtocolActivities: function (protocol) {
      this.selectedProtocolId = protocol.id;
      this.selectedProtocolNormative = protocol.normative;
      this.showAssignActivityModal = true;
    },
    ProtocolActivitiesClose: function () {
      this.showAssignActivityModal = false;
      this.init();
    },
  },
};
</script>
