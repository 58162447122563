<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <WaitModal :show="showWaitModal" />
    <v-data-table
      :items="items"
      :headers="headers"
      :footer-props="footerProps"
      :items-per-page="25"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      show-expand
      item-key="at"
      class="elevation-1 mt-4"
      mobile-breakpoint="0"
    >
      <template #item.statusLevelId="{ item }">
        <div>
          <v-icon :color="getIconColor(item.statusLevelCode)">
            {{ getIconName(item.statusLevelCode) }}
          </v-icon>
        </div>
      </template>
      <template #expanded-item="{ item }">
        <td :colspan="headers.length + 1">
          <div class="my-2">
            <v-alert
              v-for="(child, index) in item.resultDetails"
              :key="index"
              :text="child.statusLevelCode !== 'NO'"
              :color="getAnamAlertColor(child.statusLevelCode)"
              :icon="getAnamAlertIcon(child.statusLevelCode)"
              dense
              class="mb-0"
            >
              <div>
                <span>
                  <span class="text-body-2">{{ child.time }}</span>
                  <span class="text-body-2 text--secondary"> - {{ child.dialogueName }}</span></span
                >
                <div v-if="child.hasAbnormalValue" class="text-body-2 text--secondary">
                  <span>{{ $t('type_abnormalvalue') }}</span>
                </div>
              </div>
            </v-alert>
          </div>
        </td>
      </template>
    </v-data-table>
  </span>
</template>

<script>
import translationMixin from '@/translationMixin';
import virtuoseMixin from '@/virtuoseMixin';
import iconMixin from './iconMixin';
export default {
  name: 'PatientAnamnese',
  mixins: [translationMixin, virtuoseMixin, iconMixin],
  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },

    monitoringTypeId: {
      type: Number,
      required: true,
    },

    filters: {
      type: Object,
      required: false,
      default: null,
      validator(value) {
        return value == null || (typeof value === 'object' && !Array.isArray(value));
      },
    },
  },
  data: () => ({
    items: [],
    sortBy: 'at',
    sortDesc: true,
    error: null,
    showWaitModal: false,
  }),
  computed: {
    footerProps: function () {
      return {
        itemsPerPageOptions: [5, 10, 25, 50, -1],
        showFirstLastPage: true,
        'items-per-page-text': this.$t('rowsByPage'),
      };
    },
    headers() {
      return [
        {
          text: this.$t('statusLevelId'),
          textalign: 'center',
          value: 'statusLevelId',
          align: 'center',
          width: '95px',
          sortable: false,
        },
        {
          text: this.$t('at'),
          value: 'at',
          sortable: false,
          order: 'desc',
        },
      ];
    },
  },
  watch: {
    patientId: function () {
      this.getPatientDialogueResults();
    },
    filters: function () {
      this.getPatientDialogueResults();
    },
    monitoringTypeId: function () {
      this.getPatientDialogueResults();
    },
  },
  created: function () {
    this.init();
  },
  methods: {
    init: function () {
      this.getPatientDialogueResults();
    },
    getPatientDialogueResults: function () {
      this.showWaitModal = true;

      const success = function (response) {
        this.showWaitModal = false;
        this.items = response.data.results;
        this.dialogues = response.data.dialogues;
        this.statusLevels = response.data.statusLevels;

        var dialogueMap = new Map();
        for (var i = 0; i < this.dialogues.length; i++) {
          dialogueMap.set(this.dialogues[i].id, this.dialogues[i].name);
        }
        var statusLevelMap = new Map();
        for (var s = 0; s < this.statusLevels.length; s++) {
          statusLevelMap.set(this.statusLevels[s].id, this.statusLevels[s].code);
        }

        for (let j = 0; j < this.items.length; j++) {
          this.items[j].statusLevelCode = statusLevelMap.get(this.items[j].statusLevelId);
          for (let i = 0; i < this.items[j].resultDetails.length; i++) {
            this.items[j].resultDetails[i].dialogueName = dialogueMap.get(this.items[j].resultDetails[i].dialogueId);
            this.items[j].resultDetails[i].statusLevelCode = statusLevelMap.get(
              this.items[j].resultDetails[i].statusLevelId
            );
          }
        }
      };

      var data = {
        patientId: this.patientId,
        timeFilterType: this.filters?.timeFilterType ?? null,
        timeFilterValue: this.filters?.timeFilterValue ?? null,
        dateFrom: this.filters?.dateFrom ?? null,
        dateTo: this.filters?.dateTo ?? null,
        monitoringTypeId: this.monitoringTypeId,
      };

      this.getPatientDialoguesResults(data, success, this.defaultErrorCallBack);
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
      this.showWaitModal = false;
    },
  },
};
</script>

<style scoped></style>
