<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent max-width="600px">
      <v-card class="eb-white-bg">
        <ModalTitle :title="$t('iotConfiguration')" />

        <WaitModal :show="showWaitModal" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <v-text-field
            v-for="parameter in parameters"
            :id="getIdByName(parameter.name)"
            :key="parameter.name"
            :ref="parameter.name"
            v-model="parameter.value"
            :label="$t(parameter.name)"
            v-bind="getRequiredBindObject(false)"
            filled
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            v-if="showWaitModal == false"
            :id="getIdByName('configure')"
            ref="configure"
            text
            color="primary"
            @click.stop="configure()"
          >
            {{ $t('configure') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import iotService from '@/services/iotService';

export default {
  name: 'IotConfigurationModal',

  components: {},
  mixins: [translation, accessibility],

  props: {
    iotId: {
      type: Number,
      required: false,
      default: null,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      showWaitModal: false,
      parameters: [],
    };
  },

  watch: {
    show: function () {
      if (this.show) {
        this.init();
      }
    },
  },

  created: function () {},

  methods: {
    async init() {
      this.showWaitModal = true;

      try {
        let iot = await iotService.getIotById(this.iotId);
        var communicator = iot.iotdeviceModel.communicator;

        if (communicator != null) {
          var userParameters = communicator.definition.userParameters;
          this.parameters = this.convertParametersToArray(userParameters, iot);
        }
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    convertParametersToArray: function (userParameters, iot) {
      var parameters = Object.entries(userParameters).map((item) => {
        var alias = item[1].alias;

        var result = {};
        result.name = item[0];
        result.type = item[1].type;
        result.definition = item[1].definition;
        result.value = Object.hasOwn(iot, alias) ? iot[alias] : '';
        return result;
      });

      return parameters;
    },

    convertParametersToObject: function () {
      return this.parameters.reduce((acc, item) => {
        return { ...acc, [item.name]: item.value };
      }, {});
    },

    async configure() {
      var isValid = this.validateNotEmptyParameters();

      if (!isValid) {
        return;
      }

      this.showWaitModal = true;
      try {
        var parameters = this.convertParametersToObject();
        await iotService.configureIot(this.iotId, parameters);
        this.closeDialog();
        this.$emit('refresh', true);
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    closeDialog: function () {
      this.$emit('close', true);
    },
  },
};
</script>

<style scoped></style>
