export default {
  en: {
    iot: 'Connected Devices',
    iotMonitoringType: 'Monitoring',
    iotDeviceType: 'Device',
    iotDeviceCompany: 'Company',
    iotDeviceModel: 'Model',
    iotDeviceModelNumber: '#Model',
    iotDeviceSerialNumber: '#Serie',
    iotDeviceVersion: 'Version',
    iotDeviceStatus: 'Status',
    iotConfiguration: 'IOT Configuration',
    iotRoomConfiguration: 'IOT Room Configuration',
    iotDeviceAssociated: 'Assiociated',
    configure: 'Configure',
    createIot: 'Add device',
    editIot: 'Modify device',
    deleteIotQuestion: 'Are you sure you want to delete this Device?',
    errorIOTDeviceStatusMustBeProvided: 'IOT device status must be provided',
    errorIOTDeviceTypeMustBeProvided: 'IOT device type must be provided',
    errorModelMustBeProvided: 'IOT device model must be provided',
    errorSerieMustBeProvided: 'IOT device serial number must be provided',
    errorIOTCannotBeConfigurated: 'This IOT device type cannot be configurated',
    errorConfiguratedStatusDoesNotExists: 'Status configured does not exists',
    errorPatientVirtualAssistantNotConfigured: 'IOT device is not configured',

    currentPatientIOT: 'User IOT Device(s)',
    availableIOT: 'Available IOT Device(s)',
    patientIOTDevices: 'Assign IOT Device(s)',
  },
  fr: {
    iot: 'Objets connectés',
    iotMonitoringType: 'Surveillance',
    iotDeviceType: 'Objet',
    iotDeviceCompany: 'Compagnie',
    iotDeviceModel: 'Modèle',
    iotDeviceModelNumber: '#Modèle',
    iotDeviceSerialNumber: '#Série',
    iotDeviceVersion: 'Version',
    iotDeviceStatus: 'Statut',
    iotConfiguration: "Configuration de l'object connecté",
    iotRoomConfiguration: "Configuration de la chambre de l'object connecté",
    iotDeviceAssociated: 'Associé',
    configure: 'Configurer',
    createIot: 'Ajouter un appareil',
    editIot: "Modifier l'appareil",
    deleteIotQuestion: 'Êtes-vous certain de vouloir supprimer cet appareil ?',
    errorIOTDeviceStatusMustBeProvided: "Le statut de l'objet connecté doit être spécifié",
    errorIOTDeviceTypeMustBeProvided: "Le type de l'objet connecté doit être spécifié",
    errorModelMustBeProvided: "Le modèle de l'objet connecté doit être spécifié",
    errorSerieMustBeProvided: "Le numéro de série de l'objet connecté doit être spécifié",
    errorIOTCannotBeConfigurated: "Ce type d'objet connecté ne peut être configuré",
    errorConfiguratedStatusDoesNotExists: "Le statut configuré n'existe pas",
    errorPatientVirtualAssistantNotConfigured: "L'objet connecté n'est pas configuré",

    currentPatientIOT: "Objet(s) connecté(s) de l'usager",
    availableIOT: 'Objet(s) connecté(s) disponible(s)',
    patientIOTDevices: 'Assignation objet(s) connecté(s)',
  },
};
