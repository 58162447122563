<template>
  <v-container>
    <ConfirmationModal
      :question="$t('confirmDeleteUser')"
      :title="$t('delete')"
      :show="showDelete"
      @cancel="showDelete = false"
      @ok="deleteSelectedUser"
    />

    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row>
      <v-col>
        <div class="page-title">
          {{ $t('user.users') }}
        </div>
      </v-col>
    </v-row>

    <v-row v-if="$can(permissions.actions.add, permissions.subjects.user)">
      <v-col>
        <v-btn ref="addUser" color="primary" @click="editUserAction(null, null)">
          {{ $t('add') }}
        </v-btn>
      </v-col>
    </v-row>

    <WaitModal :show="showWaitModal" />

    <v-row>
      <v-col>
        <DataGrid :items="users" :columns="columns" grid-name="users_grid" :show-select="false" :items-per-page="25">
          <template #item.actions="{ item }">
            <v-menu>
              <template #activator="{ on }">
                <v-btn :ref="`actions_${item.id}`" icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="$can(permissions.actions.change, permissions.subjects.user)"
                  :ref="`editUser_${item.id}`"
                  @click="editUserAction(item.id, item.email)"
                >
                  <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                </v-list-item>
                <!-- <v-divider
                  v-if="
                    $can(permissions.actions.change, permissions.subjects.user) &&
                    $can(permissions.actions.delete, permissions.subjects.user)
                  "
                ></v-divider>
                <v-list-item
                  v-if="$can(permissions.actions.delete, permissions.subjects.user)"
                  :ref="`deleteUser_${item.id}`"
                  :disabled="item.email === currentUsername"
                  @click="deleteAction(item.id)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                </v-list-item> -->
              </v-list>
            </v-menu>
          </template>
        </DataGrid>
      </v-col>
    </v-row>

    <UserModal
      :show.sync="showEditModal"
      :user-id="selectedUserId"
      :is-current-user="isCurrentUser"
      @refresh="init()"
    />
  </v-container>
</template>

<script>
import DataGrid from '@/components/DataGrid.vue';
import UserModal from './UserModal';
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import userService from '@/services/userService';
import permissionMixin from '@/auth/permissionMixin';
import auth from '@/auth/auth';

export default {
  name: 'UserGrid',
  components: { DataGrid, UserModal },
  mixins: [translationMixin, accessibility, permissionMixin],
  props: {},
  data() {
    return {
      showDelete: false,
      showEditModal: false,
      error: null,
      selectedUserId: null,
      showWaitModal: false,
      currentUsername: '',
      isCurrentUser: true,
      users: [],
    };
  },
  computed: {
    columns() {
      let columns = [
        {
          text: 'firstName',
          value: 'firstName',
          filterable: true,
        },
        {
          text: 'lastName',
          value: 'lastName',
          filterable: true,
        },
        {
          text: 'user.email',
          value: 'email',
          filterable: true,
        },
        {
          text: 'user.accessGroups',
          value: 'accessGroups',
          filterable: true,
        },
        {
          text: 'user.createdAt',
          value: 'createdAt',
          filterable: true,
          visible: false,
        },
        {
          text: 'user.lastLogin',
          value: 'lastLogin',
          filterable: true,
          visible: false,
        },
      ];
      if (
        this.$can(this.permissions.actions.change, this.permissions.subjects.user) ||
        this.$can(this.permissions.actions.delete, this.permissions.subjects.user)
      ) {
        columns.push({
          text: 'Actions',
          value: 'actions',
          filterable: false,
          sortable: false,
        });
      }
      return columns;
    },
  },

  created: async function () {
    await this.init();
  },

  methods: {
    async init() {
      this.users = [];
      this.showWaitModal = true;
      this.showDelete = false;
      this.showEditModal = false;

      try {
        let [users, currentUserAttributes] = await Promise.all([
          await userService.getUsers(),
          await auth.getUserAttributes(),
        ]);

        this.users = users.map((user) => {
          let groupLength = user.accessGroups?.length ?? 0;

          switch (groupLength) {
            case 0:
              user.accessGroups = '';
              break;
            case 1:
              user.accessGroups = user.accessGroups[0].name;
              break;
            default:
              user.accessGroups = `${user.accessGroups[0].name}, ${user.accessGroups[1].name}`;
              if (groupLength > 2) {
                user.accessGroups = `${user.accessGroups} (+${groupLength - 2})`;
              }
              break;
          }
          return user;
        });
        this.currentUsername = currentUserAttributes.username;
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    editUserAction: function (id, email) {
      this.selectedUserId = id;
      this.isCurrentUser = email === this.currentUsername;
      this.showEditModal = true;
    },

    async deleteSelectedUser() {},
  },
};
</script>
