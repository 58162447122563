import api from './baseApiService';

export class IotService {
  async getIOTDeviceParametersInformations() {
    return await api.get('iotdeviceparameters');
  }

  async getIOTDeviceTypesByMonitoringType(monitoringTypeId) {
    return await api.get(`iotdevicetypesbymonitoringtype/${monitoringTypeId}`);
  }

  async getIots(data) {
    return await api.post('iotdevices/', data);
  }

  async deleteIot(iotDeviceId) {
    return await api.delete(`iotdevice/${iotDeviceId}/delete/`);
  }

  async getIotById(iotDeviceId) {
    return await api.get(`iotdevice/${iotDeviceId}`);
  }

  async createIot(data) {
    return await api.post('iotdevice/', data);
  }

  async updateIot(iotDeviceId, data) {
    return await api.put(`iotdevice/${iotDeviceId}/`, data);
  }

  async configureIot(iotDeviceId, data) {
    return await api.post(`iotdevice/${iotDeviceId}/configure/`, data);
  }

  async getIotDeviceTypes() {
    return await api.get('iotdevicetypes');
  }

  async getIotRoom(iotDeviceId) {
    return await api.get(`iotdeviceroom/${iotDeviceId}`);
  }

  async updateIotRoom(iotDeviceId, data) {
    return await api.put(`iotdeviceroom/${iotDeviceId}/`, data);
  }

  async getIotDeviceAvailableRooms(data) {
    return await api.post(`iotdeviceavailablerooms/`, data);
  }

  async getPatientIOTDevices(patientId) {
    return await api.get(`patientiotdevices/${patientId}`);
  }

  async updatePatientIOTDevice(patientId, data) {
    return await api.put(`patientiotdevice/${patientId}/`, data);
  }
}

export default new IotService();
