export default {
  en: {
    activitiesProtocols: 'Activities / Protocols',
    patientActivitiesProtocols: 'Assign Activities and Protocols',
    availableActivities: 'Available Activities',
    currentPatientActivities: 'User Activities',
    createNewActivity: 'Create new activity',
    status: 'Status',
    pendingValidation: 'Pending validation',
    saveActivity: 'Save activity',
    nextStep: 'Next step',
    previousStep: 'Previous step',
    activityName: 'Activity name for the user',
    themes: 'Themes',
    zoneSymo: 'Zone SYMO/Healthcare partners',
    activityType: 'Activity type',
    activityId: 'Activity ID',
    discipline: 'Discipline',
    newDiscipline: 'New discipline',
    healthCarePlan: 'Health care plan',
    categoryForNurse: 'Category for nurse',
    categoryForUser: 'Category for user',
    categoryForCaregiver: 'Category for caregiver',
    voiceAssistants: 'Voice assistants',
    vocalAssistant: 'Vocal Assistant',
    virtualAssistant: 'Virtuose Virtual Assistant',
    connectedDevices: 'Connected devices',
    activityTrigger: 'Activity trigger',
    fromDate: 'From',
    toDate: 'To',
    selectDateRange: 'Select date range',
    triggerSchedule: 'Trigger schedule',
    repetitive: 'Repetitive',
    add: 'Add',
    addSchedule: 'Add an extra schedule',
    timeTo: 'to',
    validateParameters: 'Validate parameters',
    activityParameters: 'Activity parameters',
    addQuestion: 'Add question',
    roadmapAnswer: 'Detailed roadmap answer',
    answer1Letter: 'A',
    condQuestionIfYes: 'Add conditional question if answer is yes',
    scenarioVisualization: 'Scenario visualization',
    editActivity: 'Edit activity',
    confirmDeleteActivity: 'Are you sure you want to delete this activity',
    residentsAssignedToActivity: 'Users assigned to activity',
    connectedDeviceChoice: 'Connected device choice',
    valueToObtain: 'Name the of value to obtain',
    expectedData: 'Expected data',
    dateTaken: 'Date taken',
    hourTaken: 'Hour taken',
    resultTaken: 'Result taken',
    amountLbs: 'Amount of pounds',
    amountPercentage: 'Amount in percentage',
    createAlert: 'Create an Alert Threshold',
    ifForgotten: 'If forgotten',
    addNotification: 'Add a notification',
    addNurseNotification: 'People to notify',
    notification: 'Notification',
    nurseNotification: 'Nurse',
    addFirstResponderNotification: 'Add first responder notification',
    firstResponderNotification: 'First responder',
    durationIn: 'Duration in',
    oneWeek: '1 week',
    createComplementaryAlerts: 'Create complementary alerts',
    complementaryAlerts: 'Complementary Alerts',
    anActivityTypeMustBeSelected: 'An activity type must be selected',
    activityResidents: 'Activity Users',
    editScenario: 'Edit scenario',
    editConnectedDevice: 'Edit connected device',
    addAlert: 'Add an Alert Threshold',
    addAlertConfiguration: 'Add an Alert Configuration',
    monitoringTypeRequired: 'You must select a monitoring type',
    iotDeviceTypeRequired: 'You must select an IOT type',
    lengthType_days: 'Day(s)',
    lengthType_hours: 'Hour(s)',
    monitoringValue_SAT: 'Saturation',
    monitoringValue_APR: 'Blood pressure',
    monitoringValue_SAPR: 'Systolic Blood Pressure',
    monitoringValue_DAPR: 'Diastolic Blood Pressure',
    monitoringValue_INC: 'Incontinence',
    monitoringValue_RXA: 'Medication Adherence',
    monitoringValue_CIA: 'CI Anamnesis',
    monitoringValue_POA: 'Post Hosp Anamnesis',
    monitoringValue_WEI: 'Weight',
    monitoringValue_BeatsPerMin: 'Beats per minute',
    monitoringValue_Fall: 'Fall',
    monitoringValue_BodyTemperature: 'Body temperature',
    monitoringValue_Breathing: 'Breathing',
    recipients_professionals: 'Professional',
    recipients_caregiver: 'Caregiver',
    type_biggerThan: 'Greater than (>)',
    type_lesserThan: 'Less than (<)',
    type_variation: 'Variation',
    type_biggerThanHalf: 'Greater than (>) 1/2 full',
    type_lesserThanHalf: 'Less than (<) 1/2 full',
    type_wet: 'Wet',
    type_toChange: 'Must change',
    type_nottaken: 'Not taken',
    type_abnormalvalue: 'Abnormal Value',
    unitType_percent: '%',
    unitType_mmhg: 'mmhg',
    unitType_breathingCycles: 'breathing cycle',
    unitType_bpm: 'bpm',
    unitType_fahrenheit: '°F',
    unitType_celsius: '°C',
    unitType_kg: 'kg',
    unitType_lbs: 'lb',
    unitType_percentageVariation: '%',
    resetAlertsTitle: 'Reset alerts',
    resetAlertsPrompt:
      'Changing the monitoring type will reset the alerts already configured. Do you really want to reset them?',
    resetAlertsDialoguesTitle: 'Reset Dialogues',
    resetAlertsDialoguesPrompt:
      'Changing the monitoring type will reset the dialogues already configured. Do you really want to reset them?',
    resetAlertsDialoguesTriggersTitle: 'Reset Dialogues And Triggers',
    resetAlertsDialoguesTriggersPrompt:
      'Changing the monitoring type will reset the dialogues and triggers already configured. Do you really want to reset them?',
    resetAlertsTriggersTitle: 'Reset Triggers',
    resetAlertsTriggersPrompt:
      'Changing the monitoring type will reset the triggers already configured. Do you really want to reset them?',
    testDialoguesActivity: 'Test activity dialogues',

    changeNormativeDialogueVMQuestion:
      'Changing the normative field will remove the associated non-normatives dialogues and vocal messages. Do you really want to remove them?',
    changeNormativeDialogueVMTitle: 'Remove normative content',

    activitiesName: 'Protocol / Activity Name',
    activitiesDescription: 'Description',
    customize: 'Customize',
    customizePatientActivities: 'Customize Activity',
    startDate: 'Start Date',
    endDate: 'End Date',
    protocols: 'Protocols',
    allActAndProtocols: 'All',

    errorPatientActivityDoesNotExist: 'Patient Activity does not exists',
    activitiesInProtocol: 'Protocol activities',
    currentActivityDialogues: 'Activity Dialogue(s)',
    availableDialogues: 'Available Dialogue(s)',
    dialogueName: 'Dialogue',
    dialogueOrder: 'Order',
    dialogues: 'Dialogues',
    dialogue: 'Dialogue',

    timeOnly: 'Time',
    triggers: 'Triggers',
    vocalMessagesTriggers: 'Vocal Messages Triggers',
    repeatMinute: 'Relaunch Delay (minutes)',
    repeatCount: 'Relaunch Count',
    addTrigger: 'Add a trigger',
    addVocalMessagesTrigger: 'Add a Vocal Message trigger',
    errorRepeatMinuteIsNotValid: 'Relaunch Delay Value is not Valid',
    errorRepeatCountIsNotValid: 'Relaunch Count Value is not Valid',
    errorStartTimeIsNotValid: 'Start Time Value is not Valid',
    errorSameStartTimeMoreThanOnce: 'Triggers should have different start time',
    activity: 'Activity',
    uncheckToReset: 'Uncheck to reset to default threshold values',
    checkToCustomize: 'Check to customize threshold values',
    selectToCustomize: 'Select an activity to customize it',
    alertConfiguration: 'Alerts configuration',
    noCustomization: 'This activity can not be customized',
  },
  fr: {
    activitiesProtocols: 'Activités / Protocoles',
    patientActivitiesProtocols: 'Assignation des activités et protocoles',
    availableActivities: 'Activités disponibles',
    currentPatientActivities: 'Activités du patient',
    createNewActivity: 'Créer une nouvelle activité',
    status: 'Statue',
    pendingValidation: 'En attente de validation',
    saveActivity: "Enregistrer l'activité",
    nextStep: 'Prochaine étape',
    previousStep: 'Étape précédente',
    activityName: "Nom de l'activité pour l'usager",
    themes: 'Thèmes',
    zoneSymo: 'Zone SYMO/Partenaire santé',
    activityType: "Type de l'activité",
    activityId: "ID de l'activité",
    discipline: 'Discipline',
    newDiscipline: 'Nouvelle discipline',
    healthCarePlan: 'Plan de soin',
    categoryForNurse: 'Catégorie pour inf et inf aux',
    categoryForUser: 'Catégorie pour usager',
    categoryForCaregiver: 'Catégorie pour proche aidant',
    voiceAssistants: 'Assistants vocaux',
    vocalAssistant: 'Assistant vocal',
    virtualAssistant: 'Virtuose assistant virtuel',
    connectedDevices: 'Objets connectés',
    activityTrigger: "Déclencheur de l'activité",
    fromDate: 'Du',
    toDate: 'Au',
    selectDateRange: 'Sélectionner les dates',
    triggerSchedule: 'Horaire du déclenchement',
    repetitive: 'Répétitif',
    add: 'Ajouter',
    addSchedule: 'Ajouter un horaire supplémentaire',
    timeTo: 'à',
    validateParameters: 'Valider les paramètres',
    activityParameters: "Paramètres de l'activité",
    addQuestion: 'Ajouter une question',
    roadmapAnswer: 'Réponse feuille de route détaillé',
    answer1Letter: 'R',
    condQuestionIfYes: 'Ajouter une question conditionnelle si la réponse est oui',
    scenarioVisualization: 'Visualisation du scénario',
    editActivity: "Modifier l'activité",
    confirmDeleteActivity: 'Voulez-vous supprimer cette activité',
    residentsAssignedToActivity: "Usagers assignés à l'activité",
    connectedDeviceChoice: "Choix de l'objet connecté",
    valueToObtain: 'Nom de la valeur à obtenir',
    expectedData: 'Données attendues',
    dateTaken: 'Date de la prise',
    hourTaken: 'Heure de la prise',
    resultTaken: 'Résultat de la prise',
    amountLbs: 'Nombre de livres',
    amountPercentage: 'Nombre en %',
    createAlert: "Créer un seuil d'alerte",
    ifForgotten: 'Si oublié',
    addNotification: 'Personnes à notifier',
    addNurseNotification: 'Ajouter une notification infirmière',
    notification: 'Notification',
    nurseNotification: 'Infirmière',
    addFirstResponderNotification: 'Ajouter une notification proche aidant',
    firstResponderNotification: 'Proche aidant',
    durationIn: 'Durée en',
    oneWeek: '1 semaine',
    createComplementaryAlerts: 'Créer des alertes complémentaires',
    complementaryAlerts: 'Alertes complémentaires',
    anActivityTypeMustBeSelected: "Un type d'activité doit être sélectionné",
    activityResidents: "Usagers pour l'activité",
    editScenario: 'Modifier le scenario',
    editConnectedDevice: "Modifier l'objet connecté",

    activitiesName: 'Nom protocole / activité',
    activitiesDescription: 'Description',
    customize: 'Personnaliser',
    customizePatientActivities: "Personnalisation de l'activité",
    startDate: 'Date de début',
    endDate: 'Date de fin',
    protocols: 'Protocoles',
    allActAndProtocols: 'Tous',

    addAlert: "Ajouter un seuil d'alerte",
    addAlertConfiguration: "Ajouter une configuration d'alerte",
    monitoringTypeRequired: 'Vous devez choisir un type de surveillance',
    iotDeviceTypeRequired: "Vous devez choisir un type d'IOT",
    lengthType_days: 'Jour(s)',
    lengthType_hours: 'Heure(s)',
    monitoringValue_SAT: 'Saturation',
    monitoringValue_APR: 'Pression artérielle',
    monitoringValue_SAPR: 'Pression artérielle systolique',
    monitoringValue_DAPR: 'Pression artérielle diastolique',
    monitoringValue_INC: 'Incontinence',
    monitoringValue_RXA: 'Adhérence RX',
    monitoringValue_CIA: 'Anamnèse IC',
    monitoringValue_POA: 'Anamnèse Post-Hospit.',
    monitoringValue_WEI: 'Poids',
    monitoringValue_BeatsPerMin: 'Battements par minute',
    monitoringValue_Fall: 'Chute',
    monitoringValue_BodyTemperature: 'Température corporelle',
    monitoringValue_Breathing: 'Respiration',
    recipients_professionals: 'Intervenant',
    recipients_caregiver: 'Proche aidant',
    type_biggerThan: 'Plus grand que (>)',
    type_lesserThan: 'Plus petit que (<)',
    type_variation: 'Variation',
    type_biggerThanHalf: 'Plus grand que (>) 1/2 plein',
    type_lesserThanHalf: 'Plus petit que (<) 1/2 plein',
    type_wet: 'Humide',
    type_toChange: 'À changer',
    type_nottaken: 'Non pris',
    type_abnormalvalue: 'Valeur anormale',
    unitType_percent: '%',
    unitType_mmhg: 'mmhg',
    unitType_breathingCycles: 'Cycle de resp.',
    unitType_bpm: 'bpm',
    unitType_fahrenheit: '°F',
    unitType_celsius: '°C',
    unitType_kg: 'kg',
    unitType_lbs: 'lb',
    unitType_percentageVariation: '%',
    resetAlertsTitle: 'Réinitialiser les alertes',
    resetAlertsPrompt:
      'La modification du type de surveillance va réinitialiser les alertes déjà configurées. Désirer-vous vraiment les réinitialiser?',
    resetAlertsDialoguesTitle: 'Réinitialiser les dialogues',
    resetAlertsDialoguesPrompt:
      'La modification du type de surveillance va réinitialiser les dialogues déjà configurées. Désirer-vous vraiment les réinitialiser?',
    resetAlertsDialoguesTriggersTitle: 'Réinitialisation',
    resetAlertsDialoguesTriggersPrompt:
      'La modification du type de surveillance va réinitialiser les dialogues et déclencheurs déjà configurées. Désirer-vous vraiment les réinitialiser?',
    resetAlertsTriggersTitle: 'Réinitialiser les déclencheurs',
    resetAlertsTriggersPrompt:
      'La modification du type de surveillance va réinitialiser les déclencheurs déjà configurées. Désirer-vous vraiment les réinitialiser?',
    testDialoguesActivity: "Tester les dialogues de l'activité",

    changeNormativeDialogueVMQuestion:
      'La modification du champ normatif va retirer les messages vocaux et les dialogues non-normatifs associés à cette activité. Désirer-vous vraiment les retirer?',
    changeNormativeDialogueVMTitle: 'Retirer le contenu normatif',

    errorPatientActivityDoesNotExist: "Il n'existe pas d'activité sur ce patient",
    activitiesInProtocol: 'Activités du protocole:',

    currentActivityDialogues: "Dialogue(s) de l'activité",
    availableDialogues: 'Dialogue(s) disponible(s)',
    dialogueName: 'Dialogue',
    dialogueOrder: 'Ordre',
    dialogues: 'Dialogues',
    dialogue: 'Dialogue',

    timeOnly: 'Heure',
    triggers: 'Déclencheurs',
    vocalMessagesTriggers: 'Déclencheurs messages vocaux',
    repeatMinute: 'Délai de relance (minutes)',
    repeatCount: 'Nombre de relance(s)',
    addTrigger: 'Ajouter un déclencheur',
    addVocalMessagesTrigger: "Ajouter un déclencheur d'avertissement vocal",
    errorRepeatMinuteIsNotValid: "Le délai de relance n'est pas valide",
    errorRepeatCountIsNotValid: "Le nombre de relance(s) n'est pas valide",
    errorStartTimeIsNotValid: "L'heure n'est pas valide",
    errorSameStartTimeMoreThanOnce: 'Les déclencheurs doivent avoir des heures différentes',
    activity: 'Activité',
    uncheckToReset: "Décocher la case pour rétablir les valeurs par défaut de l'activité",
    checkToCustomize: "Cocher la case pour personnaliser les valeurs de l'activité",
    selectToCustomize: 'Sélectionner une activité pour la personnaliser',
    alertConfiguration: 'Configuration alerte',
    noCustomization: "Cette activité n'est pas personnalisable",
  },
};
