import api from './baseApiService';

export class ActivityService {
  async getActivities() {
    return await api.get('activities');
  }

  async getActivityById(activityId) {
    return await api.get(`activity/${activityId}`);
  }

  async deleteActivity(activityId) {
    return await api.delete(`activity/${activityId}/delete/`);
  }

  async getAvailableActivityTypes() {
    return await api.get('activitytypes');
  }
}

export default new ActivityService();
