export default {
  en: {
    protocol: 'Protocols',
    protocolName: 'Protocol name for the user',
    confirmDeleteProtocol: 'Are you sure you want to delete this protocol?',
    createNewProtocol: 'Create new protocol',
    editProtocol: 'Edit protocol',

    assignActivities: 'Activities',
    protocolActivities: 'Assign Activities to protocol',

    currentProtocolActivity: "Protocol's Activities",
    availableActivity: 'Available Activities',
    activityProtocolName: 'Name',
    activityProtocolDescription: 'Description',
  },
  fr: {
    protocol: 'Protocoles',
    protocolName: "Nom de du protocole pour l'usager",
    confirmDeleteProtocol: 'Voulez-vous supprimer ce protocole?',
    createNewProtocol: 'Créer un nouveau protocole',
    editProtocol: 'Modifier le protocole',

    assignActivities: 'Activités',
    protocolActivities: "Assignation d'activités au protocole",

    currentProtocolActivity: 'Activités du protocole',
    availableActivity: 'Activités disponibles',
    activityProtocolName: 'Nom',
    activityProtocolDescription: 'Description',
  },
};
