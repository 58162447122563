<template>
  <div>
    <v-dialog v-if="visible" value="visible" scrollable persistent max-width="1200px" :fullscreen="fullscreen">
      <v-card>
        <v-card-title>
          <v-progress-circular color="#79a314" rotate="-90" :size="50" :width="10" :value="currentProgress * 100">
          </v-progress-circular>
          <span class="ml-4 text-h4">{{ activityName }}</span>
          <v-spacer></v-spacer>
          <img v-if="!$vuetify.breakpoint.xs" class="logo--small" src="/static/img/virtual-assistant.png" />
          <v-btn icon @click="fullscreen = !fullscreen"
            ><v-icon>{{ fullscreen ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}</v-icon></v-btn
          >
        </v-card-title>
        <v-card-text>
          <template v-if="greetings">
            <v-row>
              <v-col>
                <div class="text-h4 text-center text--primary">
                  <div class="mt-4">{{ greetings }}</div>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="auto">
                <v-card hover class="mx-4" @click="start()">
                  <v-card-text>
                    <div
                      :class="[$vuetify.breakpoint.xs ? 'answer-card--small' : 'answer-card']"
                      class="d-flex align-center justify-center flex-column"
                    >
                      <span class="font-weight-bold" style="font-size: 48px; line-height: 64px">{{ $t('start') }}</span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </template>
          <template v-if="currentDialogue">
            <v-row>
              <v-col>
                <div class="text-h6 text-center">{{ currentDialogue.name }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div :class="[$vuetify.breakpoint.xs ? 'text-h5' : 'text-h4']" class="text-center text--primary">
                  <div class="my-8">{{ currentQuestionText }}</div>
                </div>
              </v-col>
            </v-row>
            <v-row :no-gutters="questionType.type === 'range'">
              <v-spacer></v-spacer>
              <v-col v-for="choice in answerChoices" :key="choice.value" cols="auto">
                <v-card
                  hover
                  class="mx-4"
                  :color="currentQuestion && currentQuestion.response === choice.value ? '#e8ffc9' : undefined"
                  :elevation="currentQuestion && currentQuestion.response === choice.value ? 8 : undefined"
                  @click="answerQuestion(choice.value, true)"
                >
                  <v-card-text>
                    <div
                      class="d-flex align-center justify-center flex-column"
                      :class="[
                        currentQuestion && currentQuestion.response === choice.value ? 'text--primary' : null,
                        $vuetify.breakpoint.xs ? 'answer-card--small' : 'answer-card',
                        questionType.type === 'range' ? 'answer-card--range' : null,
                      ]"
                    >
                      <span class="font-weight-bold">{{ choice.text }}</span>
                      <span v-if="choice.emoji" class="font-weight-bold">{{ choice.emoji }}</span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </template>
          <template v-if="completed">
            <v-row>
              <v-col>
                <div class="text-h4 text-center text--primary" style="height: 100px">
                  <div class="my-8">{{ completedPrompt }}</div>
                </div>
              </v-col>
            </v-row>
          </template>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="auto">
              <div style="height: 50px; width: 50px">
                <v-progress-circular v-if="listening" :size="50" :width="1" :color="'red'" indeterminate>
                  <v-icon color="red" large>mdi-microphone</v-icon>
                </v-progress-circular>
                <v-progress-circular v-if="thinking" :size="50" :width="1" :color="'#79a314'" indeterminate>
                  <v-icon :color="'#79a314'" large>mdi-head-cog</v-icon>
                </v-progress-circular>
                <v-progress-circular v-if="loading" :size="50" :width="2" :color="'#79a314'" indeterminate>
                </v-progress-circular>
              </div>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
        </v-card-text>
        <v-card-actions xclass="justify-end">
          <v-btn icon @click="speakerMuted = !speakerMuted"
            ><v-icon>{{ speakerMuted ? 'mdi-volume-off' : 'mdi-volume-low' }}</v-icon></v-btn
          >
          <v-btn v-if="canUseRecognition" icon @click="useMicrophone = !useMicrophone"
            ><v-icon>{{ useMicrophone ? 'mdi-microphone' : 'mdi-microphone-off' }}</v-icon></v-btn
          >
          <v-btn text @click="restart">{{ $t('restart') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn text @click="close">{{ completed ? $t('close') : $t('cancel') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import virtuoseMixin from '@/virtuoseMixin';
import translation from '@/translationMixin';
import dialogueService from '@/services/dialogueService';
import patientService from '@/services/patientService';
import activityService from '@/services/activityService';
import api from '@/services/baseApiService';
class VAService {
  async postResults(results) {
    return await api.post('dialogues_results', results);
  }
  async postChatGPT(response) {
    return await api.post('chat_gpt', response);
  }
}
function flatten(obj, childrenAccessor) {
  const array = Array.isArray(obj) ? obj : [obj];
  return array.reduce((acc, value) => {
    acc.push(value);
    let children = childrenAccessor(value);
    if (children) {
      acc = acc.concat(flatten(children, childrenAccessor));
    }
    return acc;
  }, []);
}
const synth = 'speechSynthesis' in window ? speechSynthesis : null;
var SpeechRecognition = SpeechRecognition || window.webkitSpeechRecognition;
var recognition = null;
if (SpeechRecognition) {
  recognition = new SpeechRecognition();
  recognition.interimResults = false;
  recognition.maxAlternatives = 1;
}
export default {
  mixins: [translation, virtuoseMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    activityId: {
      type: Number,
      default: null,
    },
    patientId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialogues: [],
      activity: null,
      currentDialogue: null,
      currentQuestion: null,
      speakerMuted: false,
      useMicrophone: true,
      listening: false,
      nextQuestionTimeout: null,
      completed: false,
      greetings: null,
      thinking: false,
      speaking: false,
      loading: false,
      fullscreen: false,
    };
  },
  computed: {
    currentQuestionText() {
      if (!this.currentQuestion) {
        return '';
      }
      return this.currentQuestion.languages.find((x) => x.language === this.getLanguage()).value;
    },
    questionType() {
      let regEx;
      if (this.getLanguage() === 'fr') {
        regEx = /.*échelle.* de (?<min>\d) à (?<max>\d\d?)/i;
      } else if (this.getLanguage() === 'en') {
        regEx = /.*scale.* from (?<min>\d) to (?<max>\d\d?)/i;
      }
      const rangeRegEx = regEx;
      let match = this.currentQuestionText.match(rangeRegEx);
      if (match && match.groups?.min !== undefined && match.groups?.max !== undefined) {
        return {
          type: 'range',
          min: match.groups?.min,
          max: match.groups?.max,
        };
      } else {
        return {
          type: 'yesno',
        };
      }
    },
    currentProgress() {
      if (this.completed) {
        return 1;
      }
      if (!this.currentDialogue) {
        return 0;
      }
      return this.dialogues.indexOf(this.currentDialogue) / this.dialogues.length;
    },
    canUseRecognition() {
      return !!recognition;
    },
    activityName() {
      switch (this.activity?.name) {
        case "Surveillance de l'anamnèse IC":
          return this.getLanguage() === 'en' ? 'Cardiac Assessment' : 'Bilan cardiaque';
        case "Surveillance de l'anamnèse post-hospitalisation":
          return this.getLanguage() === 'en' ? 'Post-Hospitalization Assessment' : 'Bilan post-hospitalisation';
        default:
          return this.activity?.name ?? 'Bilan';
      }
    },
    answerChoices() {
      let qType = this.questionType;
      if (qType.type === 'range') {
        let min = Number(qType.min);
        let max = Number(qType.max);
        let choices = [];
        for (let value = min; value <= max; value++) {
          choices.push({
            text: value.toString(),
            emoji: null,
            value: value.toString(),
          });
        }
        return choices;
      } else if (qType.type === 'yesno') {
        if (this.getLanguage() === 'fr') {
          return [
            {
              text: 'Oui',
              emoji: '😟',
              value: 'oui',
            },
            {
              text: 'Non',
              emoji: '😊',
              value: 'non',
            },
          ];
        } else if (this.getLanguage() === 'en') {
          return [
            {
              text: 'Yes',
              emoji: '😟',
              value: 'yes',
            },
            {
              text: 'No',
              emoji: '😊',
              value: 'no',
            },
          ];
        }
      }
      return [];
    },
  },
  watch: {
    visible() {
      if (!this.visible) {
        this.dialogues = [];
        if (this.speaking && synth) {
          synth.cancel();
        }
        if (this.listening && recognition) {
          recognition.abort();
        }
      } else {
        this.load();
      }
    },
    currentQuestionText() {
      if (this.currentQuestionText) {
        this.speak(this.currentQuestionText);
      }
    },
    speakerMuted() {
      if (this.speakerMuted && this.speaking && synth) {
        synth.cancel();
      }
    },
    useMicrophone() {
      if (!this.useMicrophone && this.listening && recognition) {
        recognition.abort();
      }
    },
  },
  methods: {
    async load() {
      if (this.activityId && this.visible) {
        this.loading = true;
        this.patient = await patientService.getPatientById(this.patientId);
        await this.loadDialoguesFromActivity(this.activityId);
        this.loading = false;
        this.greet();
      }
    },
    async loadDialoguesFromActivity(activityId) {
      let activityResult = await activityService.getActivityById(activityId);
      this.activity = activityResult;
      let dialogueIds = activityResult.dialogues?.map((x) => x.id);
      await this.loadDialogues(dialogueIds);
    },
    async loadDialogues(dialogueIds) {
      let dialogues = [];
      for (let i = 0; i < dialogueIds.length; i++) {
        let dialogue = await dialogueService.getDialogue(dialogueIds[i]);
        dialogue.children = dialogue.parameters;
        dialogue.asked = false;
        dialogue.type = 'dialogue';
        flatten(dialogue.children, (x) => x.children)
          .filter((x) => x.type === 'question')
          .forEach((x) => {
            x.response = null;
            x.effectiveResponse = null;
          });
        this.setParents(dialogue);
        dialogues.push(dialogue);
      }
      this.dialogues = dialogues;
    },
    greet() {
      switch (this.patient.gender?.code) {
        case 'W':
          this.greetings = `${this.$t('helloW')} ${this.patient.lastName}, ${this.$t(
            'areYouReadyW'
          )} ${this.activityName.toLowerCase()}?`;
          break;
        case 'M':
          this.greetings = `${this.$t('helloM')} ${this.patient.lastName}, ${this.$t(
            'areYouReadyM'
          )} ${this.activityName.toLowerCase()}?`;
          break;
        default:
          this.greetings = `${this.$t('hello')}, ${this.$t('areYouReady')} ${this.activityName.toLowerCase()}?`;
          break;
      }
      this.speak(this.greetings);
    },
    start() {
      this.greetings = null;
      this.currentDialogue = this.dialogues[0];
      this.currentQuestion = this.currentDialogue.parameters[0];
    },
    answerQuestion(response, immediate) {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
      if (this.listening) {
        recognition.abort();
      }
      if (this.nextQuestionTimeout) {
        clearTimeout(this.nextQuestionTimeout);
      }
      this.currentQuestion.response = response;
      this.currentQuestion.effectiveResponse = this.responseToEffectiveValue(response);
      this.nextQuestionTimeout = setTimeout(
        () => {
          this.gotoNextQuestion(this.responseToEffectiveValue(response));
          if (this.currentDialogue === null) {
            this.generateResults();
          }
        },
        immediate ? 800 : 2000
      );
    },
    generateResults() {
      let dialoguesResults = this.dialogues.map((d) => this.getDialogueResults(d));
      let results = {
        activityId: this.activityId,
        patientId: this.patientId,
        dialoguesResults: dialoguesResults,
        activityAbnormalValue: dialoguesResults.some((x) => x.dialogueAbnormalValue),
      };
      let service = new VAService();
      service.postResults(results);
    },
    getDialogueResults(dialogue) {
      let getDetail = (question) => {
        let response = question.children.find((x) => x.value === question.effectiveResponse);
        return {
          questionId: question.id,
          responseId: response.id,
          questionValue: question.languages.find((x) => x.language === this.getLanguage()).value,
          responseValue: response.value,
          detailResponseValue: response.detailResponse,
          abnormalValue: !!response.triggerAbnormalValue,
          children: response.children ? response.children.map(getDetail) : null,
        };
      };
      let details = dialogue.children.map(getDetail);
      let results = {
        dialogueId: dialogue.id,
        dialogueTimeout: false,
        dialogueAbnormalValue: flatten(details, (x) => x.children).some((x) => x.abnormalValue),
        dialogueDetail: details,
      };
      return results;
    },
    setParents(item) {
      if (item.children) {
        item.children.forEach((x) => {
          x.parent = item;
          this.setParents(x);
        });
      }
    },
    gotoNextQuestion(response) {
      let dialogueResponse = this.currentQuestion.children.find((x) => x.value === response);
      if (dialogueResponse.children?.length > 0) {
        this.currentQuestion = dialogueResponse.children[0];
        return;
      }
      let nextQuestion = null;
      let currentDialogue = this.currentDialogue;
      let currentQuestion = this.currentQuestion;
      while (nextQuestion == null && currentDialogue !== null) {
        let questionIndex = currentQuestion.parent.children.indexOf(currentQuestion);
        if (questionIndex < currentQuestion.parent.children.length - 1) {
          nextQuestion = currentQuestion.parent.children[questionIndex + 1];
        } else {
          if (currentQuestion.parent.type === 'response') {
            currentQuestion = currentQuestion.parent.parent;
          } else {
            let dialogueIndex = this.dialogues.indexOf(currentQuestion.parent);
            if (dialogueIndex < this.dialogues.length - 1) {
              currentDialogue = this.dialogues[dialogueIndex + 1];
              nextQuestion = currentDialogue.children[0];
            } else {
              currentDialogue = null;
            }
          }
        }
      }
      this.currentQuestion = nextQuestion;
      this.currentDialogue = currentDialogue;
      if (!currentDialogue) {
        this.doCompleted();
      }
    },
    speak(prompt, waitForAnswer) {
      waitForAnswer = waitForAnswer === undefined || waitForAnswer;
      if (this.listening) {
        recognition.abort();
      }
      if (this.speakerMuted) {
        if (!this.completed && waitForAnswer) {
          this.listenForAnswer();
        }
        return;
      }
      if (this.speaking) {
        synth.cancel();
      }
      this.speaking = true;
      let utterance = new SpeechSynthesisUtterance(prompt);
      utterance.voice = this.getVoice();
      let vm = this;
      utterance.onend = function () {
        vm.speaking = false;
        if (!vm.completed && waitForAnswer) {
          vm.listenForAnswer();
        }
      };
      synth.speak(utterance);
    },
    listenForAnswer() {
      if (!this.useMicrophone || !this.canUseRecognition) {
        return;
      }
      this.listening = true;
      let vm = this;
      recognition.lang = vm.getLanguage();
      recognition.start();
      recognition.onresult = async function (event) {
        let response = event.results[0][0].transcript;
        vm.analyzeResponse(response);
      };
      recognition.onspeechend = function () {
        recognition.stop();
      };
      recognition.onend = function () {
        vm.listening = false;
      };
    },
    getVoice() {
      let preferredvoices = [];
      switch (this.getLanguage()) {
        case 'fr':
          preferredvoices.push('Google français', 'Microsoft Nathalie - French (Canada)', 'français Canada (fr_CA)');
          break;
        case 'en':
          preferredvoices.push('Google US English', 'Microsoft Linda - English (Canada)', 'english Canada (en_CA)');
      }
      let allVoices = synth.getVoices();
      let voice = allVoices
        .filter((v) => preferredvoices.some((p) => p === v.name))
        .sort((a, b) => preferredvoices.indexOf(a.name) - preferredvoices.indexOf(b.name));
      return voice[0] || allVoices.find((x) => x.default) || allVoices[0];
    },
    doCompleted() {
      this.completed = true;
      this.completedPrompt = `${this.$t('your')} ${this.activityName.toLowerCase()} ${this.$t('wasCompleted')}.`;
      this.speak(
        `${this.$t('thankYou')}, ${this.$t('your').toLowerCase()} ${this.activityName} ${this.$t('wasCompleted')}`
      );
    },
    close() {
      this.$emit('update:visible', false);
      4;
      this.completed = false;
      this.greetings = null;
      this.currentDialogue = null;
      this.currentQuestion = null;
    },
    restart() {
      flatten(this.dialogues, (x) => x.children)
        .filter((x) => x.response)
        .forEach((x) => {
          x.response = null;
          x.effectiveResponse = null;
        });
      this.currentDialogue = null;
      this.currentQuestion = null;
      this.completed = false;
      this.greet();
    },
    responseToEffectiveValue(response) {
      let qType = this.questionType;
      if (qType.type === 'yesno') {
        let language = this.getLanguage();
        if ((response === 'oui' && language === 'fr') || (response === 'yes' && language === 'en')) {
          return 'yes';
        } else if ((response === 'non' && language === 'fr') || (response === 'no' && language === 'en')) {
          return 'no';
        }
      }

      let min = Number(qType.min);
      let max = Number(qType.max);
      let count = max - min + 1;
      let responseNumber = Number(response);
      let isFirstHalf = (responseNumber - min) / count < 0.5;
      return isFirstHalf ? 'no' : 'yes';
    },
    async analyzeResponse(response) {
      let service = new VAService();
      let analyzedResponse;
      if (this.answerChoices.some((x) => x.value === response?.toLowerCase())) {
        analyzedResponse = response.toLowerCase();
      } else {
        try {
          this.thinking = true;
          let gptResponse = await service.postChatGPT({
            question: this.currentQuestionText,
            type: this.questionType.type,
            lowerLimit: this.questionType.min,
            upperLimit: this.questionType.max,
            text_in: response,
            language: this.getLanguage(),
          });
          analyzedResponse = gptResponse.choices[0]?.message?.content?.toLowerCase();
        } catch (ex) {
          console.log('this.questionType.type: ', this.questionType.type);
          switch (this.questionType.type) {
            case 'yesno':
              this.speak(this.$t('pleaseAnswerYesOrNo'));
              break;
            case 'range':
              this.speak(this.$t('pleaseAnswerRange'));
              break;
            default:
              this.speak(this.$t('pleaseRepeat'));
          }
        } finally {
          this.thinking = false;
        }
      }
      if (this.greetings) {
        if (
          (analyzedResponse === 'oui' && this.getLanguage() === 'fr') ||
          (analyzedResponse === 'yes' && this.getLanguage() === 'en')
        ) {
          this.start();
        } else {
          switch (analyzedResponse) {
            case 'indécis':
            case 'undecided':
              this.speak(this.$t('pleaseRepeat'));
              break;
            case 'insulte':
            case 'insult':
              this.speak(this.$t('bePolite'), false);
              break;
            default:
              break;
          }
        }
        return;
      }
      if (this.answerChoices.some((x) => x.value === analyzedResponse)) {
        this.answerQuestion(analyzedResponse);
      } else {
        switch (analyzedResponse) {
          case 'indécis':
          case 'undecided':
            this.speak(this.$t('pleaseRepeat'));
            break;
          case 'insulte':
          case 'insult':
            this.speak(this.$t('bePolite'), false);
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.logo--small {
  height: 80px;
}
.answer-card {
  height: 200px;
  width: 200px;
  font-size: 64px;
  line-height: 104px;
}
.answer-card--small {
  height: 100px;
  width: 100%;
  min-width: 100px;
  font-size: 36px;
  line-height: 48px;
}
.answer-card--range {
  height: 60px !important;
  width: 60px !important;
  font-size: 36px;
  line-height: 48px;
}
</style>
