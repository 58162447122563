export default {
  en: {
    deleteResidentQuestion: 'Are you sure you want to delete this user?',
    patients: 'Patients',
    patient: 'Patient',
    nasm: 'Health Insurance Number',
    birthDate: 'Birth Date',
    deathDate: 'Date of death',
    facility: 'Facility',
    admitDate: 'Admission Date',
    serviceStartDate: 'Service Start Date',
    city: 'City',
    country: 'Country',
    province: 'Province',
    department: 'Department',
    createdAt: 'Created at',
    externalId: 'External ID',
    patientType: 'Patient Type',
    roombed: 'Bed Number',
    language: 'Language',
    errorLanguageDoesNotExist: 'Language does not exists',
    caregiverFirstName: 'Caregiver Last Name',
    caregiverLastName: 'Caregiver First Name',
    caregiverRelation: 'Relationship',
    daughter: 'Daughter',
    son: 'Son',
    spouse: 'Spouse',
    daughterInLaw: 'Daughter-in-law',
    sonInLaw: 'Son-in-law',
    sister: 'Sister',
    brother: 'Brother',
    neighbor: 'Neighbor',
    friend: 'Friend',
    father: 'Father',
    mother: 'Mother',

    createResident: 'Add User',
    editResident: 'Edit User',
    currentPatientActivities: 'Resident Activities',
    availableActivities: 'Available Activities',
    patientMonitoring: 'Clinical Overview',
    patientVitalSigns: 'Vital Signs (RT)',
    monitoring: 'Monitoring',

    statusLevels: 'Monitoring Values',
    timeFilterType: 'Time Filter Type',
    filter24h: 'Last 24 Hours',
    filterdays: 'Last Days',
    filterhours: 'Last Hours',
    filterrange: 'Range',
    nbDays: 'Days',
    nbHours: 'Hours',
    messages: 'Messages',
    legend: 'Legend',
    incontinence: 'Incontinence',
    standardValues: 'Standard Values',
    fdeValues: 'Fall Detection Values',
    fallDetected: 'Fall detected',
    noFallDetected: 'No fall detected',

    errorFirstNameMustBeProvided: 'First name must be provided',
    errorLastNameMustBeProvided: 'Last name must be provided',
    errorBirthDateMustBeProvided: 'Birth data must be provided',
    errorNasmMustBeProvided: 'Health insurance number must be provided',

    senderLastName: 'Last Name',
    senderFirstName: 'First Name',
    activeProximite: 'Active Proximité',
    messageDatetime: 'Received at',
    messageContent: 'Message Content',
    responseDatetime: 'Sent at',
    responseContent: 'Health Professional Response',
    answeredBy: 'Answered by',
    answer: 'Answer',
    newMessage: 'New',
    newChat: 'Chat (patient)',
    newVideo: 'Video Call (patient)',

    senderMessage: 'Sender Message',
    recipientMessage: 'Health Professional Message',
    send: 'Send',
    answerResidentMessage: 'Answer Message',
    actions: 'Actions',
    seeBtnTooltip: 'Read message',
    activities: 'Activities',
    communicators: 'Communicators',
    healthcareProfessionals: 'Healthcare Professionals',

    cohortMonitoring: 'Cohorts',
    takenMedication: 'Drug Taken',

    average: 'Average',
    minimum: 'Minimum',
    maximum: 'Maximum',
    valuesNumber: 'Number of values',
    weekOf: 'Week of',
  },
  fr: {
    deleteResidentQuestion: 'Êtes-vous certain de vouloir supprimer cet usager ?',
    patients: 'Patients',
    patient: 'Patient',
    nasm: "Numéro d'assurance maladie",
    birthDate: 'Date de naissance',
    deathDate: 'Date de décès',
    facility: 'Installation',
    admitDate: "Date d'admission",
    serviceStartDate: 'Date de début de service',
    city: 'Ville',
    country: 'Pays',
    province: 'Province',
    department: 'Département',
    createdAt: 'Date de création',
    updatedAt: 'Date de modification',
    externalId: 'Identifiant externe',
    patientType: 'Type de patient',
    roombed: 'Chambre-lit',
    language: 'Langue',
    errorLanguageDoesNotExist: "Cette langue n'existe pas",
    caregiverFirstName: 'Prénom proche-aidant',
    caregiverLastName: 'Nom proche-aidant',
    caregiverRelation: 'Lien de parenté',
    daughter: 'Fille',
    son: 'Fils',
    spouse: 'Conjoint(e)',
    daughterInLaw: 'Bru',
    sonInLaw: 'Gendre',
    sister: 'Soeur',
    brother: 'Frère',
    neighbor: 'Voisin(e)',
    friend: 'Ami(e)',
    father: 'Père',
    mother: 'Mère',

    createResident: 'Ajouter un usager',
    editResident: 'Modifier un usager',
    currentPatientActivities: "Activités de l'usager",
    availableActivities: 'Activités disponibles',
    patientMonitoring: 'Portrait clinique',
    patientVitalSigns: 'Signes vitaux (TR)',
    monitoring: 'Surveillance',

    statusLevels: 'Valeurs de surveillance',
    timeFilterType: 'Type de filtre temporel',
    filter24h: 'Dernières 24 heures',
    filterdays: 'Nombre de jours précedents',
    filterhours: "Nombre d'heures précedentes",
    filterrange: 'Période',
    nbDays: 'Nombre de jours',
    nbHours: "Nombre d'heures",
    messages: 'Messages',
    legend: 'Légende',
    incontinence: 'Incontinence',
    standardValues: 'Valeurs standard',
    fdeValues: 'Détection de la chute',
    fallDetected: 'Chute détectée',
    noFallDetected: 'Aucune chute détectée',

    errorFirstNameMustBeProvided: 'Le prénom doit être spécifié',
    errorLastNameMustBeProvided: 'Le nom doit être spécifié',
    errorBirthDateMustBeProvided: 'La date de naissance doit être spécifiée',
    errorNasmMustBeProvided: "Le numéro d'assurance maladie doit être spécifié",

    senderLastName: 'Nom',
    senderFirstName: 'Prénom',
    activeProximite: 'Actif Proximité',
    messageDatetime: 'Reçu à',
    messageContent: 'Contenu du message',
    responseDatetime: 'Répondu à',
    responseContent: 'Réponse intervenant-e',
    answeredBy: 'Répondu par',
    answer: 'Répondre',
    newMessage: 'Nouveau',
    newChat: 'Clavardage (patient)',
    newVideo: 'Appel vidéo (patient)',

    senderMessage: 'Message du correspondant',
    recipientMessage: "Message de l'intervenant-e",
    send: 'Envoyer',
    answerResidentMessage: 'Répondre au message',
    actions: 'Actions',
    seeBtnTooltip: 'Lire le message',
    activities: 'Activités',
    communicators: 'Communicateurs',
    healthcareProfessionals: 'Intervenants',

    cohortMonitoring: 'Cohortes',
    takenMedication: 'Médicament pris',

    average: 'Moyenne',
    minimum: 'Minimum',
    maximum: 'Maximum',
    valuesNumber: 'Nombre de valeurs',
    weekOf: 'Semaine du',
  },
};
