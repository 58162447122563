<template>
  <v-card>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <template v-if="isFilter24H">
      <ChartDetails
        ref="chartDetails"
        :chart-title="chartTitle"
        :y-axis="yAxis"
        :values="patientValues"
        :series="bloodPressureSeries"
      />
    </template>

    <template v-else>
      <ChartFiltered
        ref="chartFiltered"
        :chart-title="chartTitle"
        :duration="statsDuration"
        :y-axis="yAxis"
        :values="patientValues"
        :series="bloodPressureSeries"
        :filters="filters"
      />
    </template>
  </v-card>
</template>

<script>
import ChartDetails from './ChartDetails.vue';
import ChartFiltered from './ChartFiltered.vue';
import patientMonitoringService from '@/services/patientMonitoringService';

export default {
  name: 'PatientBloodPressure',
  components: {
    ChartFiltered,
    ChartDetails,
  },
  props: {
    patientId: {
      type: Number,
      default: null,
    },

    filters: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      yAxis: {
        Ymax: 220,
        YStepSize: 20,
        title: 'bloodPressure',
      },
      chartTitle: 'bloodPressureDetails',
      bloodPressureSeries: [],
      patientValues: [],
      statsDuration: null,
      error: null,
    };
  },

  computed: {
    isFilter24H: function () {
      return !this.filters || this.filters.timeFilterType === '24h' || !this.filters.timeFilterType;
    },
  },
  watch: {
    patientId: function () {
      this.getBloodPressureData();
    },

    filters: function () {
      this.getBloodPressureData();
    },
  },

  created() {
    this.getBloodPressureData();
  },

  methods: {
    async getBloodPressureData() {
      this.patientValues = [];
      this.bloodPressureSeries = [];

      let patientInfo = {
        patientId: this.patientId,
        timeFilterType: !this.filters ? '24h' : this.filters.timeFilterType,
        timeFilterValue: this.filters?.timeFilterValue,
        dateFrom: this.filters?.dateFrom,
        dateTo: this.filters?.dateTo,
      };

      try {
        let patientData = await patientMonitoringService.getPatientBloodPressure(patientInfo);
        this.statsDuration = patientData.duration;
        this.patientValues = patientData.results;

        this.bloodPressureSeries = [
          {
            title: 'systolic',
            thresholds: {
              minThreshold: patientData.thresholdMinSystolic,
              maxThreshold: patientData.thresholdMaxSystolic,
              title: 'SystoliqueThresholds',
            },
          },
          {
            title: 'diastolic',
            thresholds: {
              minThreshold: patientData.thresholdMinDiastolic,
              maxThreshold: patientData.thresholdMaxDiastolic,
              title: 'DiastoliqueThresholds',
            },
          },
        ];
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>
