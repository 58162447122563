<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-dialog v-if="show" value="show" scrollable persistent max-width="550">
      <v-card>
        <ModalTitle :title="$t('userPreferences')" />
        <v-card-text>
          <v-form ref="userPreferencesForm">
            <v-row>
              <v-col>
                <span>{{ $t('language') }}</span>

                <v-radio-group ref="preferencesRadioButtons" v-model="formData.userLanguage" row>
                  <v-radio ref="languageRadioButtonFr" label="Français" value="fr" color="primary"></v-radio>
                  <v-radio ref="languageRadioButtonEn" label="English" value="en" color="primary"></v-radio>
                </v-radio-group>

                <span>{{ $t('notifications') }}</span>

                <v-checkbox
                  ref="emailNotifications"
                  v-model="formData.emailNotifications"
                  hide-details
                  color="primary"
                  :label="$t('userPreferences.emailNotificationsQuestion')"
                />
                <v-checkbox
                  ref="SMSNotifications"
                  v-model="formData.SMSNotifications"
                  hide-details
                  color="primary"
                  :label="$t('userPreferences.SMSNotificationsQuestion')"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text @click="cancel()">{{ $t('cancel') }}</v-btn>
          <v-btn ref="ok" text color="primary" @click="ok()">{{ $t('ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import userService from '@/services/userService';
export default {
  name: 'UserPreferences',
  mixins: [translation],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      availableLanguages: [],
      error: null,
    };
  },

  watch: {
    show: function () {
      if (this.show) this.getUserPreferences();
    },
  },

  methods: {
    async getUserPreferences() {
      try {
        let userPreferences = await userService.getUserPreferences();
        this.formData = userPreferences;
      } catch (error) {
        this.error = error;
      }
    },

    cancel: function () {
      this.$emit('update:show', false);
    },

    async ok() {
      let isValid = this.$refs.userPreferencesForm.validate();

      if (!isValid) {
        return;
      }

      this.changeLanguage(this.formData.userLanguage);

      try {
        let data = {
          emailNotifications: this.formData.emailNotifications,
          SMSNotifications: this.formData.SMSNotifications,
          userLanguage: this.formData.userLanguage,
        };

        await userService.updateUserPreferences(data);
      } catch (error) {
        this.error = error;
      }

      this.$emit('update:show', false);
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
  },
};
</script>
