<template>
  <v-card>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <ChartDetails
      ref="chartDetails"
      :chart-title="chartTitle"
      :y-axis="yAxis"
      :values="patientValues"
      :series="weightSeries"
      :filters="filters"
    />
    <v-row class="px-4">
      <v-col cols="auto">
        <v-select
          ref="unitWeight"
          v-model="selectedUnitWeight"
          item-text="name"
          item-value="code"
          :label="$t('unitWeight')"
          outlined
          dense
          hide-details
          :items="availableUnitsWeight"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import patientMonitoringService from '@/services/patientMonitoringService';
import translationMixin from '@/translationMixin';
import ChartDetails from './ChartDetails.vue';

export default {
  name: 'PatientWeight',
  components: {
    ChartDetails,
  },
  mixins: [translationMixin],

  props: {
    patientId: {
      type: Number,
      default: null,
    },

    filters: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      yAxis: {
        Ymax: 100,
        Ymin: 60,
        YStepSize: 5,
        title: 'weight',
      },
      chartTitle: 'weightDetails',
      weightSeries: [],
      patientData: null,
      patientValues: null,
      error: null,
      selectedUnitWeight: 'kg',
      availableUnitsWeight: [
        {
          code: 'kg',
          name: this.$t('kilogram'),
        },
        {
          code: 'lbs',
          name: this.$t('pound'),
        },
      ],
    };
  },

  watch: {
    patientId: function () {
      this.getWeightData();
    },

    filters: function () {
      this.getWeightData();
    },

    selectedUnitWeight: function () {
      this.convertUnitWeight();
    },
  },

  mounted() {
    this.getWeightData();
  },

  methods: {
    async getWeightData() {
      this.patientValues = [];
      this.weightSeries = [];

      let patientInfo = {
        patientId: this.patientId,
        timeFilterType: !this.filters ? '24h' : this.filters.timeFilterType,
        timeFilterValue: this.filters?.timeFilterValue,
        dateFrom: this.filters?.dateFrom,
        dateTo: this.filters?.dateTo,
        statusLevelIds: this.filters?.statusLevelIds,
      };

      try {
        this.patientData = await patientMonitoringService.getPatientWeight(patientInfo);
        this.patientValues = this.patientData.results;

        if (this.selectedUnitWeight === 'lbs') this.convertUnitWeight();

        this.weightSeries = [
          {
            title: 'weight',
            thresholds: {
              minThreshold: this.selectedUnitWeight === 'lbs' ? 165 : this.patientData.thresholdMin,
              maxThreshold: this.selectedUnitWeight === 'lbs' ? 176 : this.patientData.thresholdMax,
              title: 'weightThresholds',
            },
          },
        ];
      } catch (error) {
        this.error = error;
      }
    },

    convertUnitWeight: function () {
      if (this.selectedUnitWeight === 'lbs') {
        this.yAxis.Ymax = 220;
        this.yAxis.Ymin = 132;
        this.yAxis.YStepSize = 10;
      } else {
        this.yAxis.Ymax = 100;
        this.yAxis.Ymin = 60;
      }

      this.patientValues = this.patientData.results.map((weight) => {
        return {
          alertDatetime: weight.alertDatetime,
          alertValues: weight.alertValues.map((weightValue) =>
            weightValue && this.selectedUnitWeight === 'lbs' ? (weightValue *= 2.2).toFixed(1) : weightValue
          ),
        };
      });

      this.weightSeries = [
        {
          title: 'weight',
          thresholds: {
            minThreshold: this.selectedUnitWeight === 'lbs' ? 165 : this.patientData.thresholdMin,
            maxThreshold: this.selectedUnitWeight === 'lbs' ? 176 : this.patientData.thresholdMax,
            title: 'weightThresholds',
          },
        },
      ];
    },
  },
};
</script>
