<template>
  <div ref="chartContainer" class="pa-4">
    <div ref="chartBody">
      <Bar ref="barChart" :chart-options="chartOptions" :chart-data="chartData" class="barChart" />
    </div>
  </div>
</template>

<script>
import {
  Chart as ChartJS,
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  PointElement,
  TimeScale,
  LinearScale,
  LineController,
} from 'chart.js';
import { Bar } from 'vue-chartjs/legacy';
import { format } from 'date-fns';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import { chartColor } from './chartColor';
import translationMixin from '@/translationMixin';
import 'chartjs-adapter-date-fns';
import { frCA, enCA } from 'date-fns/locale';
import { StatsDuration } from './constants';

ChartJS.register(
  BarElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineController,
  PointElement,
  annotationPlugin,
  zoomPlugin,
  TimeScale
);

export default {
  name: 'ChartFiltered',
  components: {
    Bar,
  },
  mixins: [translationMixin],
  props: {
    chartTitle: {
      type: String,
      default: '',
    },

    values: {
      type: Array,
      default: () => [],
    },
    yAxis: {
      type: Object,
      default() {
        return {
          Ymax: 150,
          Ymin: 0,
          YStepSize: 20,
          title: '',
        };
      },
    },
    series: {
      type: Array,
      default: () => [],
    },
    duration: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      chartData: {
        datasets: [],
      },
      chartOptions: {
        animation: false,
        scales: {
          y: {
            title: {
              display: true,
            },
            min: this.yAxis.Ymin,
            max: this.yAxis.Ymax,
            ticks: {
              stepSize: this.yAxis.YStepSize,
            },
          },
          x: {
            stacked: true,
            type: 'time',
            time: {
              unit: 'hour',
            },
            min: 0,
            max: 0,
            ticks: {
              source: 'auto',
              callback: (value) => this.formatXAxisValues(value),
            },
          },
          x2: {
            type: 'time',
            display: true,
            time: {
              unit: 'day',
              displayFormats: {
                day: 'yyyy-MM-dd',
                year: 'yyyy',
              },
            },
            title: {
              display: true,
            },
            min: 0,
            max: 0,
            grid: {
              color: () => chartColor.delimiterBorderColor,
            },
          },
        },
        plugins: {
          tooltip: {
            titleAlign: 'center',
            callbacks: {
              title: (ctx) => this.formatLabelDate(ctx),
              afterTitle: (ctx) => ctx[0].dataset.label,
              afterBody: (ctx) => this.addTooltipStatsInfo(ctx),
              label: () => null,
            },
          },
          datalabels: {
            display: false,
          },
          legend: {
            onClick: () => null,
            title: {
              display: true,
            },
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              mode: 'x',
            },
            pan: {
              enabled: true,
              mode: 'x',
            },
          },
          autocolors: false,
          annotation: {
            annotations: {},
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  watch: {
    values: function () {
      this.init();
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init: function () {
      this.setTextLanguage();

      if (!this.series || !this.duration) return;
      this.showChart();
      this.setXAxis();

      if (this.values?.length < 1) return;
      this.insertChartValues();
    },

    showChart: function () {
      this.chartData.datasets = [];

      this.chartOptions.scales.y.min = this.yAxis.Ymin;
      this.chartOptions.scales.y.max = this.yAxis.Ymax;
      this.chartOptions.scales.y.ticks.stepSize = this.yAxis.YStepSize;

      this.chartData.datasets = this.series.map((serie) => {
        return {
          label: this.$t(serie.title),
          borderColor: [],
          backgroundColor: [],
          hoverBackgroundColor: [],
          data: [],
          maxBarThickness: 18,
          borderWidth: 3,
          borderRadius: 10,
          borderSkipped: false,
        };
      });

      let thresholds = this.series.map((serie, datasetIndex) => {
        return {
          label: this.$t(serie.thresholds.title),
          borderWidth: 1.5,
          borderColor: datasetIndex === 0 ? chartColor.mainBorderLegendColor : chartColor.correctSecondaryDataColor,
          backgroundColor: chartColor.backgroundThreshold,
          borderDash: [12, 5],
          type: 'line',
        };
      });

      this.chartData.datasets.push(...thresholds);

      this.series.forEach((serie, thresholdIndex) => {
        const { minThreshold, maxThreshold } = serie.thresholds;

        if (minThreshold) {
          this.chartOptions.plugins.annotation.annotations['limit' + thresholdIndex] = {
            type: 'line',
            mode: 'horizontal',
            borderDash: [13, 7],
            yMin: this.series[thresholdIndex].thresholds.minThreshold,
            yMax: this.series[thresholdIndex].thresholds.minThreshold,
            borderColor:
              thresholdIndex === 0 ? chartColor.mainBorderLegendColor : chartColor.secondaryBorderLegendColor,
            borderWidth: 1,
          };
        }

        if (maxThreshold) {
          this.chartOptions.plugins.annotation.annotations['limit' + (thresholdIndex + 2)] = {
            type: 'line',
            mode: 'horizontal',
            borderDash: [13, 7],
            yMin: this.series[thresholdIndex].thresholds.maxThreshold,
            yMax: this.series[thresholdIndex].thresholds.maxThreshold,
            borderColor:
              thresholdIndex === 0 ? chartColor.mainBorderLegendColor : chartColor.secondaryBorderLegendColor,
            borderWidth: 1,
          };
        }
      });
    },

    insertChartValues: function () {
      this.values.forEach((eachAlert) => {
        for (let alertIndex = 0; alertIndex < eachAlert?.values?.length; alertIndex++) {
          {
            this.chartData.datasets[alertIndex].data.push({
              x: new Date(eachAlert.time).getTime(),
              y: [eachAlert.values[alertIndex].minimum, eachAlert.values[alertIndex].maximum],
              average: eachAlert.values[alertIndex].average,
              count: eachAlert.values[alertIndex].count,
            });

            this.selectBarColor(eachAlert, alertIndex);
          }
        }
      });

      if (this.chartData.datasets[0]?.data[0]) {
        this.chartOptions.scales.x.min = this.chartData.datasets[0].data[0].x;
        this.chartOptions.scales.x.max = this.chartData.datasets[0].data.at(-1).x;
        this.chartOptions.scales.x2.min = this.chartData.datasets[0].data[0].x;
        this.chartOptions.scales.x2.max = this.chartData.datasets[0].data.at(-1).x;

        this.chartOptions.plugins.zoom.limits = {
          x: {
            min: new Date(this.chartOptions.scales.x.min).getTime(),
            max: new Date(this.chartOptions.scales.x.max).getTime(),
          },
        };
      }

      if (this.getLanguage() === 'fr') {
        this.chartOptions.scales.x.time.displayFormats = {
          hour: 'HH:mm',
        };
      } else {
        this.chartOptions.scales.x.time.displayFormats = {
          hour: 'h:mm b',
        };
      }

      this.$nextTick(() => {
        if (this.$refs.barChart?.getCurrentChart()?.canvas.clientWidth > 0) {
          let visibleData = this.$refs.barChart.getCurrentChart().canvas.clientWidth / this.values.length;

          if (visibleData < 12) {
            this.$refs.barChart.getCurrentChart().zoom(Math.min(1.2 / (visibleData / 9), 2));
          }
        }
      });
    },

    formatLabelDate: function (data) {
      let currentLanguage = this.getLanguage();

      switch (this.duration) {
        case StatsDuration.HOUR:
          return currentLanguage === 'fr'
            ? format(new Date(data[0].raw.x), 'yyyy-MM-dd HH:mm:ss')
            : format(new Date(data[0].raw.x), 'yyyy-MM-dd hh:mm:ss b');
        case StatsDuration.DAY:
          return format(new Date(data[0].raw.x), 'yyyy-MM-dd');
        case StatsDuration.WEEK: {
          let language = this.getLanguage() === 'fr' ? frCA : enCA;
          let dateTimeFormat = this.getLanguage() === 'fr' ? 'd MMM yyyy' : 'MMM d,  yyyy';

          return this.$t('weekOf') + ' ' + format(new Date(data[0].raw.x), dateTimeFormat, { locale: language });
        }
        case StatsDuration.MONTH: {
          let monthTitle = format(new Date(data[0].raw.x), 'MMMM yyyy', {
            locale: currentLanguage === 'fr' ? frCA : enCA,
          });

          return monthTitle.charAt(0).toUpperCase() + monthTitle.slice(1);
        }
        default:
          return format(new Date(data[0].raw.x), 'yyyy');
      }
    },

    setXAxis: function () {
      switch (this.duration) {
        case StatsDuration.HOUR:
          this.chartOptions.scales.x.time.unit = 'hour';
          this.chartOptions.scales.x2.time.unit = 'day';
          break;
        case StatsDuration.DAY:
          this.chartOptions.scales.x.time.unit = 'day';
          this.chartOptions.scales.x2.time.unit = 'year';
          break;
        case StatsDuration.WEEK: {
          this.chartOptions.scales.x.time.unit = 'week';
          this.chartOptions.scales.x2.time.unit = 'year';
          break;
        }
        case StatsDuration.MONTH: {
          this.chartOptions.scales.x.time.unit = 'month';
          this.chartOptions.scales.x2.display = false;
          break;
        }
        default:
          this.chartOptions.scales.x.time.unit = 'year';
          this.chartOptions.scales.x2.display = false;
          break;
      }
    },

    formatXAxisValues: function (value) {
      let language = this.getLanguage() === 'fr' ? frCA : enCA;
      let dateTimeFormat = this.getLanguage() === 'fr' ? 'd MMM' : 'MMM do';
      let valueDate = new Date(value);

      if (this.duration === StatsDuration.DAY) {
        if (valueDate instanceof Date && !isNaN(valueDate))
          return format(new Date(value), dateTimeFormat, { locale: language });
      }

      if (this.duration === StatsDuration.WEEK) {
        if (valueDate instanceof Date && !isNaN(valueDate))
          return this.$t('weekOf') + ' ' + format(valueDate, dateTimeFormat, { locale: language });
      }

      if (this.duration === StatsDuration.MONTH) {
        let monthFormat = format(valueDate, 'MMMM yyyy', { locale: language });
        return monthFormat.charAt(0).toUpperCase() + monthFormat.slice(1);
      }

      return value;
    },

    selectBarColor: function (alert, alertIndex) {
      let invisibleBar = alert.values[alertIndex].maximum - alert.values[alertIndex].minimum < 3;

      if (
        alert.values[alertIndex].minimum < this.series[alertIndex].thresholds.minThreshold ||
        alert.values[alertIndex].maximum > this.series[alertIndex].thresholds.maxThreshold
      ) {
        this.chartData.datasets[alertIndex].backgroundColor.push(chartColor.incorrectDataColor);
        this.chartData.datasets[alertIndex].hoverBackgroundColor.push(chartColor.hoveredIncorrectDataColor);
        this.chartData.datasets[alertIndex].borderColor.push(
          invisibleBar ? chartColor.incorrectDataColor : 'transparent'
        );
        return;
      }

      if (alertIndex === 0) {
        this.chartData.datasets[alertIndex].backgroundColor.push(chartColor.correctFirstDataColor);
        this.chartData.datasets[alertIndex].hoverBackgroundColor.push(chartColor.hoveredCorrectFirstDataColor);
        this.chartData.datasets[alertIndex].borderColor.push(
          invisibleBar ? chartColor.correctFirstDataColor : 'transparent'
        );
      } else {
        this.chartData.datasets[alertIndex].backgroundColor.push(chartColor.correctSecondaryDataColor);
        this.chartData.datasets[alertIndex].hoverBackgroundColor.push(chartColor.hoveredCorrectSecondaryDataColor);
        this.chartData.datasets[alertIndex].borderColor.push(
          invisibleBar ? chartColor.correctSecondaryDataColor : 'transparent'
        );
      }
    },

    addTooltipStatsInfo: function (data) {
      let yValues = data[0].raw.y;
      let valuesCount = data[0].raw.count;
      let averageValue = data[0].raw.average;

      let statsContent =
        `${this.$t('minimum')}: ${Math.min(...yValues)}` +
        '\n' +
        `${this.$t('average')}: ${averageValue}` +
        '\n' +
        `${this.$t('maximum')}: ${Math.max(...yValues)}` +
        '\n' +
        `${this.$t('valuesNumber')}: ${valuesCount}`;

      return statsContent;
    },

    setTextLanguage: function () {
      this.chartOptions.scales.x2.title.text = this.$t('time');
      this.chartOptions.scales.y.title.text = this.$t(this.yAxis.title);
      this.chartOptions.plugins.legend.title.text = this.$t(this.chartTitle);
    },
  },
};
</script>

<style scoped>
.barChart {
  height: 500px;
}
</style>
