<template>
  <v-card>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <template v-if="isFilter24H">
      <ChartDetails
        ref="chartDetails"
        :chart-title="chartTitle"
        :y-axis="yAxis"
        :values="patientValues"
        :series="bodyTemperatureSeries"
      />
    </template>

    <template v-else>
      <ChartFiltered
        ref="chartFiltered"
        :chart-title="chartTitle"
        :duration="statsDuration"
        :y-axis="yAxis"
        :values="patientValues"
        :series="bodyTemperatureSeries"
        :filters="filters"
      />
    </template>
  </v-card>
</template>

<script>
import ChartDetails from './ChartDetails.vue';
import ChartFiltered from './ChartFiltered.vue';
import patientMonitoringService from '@/services/patientMonitoringService';

export default {
  name: 'PatientBodyTemperature',
  components: {
    ChartFiltered,
    ChartDetails,
  },
  props: {
    patientId: {
      type: Number,
      default: null,
    },

    filters: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      yAxis: {
        Ymax: 43,
        Ymin: 33,
        YStepSize: 2,
        title: 'bodyTemperature',
      },
      chartTitle: 'bodyTemperatureDetails',
      bodyTemperatureSeries: [],
      patientValues: null,
      statsDuration: null,
      error: null,
    };
  },

  computed: {
    isFilter24H: function () {
      return !this.filters || this.filters.timeFilterType === '24h' || !this.filters.timeFilterType;
    },
  },

  watch: {
    patientId: function () {
      this.getBodyTemperatureData();
    },

    filters: function () {
      this.getBodyTemperatureData();
    },
  },

  created() {
    this.getBodyTemperatureData();
  },

  methods: {
    async getBodyTemperatureData() {
      this.patientValues = [];
      this.bodyTemperatureSeries = [];

      let patientInfo = {
        patientId: this.patientId,
        timeFilterType: !this.filters ? '24h' : this.filters.timeFilterType,
        timeFilterValue: this.filters?.timeFilterValue,
        dateFrom: this.filters?.dateFrom,
        dateTo: this.filters?.dateTo,
      };

      try {
        let patientData = await patientMonitoringService.getPatientBodyTemperature(patientInfo);
        this.statsDuration = patientData.duration;
        this.patientValues = patientData.results;

        this.bodyTemperatureSeries = [
          {
            title: 'bodyTemperature',
            thresholds: {
              minThreshold: patientData.thresholdMin,
              maxThreshold: patientData.thresholdMax,
              title: 'bodyTemperatureThresholds',
            },
          },
        ];
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>
