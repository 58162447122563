<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent :max-width="'1400px'">
      <v-card>
        <ModalTitle :title="$t('patientCohorts')" />

        <WaitModal :show="showWaitModal" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <div>
            <PatientInfoDetails ref="patientDetails" class="mb-4" :patient-info="patientInfo" />

            <list-item-selector
              :columns-assigned="columns"
              :columns-available="columns"
              :all-items="allCohorts"
              :assigned-items.sync="formData.assignedCohorts"
              :available-title="$t('availableCohorts')"
              :assigned-title="$t('currentPatientCohorts')"
            ></list-item-selector>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            :id="getIdByName('save')"
            ref="save"
            text
            color="primary"
            @click.stop="updatePatientAssociatedCohort()"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import PatientInfoDetails from '@/components/Resident/PatientAssignDetails';
import ListItemSelector from '@/components/ListItemsSelector.vue';
import cohortsService from '@/services/cohortsService';

export default {
  name: 'PatientCohorts',

  components: {
    PatientInfoDetails,
    ListItemSelector,
  },
  mixins: [translation, accessibility],

  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },
    patientInfo: {
      type: Object,
      required: false,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      selectedPatientId: null,

      error: null,
      showWaitModal: false,

      allCohorts: [],
      formData: {
        assignedCohorts: [],
      },
      originalFormData: {},
    };
  },
  computed: {
    columns: function () {
      return [
        {
          text: 'cohortName',
          value: 'name',
          filterable: false,
        },
        {
          text: 'description',
          value: 'description',
          filterable: true,
        },
      ];
    },
  },

  watch: {
    patientId: function () {
      this.init();
    },
    show() {
      if (this.show) {
        this.init();
      } else {
        this.loadedPatientId = null;
      }
    },
  },
  methods: {
    async init() {
      if (!this.patientId || !this.show || this.loadedPatientId === this.patientId) {
        return;
      }

      this.loadedPatientId = this.patientId;
      this.formData.assignedCohorts = [];

      await this.loadCohorts();
    },

    async loadCohorts() {
      this.showWaitModal = true;
      try {
        let [allCohorts, patientCohorts] = await Promise.all([
          cohortsService.getCohorts(),
          cohortsService.getPatientCohorts(this.patientId),
        ]);
        this.allCohorts = allCohorts.map((item) => {
          return {
            id: item.id,
            name: item.name,
            description: item.description,
          };
        });

        this.formData.assignedCohorts = this.allCohorts.filter((x) => patientCohorts.some((p) => p.id === x.id));
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    closeDialog: function (refresh) {
      this.$emit('update:show', false);
      if (refresh) {
        this.$emit('saved');
      }
    },

    async updatePatientAssociatedCohort() {
      let data = {
        cohortsIds: this.formData.assignedCohorts.map((x) => x.id),
      };
      try {
        await cohortsService.updatePatientCohort(this.patientId, data);
        this.closeDialog(true);
        this.$emit('refresh', true);
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
  },
};
</script>

<style scoped></style>
