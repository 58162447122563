export default {
  en: {
    'user.users': 'Users',
    'user.user': 'User',
    'user.confirmDeleteUser': 'Are you sure you want to delete this user?',
    'user.createNewUser': 'Create New User',
    'user.editUser': 'Edit User',
    'user.email': 'Email',
    'user.createdAt': 'Created At',
    'user.lastLogin': 'Last login',
    'user.accessGroups': 'Access Groups',
    'userPreferences.emailNotificationsQuestion': 'Receive email notifications',
    'userPreferences.SMSNotificationsQuestion': 'Receive text message notifications',
  },
  fr: {
    'user.users': 'Utilisateurs',
    'user.user': 'Utilisateur',
    'user.confirmDeleteUser': 'Voulez-vous supprimer cet utilisateur?',
    'user.createNewUser': 'Créer un nouvel utilisateur',
    'user.editUser': "Modifier l'utilisateur",
    'user.email': 'Courriel',
    'user.createdAt': 'Date de création',
    'user.lastLogin': 'Dernière connexion',
    'user.accessGroups': "Groupes d'accès",
    'userPreferences.emailNotificationsQuestion': 'Recevoir les notifications par courriel',
    'userPreferences.SMSNotificationsQuestion': 'Recevoir les notifications par messagerie texte',
  },
};
