<template>
  <v-container>
    <ConfirmationModal
      :question="$t('confirmDeleteDialogue')"
      :title="$t('delete')"
      :show="showDelete"
      @cancel="showDelete = false"
      @ok="deleteSelectedDialogue"
    />

    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row>
      <v-col>
        <div class="page-title">
          {{ $t('dialogues') }}
        </div>
      </v-col>
    </v-row>

    <v-row v-if="$can(permissions.actions.add, permissions.subjects.dialogue)">
      <v-col>
        <v-btn ref="addDialogue" color="primary" @click="editDialogueAction(null)">
          {{ $t('add') }}
        </v-btn>
      </v-col>
    </v-row>

    <WaitModal :show="showWaitModal" />

    <v-row>
      <v-col>
        <DataGrid
          :items="dialogues"
          :columns="columns"
          grid-name="dialogues_grid"
          :show-select="false"
          :items-per-page="25"
        >
          <template #[`item.active`]="{ item }">
            <v-simple-checkbox v-model="item.active" disabled />
          </template>
          <template #[`item.normative`]="{ item }">
            <v-simple-checkbox v-model="item.normative" disabled />
          </template>

          <template #item.actions="{ item }">
            <v-menu v-if="!item.normative || $can(permissions.actions.manage, permissions.subjects.normative)">
              <template #activator="{ on }">
                <v-btn :ref="`actions_${item.id}`" icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="$can(permissions.actions.change, permissions.subjects.dialogue)"
                  :ref="`editDialogue_${item.id}`"
                  @click="editDialogueAction(item.id)"
                >
                  <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                </v-list-item>
                <v-divider
                  v-if="
                    $can(permissions.actions.change, permissions.subjects.dialogue) &&
                    $can(permissions.actions.delete, permissions.subjects.dialogue)
                  "
                ></v-divider>

                <v-list-item
                  v-if="$can(permissions.actions.delete, permissions.subjects.dialogue)"
                  :ref="`deleteDialogue_${item.id}`"
                  @click="deleteAction(item.id)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </DataGrid>
      </v-col>
    </v-row>

    <DialogueModal :show.sync="showEditModal" :dialogue-id="selectedDialogueId" @refresh="init()" />
  </v-container>
</template>

<script>
import DataGrid from '@/components/DataGrid.vue';
import DialogueModal from './DialogueModal';
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import DialogueService from '@/services/dialogueService';
import permissionMixin from '@/auth/permissionMixin';

export default {
  name: 'DialogueGrid',
  components: { DataGrid, DialogueModal },
  mixins: [translationMixin, accessibility, permissionMixin],
  props: {},
  data() {
    return {
      showDelete: false,
      showEditModal: false,
      error: null,
      selectedDialogueId: null,
      showWaitModal: false,

      dialogues: [],
    };
  },
  computed: {
    columns() {
      let columns = [
        {
          text: 'name',
          value: 'name',
          filterable: true,
        },
        {
          text: 'description',
          value: 'description',
          filterable: true,
        },
        {
          text: 'active',
          value: 'active',
          filterable: true,
        },
        {
          text: 'normative',
          value: 'normative',
          filterable: true,
        },
        {
          text: 'createdDate',
          value: 'createdAt',
          filterable: true,
          visible: false,
        },
        {
          text: 'createdBy',
          value: 'createdBy',
          filterable: true,
          visible: false,
        },
        {
          text: 'modifiedDate',
          value: 'updatedAt',
          filterable: true,
          visible: false,
        },
        {
          text: 'modifiedBy',
          value: 'updatedBy',
          filterable: true,
          visible: false,
        },
      ];
      if (
        this.$can(this.permissions.actions.change, this.permissions.subjects.dialogue) ||
        this.$can(this.permissions.actions.delete, this.permissions.subjects.dialogue)
      ) {
        columns.push({
          text: 'Actions',
          value: 'actions',
          filterable: false,
          sortable: false,
        });
      }
      return columns;
    },
  },
  created: function () {
    this.init();
  },

  methods: {
    async init() {
      this.dialogues = [];
      this.showWaitModal = true;
      this.showDelete = false;
      this.showEditModal = false;
      try {
        let dialogues = await DialogueService.getDialogues();
        this.dialogues = dialogues.map((item) => {
          let result = {
            id: item.id,
            name: item.name,
            description: item.description,
            active: item.active,
            normative: item.normative,
            createdAt: item.createdAt,
            createdBy: item.createdBy != null ? item.createdBy.username : '',
            updatedAt: item.updatedAt,
            updatedBy: item.updatedBy != null ? item.updatedBy.username : '',
          };
          return result;
        });
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    async deleteSelectedDialogue() {
      this.showDelete = false;
      this.showWaitModal = true;
      try {
        await DialogueService.deleteDialogue(this.selectedDialogueId);
        this.init();
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    editDialogueAction: function (id) {
      this.selectedDialogueId = id;
      this.showEditModal = true;
    },

    deleteAction: function (id) {
      this.selectedDialogueId = id;
      this.showDelete = true;
    },
  },
};
</script>
