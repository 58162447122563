<template>
  <div>
    <v-row v-if="$vuetify.breakpoint.smAndUp">
      <v-col>
        <v-btn ref="closeMonitoringDetails" color="primary" :to="{ name: 'PatientMonitoringGrid' }" exact>
          <v-icon>mdi-arrow-left</v-icon>
          <span>{{ $t('backPrecedent') }}</span>
        </v-btn>
      </v-col>
    </v-row>

    <v-row v-if="patientInfo" align="center">
      <v-col cols="auto">
        <div>
          <span :class="$vuetify.breakpoint.smAndUp ? ['text-h6'] : []"
            >{{ patientInfo.lastName }}, {{ patientInfo.firstName }}</span
          >
          <detail-field
            v-if="patientInfo.roombed"
            class="mt-0"
            :value="patientInfo.roombed"
            readonly
            :label="$t('roombed')"
          />
        </div>
      </v-col>
      <v-col v-if="$vuetify.breakpoint.xs">
        <v-btn icon @click="showPatientSheet = true"><v-icon>mdi-information</v-icon></v-btn>
      </v-col>
    </v-row>

    <v-row v-if="$vuetify.breakpoint.xs">
      <v-col>
        <v-select
          dense
          outlined
          :items="availableTabs"
          item-text="name"
          :value="availableTabs[selectedTab]"
          return-object
          hide-details
          @change="selectedTab = availableTabs.indexOf($event)"
        ></v-select>
      </v-col>
      <v-col v-if="!availableTabs[selectedTab].isPatient && !availableTabs[selectedTab].isMessages" cols="auto">
        <v-btn icon @click="showFilterSheet = true"><v-icon>mdi-filter</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-tabs v-model="selectedTab" background-color="transparent" show-arrows>
          <v-tab v-for="(tab, index) in availableTabs" :key="index">{{ tab.name }}</v-tab>
        </v-tabs>
      </v-col>
    </v-row>

    <PatientMonitoringFilter
      v-if="
        $vuetify.breakpoint.smAndUp && !availableTabs[selectedTab].isPatient && !availableTabs[selectedTab].isMessages
      "
      class="mt-1"
      :initial-values="filters"
      :use-monitoring-status="false"
      @filter="onMonitoringFilter"
    />

    <v-navigation-drawer v-model="showFilterSheet" fixed temporary right touchless>
      <v-card flat>
        <v-card-title>{{ $t('filters') }}</v-card-title>
        <v-card-text>
          <PatientMonitoringFilter
            :initial-values="filters"
            :use-monitoring-status="false"
            @filter="onMonitoringFilter"
          />
        </v-card-text>
      </v-card>
    </v-navigation-drawer>

    <v-bottom-sheet v-model="showPatientSheet" scrollable>
      <v-card max-height="60vh">
        <v-card-title>{{ $t('patient') }}</v-card-title>
        <v-card-text>
          <PatientDetails :patient-id="patientId" />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>

    <v-row>
      <v-col>
        <v-tabs-items v-model="selectedTab" class="monitoring-tabs" touchless>
          <v-tab-item v-for="(tab, index) in availableTabs" :key="index" :value="index" :transition="false">
            <template v-if="tab.isPatient">
              <v-card>
                <v-card-text>
                  <PatientDetails ref="patientDetails" :patient-id="patientId" />
                </v-card-text>
              </v-card>
            </template>

            <PatientFallDetection
              v-if="tab.activityTypeCode === monitoringTypeCodes.FDE"
              ref="patientFallDetection"
              :patient-id="patientId"
              :filters="innerFilters"
            />

            <PatientAnamnese
              v-if="tab.activityTypeCode === monitoringTypeCodes.DLG"
              :ref="`patientAnamnese_${tab.monitoringTypeId}`"
              :patient-id="patientId"
              :monitoring-type-id="tab.monitoringTypeId"
              :filters="innerFilters"
            />

            <PatientBloodPressure
              v-if="tab.activityTypeCode === monitoringTypeCodes.APR"
              ref="patientBloodPressure"
              :patient-id="patientId"
              :filters="innerFilters"
            />

            <PatientSaturation
              v-if="tab.activityTypeCode === monitoringTypeCodes.SAT"
              ref="patientSaturation"
              :patient-id="patientId"
              :filters="innerFilters"
            />

            <PatientHeartRate
              v-if="tab.activityTypeCode === monitoringTypeCodes.CFR"
              ref="patientHeartRate"
              :patient-id="patientId"
              :filters="innerFilters"
            />

            <PatientBodyTemperature
              v-if="tab.activityTypeCode === monitoringTypeCodes.BDT"
              ref="patientBodyTemperature"
              :patient-id="patientId"
              :filters="innerFilters"
            />

            <PatientRespiratoryRate
              v-if="tab.activityTypeCode === monitoringTypeCodes.RES"
              ref="patientRespiratoryRate"
              :patient-id="patientId"
              :filters="innerFilters"
            />

            <PatientPrescription
              v-if="tab.activityTypeCode === monitoringTypeCodes.RXA"
              ref="patientPrescription"
              :patient-id="patientId"
              :filters="innerFilters"
            />

            <PatientWeight
              v-if="tab.activityTypeCode === monitoringTypeCodes.WEI"
              ref="patientWeight"
              :patient-id="patientId"
              :filters="innerFilters"
            />

            <ResidentMessageGrid v-if="tab.isMessages" ref="patientMessages" :patient-id="patientId" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import PatientDetails from './PatientDetails.vue';
import PatientHeartRate from './PatientHeartRate.vue';
import PatientSaturation from './PatientSaturation.vue';
import PatientBloodPressure from './PatientBloodPressure.vue';
import PatientBodyTemperature from './PatientBodyTemperature.vue';
import PatientRespiratoryRate from './PatientRespiratoryRate.vue';
import PatientPrescription from './PatientPrescription.vue';
import PatientWeight from './PatientWeight.vue';
import ResidentMessageGrid from './ResidentMessageGrid.vue';
import PatientAnamnese from './PatientAnamnese.vue';
import PatientFallDetection from './PatientFallDetection.vue';
import PatientMonitoringFilter from './PatientMonitoringFilter.vue';
import { ActivityTypes } from './constants';
export default {
  name: 'MonitoringDetails',
  components: {
    PatientDetails,
    PatientHeartRate,
    PatientSaturation,
    PatientBloodPressure,
    PatientBodyTemperature,
    PatientRespiratoryRate,
    PatientPrescription,
    PatientWeight,
    ResidentMessageGrid,
    PatientAnamnese,
    PatientFallDetection,
    PatientMonitoringFilter,
  },
  mixins: [translation],
  props: {
    patientId: {
      type: Number,
      default: null,
    },
    detailType: {
      type: [String, Number],
      required: false,
      default: undefined,
    },
    filters: {
      type: Object,
      required: true,
    },
    patientInfo: {
      type: Object,
      required: false,
      default: () => {},
    },
    monitoringTypes: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTab: undefined,
      innerFilters: null,
      monitoringTypeCodes: ActivityTypes,
      monitoringTypeCodeFDE: ActivityTypes.FDE,

      showFilterSheet: false,
      showPatientSheet: false,
    };
  },
  computed: {
    availableTabs() {
      let allowedActivityTypes = [
        ActivityTypes.FDE,
        ActivityTypes.DLG,
        ActivityTypes.CFR,
        ActivityTypes.SAT,
        ActivityTypes.APR,
        ActivityTypes.BDT,
        ActivityTypes.RES,
        ActivityTypes.RXA,
        ActivityTypes.WEI,
      ];
      let monitoringTabs = this.monitoringTypes
        .filter((x) => allowedActivityTypes.includes(x.activityTypeCode))
        .map((x) => {
          return {
            name: x.name,
            activityTypeCode: x.activityTypeCode,
            monitoringTypeId: x.id,
            url: x.id,
          };
        });

      let tabs = [
        {
          name: this.$t('patient'),
          isPatient: true,
        },
        ...monitoringTabs,
        {
          name: this.$t('messages'),
          isMessages: true,
          url: 'messages',
        },
      ];

      tabs.forEach((t, i) => {
        t.index = i;
      });

      return tabs;
    },
  },
  watch: {
    detailType: {
      immediate: true,
      handler() {
        this.selectedTab = this.detailTypeToTabIndex(this.detailType);
      },
    },
    monitoringTypes: {
      immediate: true,
      handler() {
        this.selectedTab = this.detailTypeToTabIndex(this.detailType);
      },
    },
    selectedTab() {
      let newDetailType = this.availableTabs[this.selectedTab]?.url;
      if (newDetailType !== this.detailType) {
        this.$emit('update:detail-type', newDetailType);
      }
    },
    filters: {
      immediate: true,
      handler() {
        this.onMonitoringFilter(this.filters);
      },
    },
    '$vuetify.breakpoint.xs': function (newValue) {
      if (!newValue) {
        this.showFilterSheet = false;
        this.showPatientSheet = false;
      }
    },
  },

  methods: {
    onMonitoringFilter: function (filter) {
      this.innerFilters = filter;
      this.showFilterSheet = false;
    },
    detailTypeToTabIndex: function (detailType) {
      let patientTab = this.availableTabs.find((x) => x.isPatient);

      if (!detailType) {
        return patientTab.index;
      }

      if (typeof detailType === 'string') {
        detailType = detailType.toLowerCase();
      }

      let tab = this.availableTabs.find((x) => x.url == detailType);

      return tab ? tab.index : patientTab.index;
    },
  },
};
</script>

<style scoped>
.monitoring-tabs.v-tabs-items {
  background: transparent;
  overflow: visible;
}
</style>
