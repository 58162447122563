<template>
  <v-container>
    <ConfirmationModal
      :question="`${$t('confirmDeleteActivity')}?`"
      :show="showDelete"
      :title="$t('delete')"
      @cancel="showDelete = false"
      @ok="deleteSelectedActivity"
    />

    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row>
      <v-col>
        <div class="page-title">
          {{ $t('activities') }}
        </div>
      </v-col>
    </v-row>

    <v-row v-if="$can(permissions.actions.add, permissions.subjects.activity)">
      <v-col>
        <v-btn ref="addActivity" color="primary" @click="editActivityAction(null)">
          {{ $t('add') }}
        </v-btn>
      </v-col>
    </v-row>

    <WaitModal :show="showWaitModal" />

    <v-row>
      <v-col>
        <DataGrid
          :items="activities"
          :columns="columns"
          grid-name="activities_grid"
          :show-select="false"
          :items-per-page="25"
        >
          <template #[`item.normative`]="{ item }">
            <v-simple-checkbox v-model="item.normative" disabled />
          </template>

          <template #item.actions="{ item }">
            <v-menu v-if="!item.normative || $can(permissions.actions.manage, permissions.subjects.normative)">
              <template #activator="{ on }">
                <v-btn :ref="`actions_${item.id}`" icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="$can(permissions.actions.change, permissions.subjects.activity)"
                  :ref="`editActivity_${item.id}`"
                  @click="editActivityAction(item.id)"
                >
                  <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                </v-list-item>
                <v-divider
                  v-if="
                    $can(permissions.actions.change, permissions.subjects.activity) &&
                    $can(permissions.actions.delete, permissions.subjects.activity)
                  "
                />
                <v-list-item
                  v-if="$can(permissions.actions.delete, permissions.subjects.activity)"
                  :ref="`deleteActivity_${item.id}`"
                  @click="deleteAction(item.id)"
                >
                  <v-list-item-icon><v-icon>mdi-delete</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </DataGrid>
      </v-col>
    </v-row>

    <ActivityModal :activity-id="selectedActivityId" :show.sync="showEditModal" @refresh="init()" />
  </v-container>
</template>

<script>
import DataGrid from '@/components/DataGrid.vue';
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import ActivityModal from './ActivityModal.vue';
import activityService from '@/services/activityService';
import permissionMixin from '@/auth/permissionMixin';

export default {
  name: 'ActivityGrid',
  components: {
    DataGrid,
    ActivityModal,
  },
  mixins: [translationMixin, accessibility, permissionMixin],

  props: {},

  data() {
    return {
      error: null,
      showWaitModal: false,
      activities: [],
      selectedActivityId: null,
      showEditModal: false,
      activityResidentId: null,
      showDelete: false,
    };
  },
  computed: {
    columns() {
      let columns = [
        {
          text: 'activityName',
          value: 'name',
          filterable: true,
        },
        {
          text: 'description',
          value: 'description',
          filterable: true,
        },
        {
          text: 'monitoringType',
          value: 'monitoringTypeName',
          filterable: true,
        },
        {
          text: 'iotDeviceType',
          value: 'iotDeviceTypeName',
          filterable: true,
        },
        {
          text: 'createdDate',
          value: 'created_at',
          filterable: true,
          visible: false,
        },
        {
          text: 'createdBy',
          value: 'createdBy',
          filterable: true,
          visible: false,
        },
        {
          text: 'modifiedDate',
          value: 'updated_at',
          filterable: true,
          visible: false,
        },
        {
          text: 'modifiedBy',
          value: 'updatedBy',
          filterable: true,
          visible: false,
        },
        {
          text: 'normative',
          value: 'normative',
          filterable: true,
        },
      ];
      if (
        this.$can(this.permissions.actions.change, this.permissions.subjects.activity) ||
        this.$can(this.permissions.actions.delete, this.permissions.subjects.activity)
      ) {
        columns.push({
          text: 'Actions',
          value: 'actions',
          filterable: false,
          sortable: false,
        });
      }
      return columns;
    },
  },
  created: function () {
    this.init();
  },
  methods: {
    init: function () {
      this.showEditModal = false;
      this.getActivityActions();
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
      this.showWaitModal = false;
    },

    async getActivityActions() {
      this.showWaitModal = true;

      try {
        let activities = await activityService.getActivities();
        this.activities = activities.map((item) => {
          return {
            id: item.id,
            name: item.name,
            description: item.description,
            monitoringTypeName: item.monitoringType.name,
            iotDeviceTypeName: item.iotDeviceType.name,
            created_at: item.created_at,
            createdBy: item.created_by ? item.created_by.username : '',
            updated_at: item.updated_at,
            updatedBy: item.updated_by ? item.updated_by.username : '',
            normative: item.normative,
            active: item.active,
            continous: item.continous,
          };
        });
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    async deleteSelectedActivity() {
      this.showDelete = false;
      this.showWaitModal = true;

      try {
        await activityService.deleteActivity(this.selectedActivityId);
        this.init();
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    setResidentActivityId: function (id) {
      this.activityResidentId = id;
    },

    editActivityAction: function (activityId) {
      this.selectedActivityId = activityId;
      this.showEditModal = true;
    },

    deleteAction: function (id) {
      this.selectedActivityId = id;
      this.showDelete = true;
    },
  },
};
</script>
