<template>
  <v-container fluid>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row align="center">
      <v-col v-if="patientId !== undefined && $vuetify.breakpoint.xs" cols="auto">
        <v-btn exact icon :to="{ name: 'PatientMonitoringGrid' }"><v-icon>mdi-chevron-left</v-icon></v-btn>
      </v-col>
      <v-col>
        <PageTitle :title="$t('patientMonitoring')" />
      </v-col>
    </v-row>

    <WaitModal :show="showWaitModal" />
    <MonitoringDetails
      v-if="patientId !== undefined"
      ref="monitoringDetails"
      :patient-id="patientId"
      :detail-type="detailType"
      :filters="filters"
      :patient-info="selectedPatientInfo"
      :monitoring-types="monitoringTypes"
      @update:detail-type="detailTypeUpdated"
    />
    <div v-show="!patientId && $vuetify.breakpoint.smAndUp" class="mt-5">
      <v-row>
        <v-col>
          <PatientMonitoringFilter :show-incontinence="showIncontinence" @filter="onMonitoringFilter" />
        </v-col>
      </v-row>

      <DataGrid
        ref="dataGrid"
        class="monitoring-grid"
        :items="patientResults"
        :columns="columns"
        :show-select="false"
        grid-name="patient_monitoring_grid"
        :items-per-page="10"
        @row:click="(item) => viewPatientMonitoringDetail(item.id)"
        @configuration-changed="dataGridConfigurationChanged"
      >
        <template #item.active="{ item }">
          <v-icon ref="activeIcon">{{ item.active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank' }}</v-icon>
        </template>

        <template v-for="monitoringType in monitoringTypes" #[`item.monitoring._${monitoringType.id}`]="{ item }">
          <td
            :ref="`monitoringTypeTd_${monitoringType.id}`"
            :key="`monitoringTypeTd_${monitoringType.id}`"
            class="text-center"
            @click.stop="() => itemClicked({ itemId: item.id, monitoringTypeId: monitoringType.id })"
          >
            <PatientMonitoringIcon
              :ref="`patientMonitoringIcon_${monitoringType.id}`"
              :item-type="monitoringType"
              :item="item"
            />
          </td>
        </template>

        <template #item.newMessageCount="{ item }">
          <td ref="viewMessage" class="text-center" @click.stop="viewPatientMonitoringDetail(item.id, 'messages')">
            <v-badge
              ref="badge"
              :value="item.newMessageCount != 0"
              overlap
              color="primary"
              top
              offset-x="6"
              offset-y="12"
              bordered
            >
              <span ref="messageCount" slot="badge">{{ item.newMessageCount }}</span>
              <v-icon ref="chatIcon" color="#55b1ff">mdi-chat</v-icon>
            </v-badge>
          </td>
        </template>
      </DataGrid>
      <StatusLevelLegend :show-incontinence="showIncontinence" />
    </div>

    <div v-if="!patientId && $vuetify.breakpoint.xs">
      <v-row dense class="my-2">
        <v-col cols="auto">
          <v-btn icon @click="showLegendSheet = !showLegendSheet"><v-icon>mdi-information</v-icon></v-btn>
        </v-col>
        <v-col>
          <v-text-field
            v-model="mobileGridSearchValue"
            dense
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
            clearable
            outlined
          >
          </v-text-field>
        </v-col>

        <v-col cols="auto">
          <v-btn icon @click="showFilterSheet = !showFilterSheet"><v-icon>mdi-filter</v-icon></v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-expansion-panels multiple>
            <v-expansion-panel v-for="item in filteredPatientResults" :key="item.id">
              <v-expansion-panel-header>
                <template #default>
                  <v-row align="center">
                    <v-col cols="auto">
                      <PatientMonitoringIcon :item-type="getPatientWorstMonitoringType(item)" :item="item" no-badge />
                    </v-col>
                    <v-col class="d-flex flex-column">
                      <span>{{ `${item.lastName}, ${item.firstName}` }}</span>
                      <span v-if="item.roombed" class="mt-2 text--secondary text-body-2">{{
                        `${$t('roombed')}: ${item.roombed}`
                      }}</span>
                    </v-col>
                    <v-col cols="auto">
                      <div v-if="item.newMessageCount" class="pr-7">
                        <v-badge
                          ref="badge"
                          :value="item.newMessageCount != 0"
                          overlap
                          color="primary"
                          bordered
                          :content="item.newMessageCount"
                        >
                          <v-btn icon @click.stop="viewPatientMonitoringDetail(item.id, 'messages')"
                            ><v-icon ref="chatIcon" color="#55b1ff">mdi-chat</v-icon></v-btn
                          >
                        </v-badge>
                      </div>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col class="patient-monitoring-details">
                    <div
                      v-for="monitoringType in monitoringTypes"
                      :key="monitoringType.id"
                      v-ripple
                      class="patient-monitoring-details__item ml-11 pa-1"
                      @click.stop="() => itemClicked({ itemId: item.id, monitoringTypeId: monitoringType.id })"
                    >
                      <div>
                        <PatientMonitoringIcon :item-type="monitoringType" :item="item" />
                      </div>
                      <div class="text--secondary">{{ monitoringType.name }}</div>
                    </div>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div v-if="!filteredPatientResults.length" class="text--secondary text-center">
            {{ $t('noDataAvailable') }}
          </div>
        </v-col>
      </v-row>
    </div>

    <v-bottom-sheet v-model="showLegendSheet">
      <v-card>
        <v-card-title>{{ $t('legend') }}</v-card-title>
        <v-card-text>
          <StatusLevelLegend :show-incontinence="showIncontinence" />
        </v-card-text>
      </v-card>
    </v-bottom-sheet>

    <v-navigation-drawer v-model="showFilterSheet" fixed temporary right touchless>
      <v-card flat>
        <v-card-title>{{ $t('filters') }}</v-card-title>
        <v-card-text>
          <PatientMonitoringFilter :show-incontinence="showIncontinence" @filter="onMonitoringFilter" />
        </v-card-text>
      </v-card>
    </v-navigation-drawer>
  </v-container>
</template>
<script>
import virtuoseMixin from '@/virtuoseMixin';
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import DataGrid from '@/components/DataGrid';
import PatientMonitoringIcon from './PatientMonitoringIcon';
import PatientMonitoringFilter from './PatientMonitoringFilter';
import MonitoringDetails from './MonitoringDetails.vue';
import StatusLevelLegend from './StatusLevelLegend';
import { containsString } from '@/utils/stringUtils';
import { ActivityTypes } from './constants';

export default {
  name: 'PatientMonitoringGrid',
  components: {
    DataGrid,
    PatientMonitoringIcon,
    MonitoringDetails,
    PatientMonitoringFilter,
    StatusLevelLegend,
  },
  mixins: [translationMixin, virtuoseMixin, accessibility],
  props: {
    patientId: {
      type: Number,
      default: undefined,
    },
    detailType: {
      type: [String, Number],
      default: undefined,
    },
  },
  data() {
    return {
      error: null,
      showWaitModal: false,
      patientResults: null,
      monitoringTypes: [],
      statusLevels: [],
      monitoringTypeDefinitions: [],
      filters: null,
      showIncontinence: true,
      mobileGridSearchValue: '',
      showLegendSheet: false,
      showFilterSheet: false,
    };
  },
  computed: {
    columns: function () {
      let result = [
        {
          text: 'lastName',
          value: 'lastName',
          filterable: true,
        },
        {
          text: 'firstName',
          value: 'firstName',
          filterable: true,
        },
        {
          text: 'roombed',
          value: 'roombed',
          filterable: true,
        },
        {
          text: 'nasm',
          value: 'nasm',
          filterable: true,
          visible: false,
        },
        {
          text: 'birthDate',
          value: 'birthDate',
          filterable: true,
          visible: false,
        },
        {
          text: 'facility',
          value: 'facility',
          filterable: true,
          visible: false,
        },
        {
          text: 'organization',
          value: 'organization',
          filterable: true,
          visible: false,
        },
        {
          text: 'admitDate',
          value: 'start_date',
          filterable: true,
          visible: false,
        },
        {
          text: 'active',
          value: 'active',
          filterable: true,
          visible: false,
        },
        {
          text: 'address',
          value: 'address',
          filterable: true,
          visible: false,
        },
        {
          text: 'city',
          value: 'city',
          filterable: true,
          visible: false,
        },
        {
          text: 'province',
          value: 'province',
          filterable: true,
          visible: false,
        },
        {
          text: 'country',
          value: 'country',
          filterable: true,
          visible: false,
        },
        {
          text: 'postalCode',
          value: 'postal_code',
          filterable: true,
          visible: false,
        },
        {
          text: 'phone',
          value: 'phone',
          filterable: true,
          visible: false,
        },
        {
          text: 'email',
          value: 'email',
          filterable: true,
          visible: false,
        },
        {
          text: 'patientType',
          value: 'patient_type',
          filterable: true,
          visible: false,
        },
        {
          text: 'createdBy',
          value: 'created_by',
          filterable: true,
          visible: false,
        },
        {
          text: 'createdDate',
          value: 'created_at',
          filterable: true,
          visible: false,
        },
        {
          text: 'modifiedDate',
          value: 'updated_at',
          filterable: true,
          visible: false,
        },
        {
          text: 'modifiedBy',
          value: 'updated_by',
          filterable: true,
          visible: false,
        },
        {
          text: 'id',
          value: 'id',
          filterable: true,
          visible: false,
        },
      ];

      result = result.concat(this.monitoringTypeDefinitions);
      result.push({
        text: 'messages',
        value: 'newMessageCount',
        filterable: false,
        visible: true,
        sortable: false,
        align: 'center',
      });

      return result;
    },
    selectedPatientInfo() {
      let patient = this.patientResults?.find((x) => x.id === this.patientId);
      return patient
        ? {
            firstName: patient.firstName,
            lastName: patient.lastName,
            roombed: patient.roombed,
          }
        : null;
    },
    filteredPatientResults() {
      if (!this.monitoringTypes.length) {
        return [];
      }

      if (!this.mobileGridSearchValue) {
        return this.patientResults;
      }
      return this.patientResults.filter((x) => {
        let name = `${x.lastName}, ${x.firstName}`;
        return (
          containsString(name, this.mobileGridSearchValue) ||
          (x.roombed && containsString(x.roombed, this.mobileGridSearchValue))
        );
      });
    },
  },
  watch: {
    '$vuetify.breakpoint.xs': function (newValue) {
      if (!newValue) {
        this.showFilterSheet = false;
        this.showLegendSheet = false;
      }
    },
  },
  created: function () {
    this.filters = {
      timeFilterType: null,
      timeFilterValue: null,
      statusLevelIds: null,
      cohortPatients: null,
    };

    this.init();
  },
  methods: {
    init: function () {
      this.getPatientsMonitoringSummary();
    },

    onMonitoringFilter: function (filters) {
      this.showFilterSheet = false;
      this.filters = filters;
      this.init();
    },

    getPatientsMonitoringSummary: function () {
      this.monitoringTypes = [];

      const success = function (response) {
        this.showWaitModal = false;

        let rawPatients = response.data.patientsResults;

        this.monitoringTypes = response.data.monitoringTypes
          .sort((a, b) => a.activityType.order - b.activityType.order || a.name.localeCompare(b.name))
          .map((mt) => {
            return {
              id: mt.id,
              name: mt.name,
              activityTypeCode: mt.activityType.code,
            };
          });

        this.statusLevels = response.data.statusLevels;

        const monitoringTypesMap = Object.fromEntries(
          this.monitoringTypes.map(({ id, activityTypeCode }) => [id, activityTypeCode])
        );

        const statusLevelMap = Object.fromEntries(this.statusLevels.map(({ id, code }) => [id, code]));

        this.monitoringTypeDefinitions = this.monitoringTypes.map((x) => {
          return {
            text: x.name,
            value: `monitoring._${x.id}`,
            filterable: false,
            visible: true,
            sortable: false,
            align: 'center',
          };
        });

        this.patientResults = rawPatients
          .filter((r) => r.result.length != 0)
          .map((r) => {
            var patient = Object.assign({}, r.patient);
            patient.country = r.patient.country ? r.patient.country.name : '';
            patient.created_by = r.patient.created_by ? r.patient.created_by.username : '';
            patient.gender = r.patient.gender ? r.patient.gender.name : '';
            patient.patient_type = r.patient.patient_type ? r.patient.patient_type.name : '';
            patient.province = r.patient.province ? r.patient.province.name : '';
            patient.updated_by = r.patient.updated_by ? r.patient.updated_by.username : '';
            patient.roombed = r.patient.roombed ? r.patient.roombed.room + ' - ' + r.patient.roombed.bed : '';
            patient.facility = r.patient.facility ? r.patient.facility.name : '';
            patient.organization = r.patient.organization ? r.patient.organization.name : '';
            patient.newMessageCount = r.patient.messagesIds.length;
            patient.monitoring = {};

            for (let i = 0; i < r.result.length; i++) {
              var keyId = r.result[i].monitoringTypeId;
              var keyName = `_${keyId}`;
              var valueId = r.result[i].statusLevelId;
              var valueCode = statusLevelMap[valueId];

              patient.monitoring[keyName] = {
                valueCode: valueCode,
                alertCount: r.result[i].alertCount,
                monitoringTypeId: keyId,
                activityTypeCode: monitoringTypesMap[keyId],
              };
            }
            return patient;
          });
      };

      this.getPatientMonitoringSummary(this.filters, success, this.defaultErrorCallBack);
    },

    itemClicked: function ({ itemId, monitoringTypeId }) {
      this.viewPatientMonitoringDetail(itemId, monitoringTypeId);
    },

    viewPatientMonitoringDetail: function (patientId, detailType) {
      this.$router.push({ name: 'PatientMonitoringGrid', params: { patientId: patientId, detailType: detailType } });
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
      this.showWaitModal = false;
    },

    detailTypeUpdated(detailType) {
      this.$router.push({
        name: 'PatientMonitoringGrid',
        params: { patientId: this.patientId, detailType: detailType },
      });
    },

    dataGridConfigurationChanged(config) {
      let incontinence = this.monitoringTypes.find((x) => x.activityTypeCode === ActivityTypes.INC);
      if (!incontinence) {
        return;
      }
      this.showIncontinence = config.visibility.some((x) => x.key === `monitoring._${incontinence.id}` && x.visible);
    },

    getPatientWorstMonitoringType(patientMonitoringItem) {
      if (this.monitoringTypes.length === 0) {
        return {};
      }

      let orderedStatusLevels = [...this.statusLevels].sort((a, b) => a.priority - b.priority);

      for (let iStatus = 0; iStatus < orderedStatusLevels.length; iStatus++) {
        const statusPriority = orderedStatusLevels[iStatus].code;

        for (let index = 0; index < this.monitoringTypes.length; index++) {
          const monitoring = this.monitoringTypes[index];
          if (patientMonitoringItem.monitoring[`_${monitoring.id}`]?.valueCode === statusPriority) {
            return monitoring;
          }
        }
      }
      return this.monitoringTypes[0];
    },
  },
};
</script>
<style scoped>
.monitoring-grid :deep(td) {
  cursor: pointer;
}

.patient-monitoring-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 8px;
}
.patient-monitoring-details__item {
  display: flex;
  align-items: center;
  flex-direction: row;
  column-gap: 24px;
  cursor: pointer;
  user-select: none;
  border-radius: 4px;
}
</style>
