<template>
  <v-container>
    <ResidentGrid />
  </v-container>
</template>

<script>
import translationMixin from '@/translationMixin';
import ResidentGrid from './ResidentGrid';

export default {
  name: 'ResidentHome',
  components: {
    ResidentGrid,
  },
  mixins: [translationMixin],
  props: {},
  data() {
    return {};
  },
  created: function () {
    this.init();
  },
  methods: {
    init: function () {},
  },
};
</script>

<style scoped></style>
