<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-data-table
      :items="items"
      :headers="headers"
      :footer-props="footerProps"
      :items-per-page="25"
      show-expand
      item-key="date"
      class="elevation-1 mt-4"
      mobile-breakpoint="0"
    >
      <template #[`item.statusLevel`]="{ item }">
        <div>
          <v-icon :color="getIconColor(item.statusLevel)">
            {{ getIconName(item.statusLevel) }}
          </v-icon>
        </div>
      </template>

      <template #expanded-item="{ item }">
        <td :colspan="headers.length + 1">
          <div v-for="(prescription, prescriptionIndex) in item.results" :key="prescriptionIndex" class="my-2">
            <v-alert
              :key="prescriptionIndex"
              :text="prescription.statusLevelCode !== 'NO'"
              :color="getAnamAlertColor(prescription.statusLevelCode)"
              dense
              :icon="getPrescriptionAlertIcon(prescription.statusLevelCode, prescription.allTaken)"
              class="mb-0"
            >
              <div class="d-flex flex-column align-start">
                <span class="ml-1 text-body-2 font-weight-bold text--secondary">{{ prescription.time }}</span>
                <v-simple-table dense class="text-body-2 text--secondary">
                  <template #default>
                    <tbody>
                      <tr v-for="(child, index) in prescription.details" :key="index">
                        <td class="px-1">{{ child.drug_name }}</td>
                        <td class="px-1 text-right">{{ child.strength }}</td>
                        <td class="px-1">
                          <v-tooltip right class="p-6">
                            <template #activator="{ on, attrs }">
                              <v-icon ref="adherenceDetails" small icon v-bind="attrs" v-on="on">
                                mdi-information-outline
                              </v-icon>
                            </template>

                            <v-row align="center">
                              <v-col class="py-4">
                                {{
                                  $t('takenMedication') +
                                  ': ' +
                                  (child.taken !== null ? $t(child.taken) : $t('noInformation'))
                                }}
                                <div v-if="child.status_reason">
                                  {{ $t('reason') + ': ' + child.status_reason }}
                                </div>
                                <div>{{ $t('dosage') + ': ' + child.dosage }}</div>
                                <div>{{ $t('form') + ': ' + child.form }}</div>
                              </v-col>
                            </v-row>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-alert>
          </div>
        </td>
      </template>
    </v-data-table>
  </span>
</template>

<script>
import patientMonitoringService from '@/services/patientMonitoringService';
import translationMixin from '@/translationMixin';
import iconMixin from './iconMixin';

export default {
  name: 'PatientPrescription',
  mixins: [translationMixin, iconMixin],
  props: {
    patientId: {
      type: Number,
      default: null,
    },

    filters: {
      type: Object,
      default: null,
    },
  },

  data: () => ({
    items: [],
    prescriptionData: [],
    sortBy: 'at',
    sortDesc: true,
    error: null,
  }),

  computed: {
    footerProps: function () {
      return {
        itemsPerPageOptions: [5, 10, 25, 50, -1],
        showFirstLastPage: true,
        'items-per-page-text': this.$t('rowsByPage'),
      };
    },
    headers: function () {
      return [
        {
          text: this.$t('statusLevelId'),
          textalign: 'center',
          value: 'statusLevel',
          align: 'center',
          width: '95px',
          sortable: false,
        },
        {
          text: this.$t('at'),
          value: 'date',
          sortable: false,
          order: 'desc',
        },
      ];
    },
  },

  watch: {
    patientId: function () {
      this.getPatientPrescriptionResults();
    },
    filters: function () {
      this.getPatientPrescriptionResults();
    },
  },

  created: function () {
    this.init();
  },

  methods: {
    init: function () {
      this.getPatientPrescriptionResults();
    },

    async getPatientPrescriptionResults() {
      let patientInfo = {
        patientId: this.patientId,
        timeFilterType: this.filters?.timeFilterType ?? null,
        timeFilterValue: this.filters?.timeFilterValue ?? null,
        dateFrom: this.filters?.dateFrom ?? null,
        dateTo: this.filters?.dateTo ?? null,
      };

      try {
        let patientData = await patientMonitoringService.getPatientPrescription(patientInfo);
        this.items = patientData.results;
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>

<style scoped></style>
