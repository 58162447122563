export default {
  en: {
    monitoringTypes: 'Monitoring Types',
    confirmDeleteMonitoringType: 'Are you sure you want to delete this monitoring type?',
    createMonitoringType: 'Add monitoring type',
    editMonitoringType: 'Modify monitoring type',
    monitoringTypeActivityType: 'Activity Type',
  },
  fr: {
    monitoringTypes: 'Types de surveillances',
    confirmDeleteMonitoringType: 'Êtes-vous certain de vouloir supprimer ce type de surveillance ?',
    createMonitoringType: 'Ajouter un type de surveillance',
    editMonitoringType: 'Modifier le type de surveillance',
    monitoringTypeActivityType: "Type d'activité",
  },
};
