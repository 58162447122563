<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-expansion-panels
      v-if="notifications && notifications.length > 0"
      ref="notifications-panels"
      v-model="panel"
      accordion
      multiple
    >
      <v-expansion-panel
        v-for="(item, index) in notifications"
        ref="notifications-panel"
        :key="index"
        @click.prevent="onClick()"
      >
        <v-expansion-panel-header :class="{ 'pa-2': $vuetify.breakpoint.xs }">
          <v-row :class="{ 'font-weight-black': !item.read }" dense class="mr-2 text-body-2" align="center">
            <v-col cols="auto" :class="{ 'mr-2': !$vuetify.breakpoint.xs }">
              <v-btn icon @click.stop="onClick()">
                <v-icon :color="item.read ? 'grey' : 'primary'" @click="toggleUpdateIsRead(item)"
                  >mdi-circle-medium</v-icon
                >
              </v-btn>
            </v-col>
            <v-col>
              <v-row dense>
                <v-col>
                  <span class="text--secondary">{{ item.data.patientName }}</span>
                </v-col>
                <v-col cols="auto">
                  <span class="text--secondary">{{ getFormattedTime(item.createdAt) }}</span>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col> {{ getNotificationType(item.notificationType) }} - {{ item.data.activityName }} </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-expansion-panel-header>

        <v-expansion-panel-content>
          <v-row dense class="text-body-2 mr-8" :class="$vuetify.breakpoint.xs ? ['ml-6'] : ['ml-12']">
            <v-col>
              <span :class="{ 'font-weight-black': !item.read }"
                >{{ $t('alertNotificationTriggered') }} {{ item.data.activityName }}</span
              >
            </v-col>
          </v-row>
          <v-row dense class="mr-8" :class="$vuetify.breakpoint.xs ? ['ml-6'] : ['ml-12']">
            <v-col>
              <span class="text-body-2 text--secondary wrap-newline">{{ getMonitoringType(item.data) }}</span>
            </v-col>
          </v-row>
          <v-row justify="end">
            <v-col cols="auto">
              <v-btn color="primary" @click="goToNotificationSource(item)">
                {{ $t('openNotification') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-expansion-panels v-else ref="notifications-panels" v-model="panel">
      <v-expansion-panel>
        <v-expansion-panel-header hide-actions>
          <v-row
            ><v-col>{{ $t('noDataAvailable') }}</v-col></v-row
          >
        </v-expansion-panel-header>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import translation, { LanguageVue } from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import notificationService from '@/services/notificationService';
import { NotificationTypes, NotificationRefreshIntervals } from './constants';
import { ActivityTypes } from '@/components/Resident/constants';

export default {
  name: 'NotificationModal',

  components: {},
  mixins: [translation, accessibility],

  props: {
    closeDialog: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      notifications: [],
      panel: [],
      expanded: [],
      userLanguage: LanguageVue.getLanguage(),
    };
  },

  async created() {
    this.init();
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  methods: {
    init: async function () {
      this.expanded = [];
      this.startTimer();
    },

    async refreshNotifications() {
      try {
        this.notifications = await notificationService.getNotifications();
        this.$emit('ui-updated');
      } catch (error) {
        this.error = error;
      }
    },

    async toggleUpdateIsRead(notification) {
      notification.read = !notification.read;

      var data = {
        read: notification.read,
      };

      let unreadCount = this.notifications.filter((notification) => !notification?.read).length;

      try {
        await notificationService.updateNotificationReadStatus(notification.id, data);
      } catch (error) {
        this.error = error;
      }
      this.$emit('read-status-changed', unreadCount);
    },

    getFormattedTime(createdAt) {
      const date = new Date(createdAt);
      const options = {
        hour: 'numeric',
        minute: 'numeric',
      };
      if (this.userLanguage === 'en') {
        options.hour12 = true;
      }
      if (this.isDateToday(createdAt)) {
        return date.toLocaleTimeString(this.userLanguage, options);
      } else {
        const dateString = date.toISOString().slice(0, 10);
        const timeString = date.toLocaleTimeString(this.userLanguage, options);
        return `${dateString} ${timeString}`;
      }
    },

    isDateToday(createdAt) {
      const today = new Date();
      const date = new Date(createdAt);
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    },

    startTimer() {
      this.refreshNotifications();
      this.timer = setInterval(this.refreshNotifications, NotificationRefreshIntervals.DEFAULT);
    },

    getNotificationType(notification) {
      const notificationType = notification;
      switch (notificationType) {
        case NotificationTypes.ACT_ALERT:
          return this.$t('notificationAlertType');
      }
    },

    getMonitoringType(data) {
      const receivedValue = data.value;
      const activityTypeCode = data.activityTypeCode;

      switch (activityTypeCode) {
        case ActivityTypes.FDE:
          return `${this.$t(`notificationMonitoringType_${activityTypeCode}`)}`;
        case ActivityTypes.APR:
          return `${this.$t('notificationMonitoringType_SAPR')}: ${receivedValue['Systolic']}, ${this.$t(
            'notificationMonitoringType_DAPR'
          )}: ${receivedValue['Diastolic']}`;
        case ActivityTypes.RXA:
          return `${this.$t('notificationType_nottaken')}: ${receivedValue['DrugName']}`;
        case ActivityTypes.CFR:
          return `${this.$t('pulse')}: ${receivedValue['Pulse']}`;
        case ActivityTypes.SAT:
          return `${this.$t('saturation')}: ${receivedValue['Saturation']}`;
        case ActivityTypes.BDT:
          return `${this.$t('bodyTemperature')}: ${receivedValue['BodyTemperature']}`;
        case ActivityTypes.WEI:
          return `${this.$t('weight')}: ${receivedValue['Weight']}`;
        case ActivityTypes.RES:
          return `${this.$t('respiratoryRate')}: ${receivedValue['RespiratoryRate']}`;
      }
    },

    onClick() {
      const curr = this.expanded;
      this.expanded = curr === undefined ? 0 : undefined;
      this.$emit('ui-updated');
    },

    async goToNotificationSource(notification) {
      if (notification.read === false) {
        await this.toggleUpdateIsRead(notification);
      }
      let notificationId = notification.id;
      this.$router.push({ name: 'NotificationRedirect', params: { notificationId } });
      this.closeDialog();
    },
  },
};
</script>

<style scoped></style>
