<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent max-width="1200px">
      <v-card>
        <ModalTitle :title="title" />

        <WaitModal :show="showWaitModal" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <v-form ref="patientModalForm" lazy-validation>
            <v-row>
              <v-col cols="auto">
                <v-icon class="mt-4">mdi-account</v-icon>
              </v-col>

              <v-col>
                <v-row dense>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <v-text-field
                      :id="getIdByName('lastName')"
                      ref="lastName"
                      v-model="formData.lastName"
                      :label="$t('lastName')"
                      filled
                      class="required-indicator"
                      :rules="[validationRules.required]"
                    />
                  </v-col>

                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <v-text-field
                      :id="getIdByName('firstName')"
                      ref="firstName"
                      v-model="formData.firstName"
                      :label="$t('firstName')"
                      filled
                      class="required-indicator"
                      :rules="[validationRules.required]"
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <DateTimePickerField
                      ref="birthDate"
                      v-model="formData.birthDate"
                      :label="$t('birthDate')"
                      picker-type="date"
                      required
                    />
                  </v-col>

                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <v-text-field
                      :id="getIdByName('nasm')"
                      ref="nasm"
                      v-model="formData.nasm"
                      :label="$t('nasm')"
                      filled
                      class="required-indicator"
                      :rules="[validationRules.required]"
                    />
                  </v-col>

                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <v-select
                      ref="gender"
                      v-model="formData.gender"
                      :items="genders"
                      :label="$t('gender')"
                      item-text="name"
                      item-value="id"
                      filled
                      :no-data-text="$t('noDataAvailable')"
                      class="required-indicator"
                      :rules="[validationRules.required]"
                    />
                  </v-col>

                  <v-col>
                    <v-select
                      ref="language"
                      v-model="formData.language"
                      :items="languages"
                      :label="$t('language')"
                      item-text="name"
                      item-value="id"
                      filled
                      :no-data-text="$t('noDataAvailable')"
                      class="required-indicator"
                      :rules="[validationRules.required]"
                    />
                  </v-col>
                </v-row>

                <v-row dense class="mt-0">
                  <v-col>
                    <v-checkbox ref="active" v-model="formData.active" :label="$t('active')" class="mt-0" />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="auto">
                <v-icon class="mt-4">mdi-home</v-icon>
              </v-col>

              <v-col>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <v-text-field
                      :id="getIdByName('address')"
                      ref="address"
                      v-model="formData.address"
                      :label="$t('address')"
                      filled
                      class="required-indicator"
                      :rules="[validationRules.required]"
                    />

                    <v-text-field
                      :id="getIdByName('city')"
                      ref="city"
                      v-model="formData.city"
                      :label="$t('city')"
                      filled
                      class="required-indicator"
                      :rules="[validationRules.required]"
                    />

                    <v-row dense>
                      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                        <v-text-field
                          :id="getIdByName('postal_code')"
                          ref="postalCode"
                          v-model="formData.postal_code"
                          :label="$t('postalCode')"
                          filled
                          class="required-indicator"
                          :rules="[validationRules.required]"
                        />
                      </v-col>

                      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                        <v-select
                          ref="country"
                          v-model="formData.countryId"
                          :items="countries"
                          :label="$t('country')"
                          item-text="name"
                          item-value="id"
                          filled
                          :no-data-text="$t('noDataAvailable')"
                          class="required-indicator"
                          :rules="[validationRules.required]"
                          @input="provincesScopeChanged(true)"
                        />
                      </v-col>

                      <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                        <v-select
                          ref="province"
                          v-model="formData.provinceId"
                          :items="provinces"
                          :label="provinceLabel"
                          item-text="name"
                          item-value="id"
                          filled
                          :no-data-text="$t('noDataAvailable')"
                          class="required-indicator"
                          :rules="[validationRules.required]"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="auto">
                <v-icon class="mt-4">mdi-card-account-details</v-icon>
              </v-col>

              <v-col>
                <v-row dense>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <v-text-field
                      :id="getIdByName('phone')"
                      ref="phone"
                      v-model="formData.phone"
                      filled
                      :label="$t('phone')"
                      class="required-indicator"
                      :rules="[validationRules.required]"
                    />
                  </v-col>

                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <v-text-field
                      :id="getIdByName('email')"
                      ref="email"
                      v-model="formData.email"
                      :label="$t('email')"
                      filled
                      :rules="[validationRules.email]"
                      validate-on-blur
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="auto">
                <v-icon class="mt-4">mdi-human-male-male </v-icon>
              </v-col>

              <v-col>
                <v-row dense>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <v-text-field
                      :id="getIdByName('caregiverFirstName')"
                      ref="caregiverFirstName"
                      v-model="formData.caregiverInfo.caregiverFirstName"
                      :label="$t('caregiverFirstName')"
                      filled
                      :class="caregiverRequired && 'required-indicator'"
                      :rules="caregiverRequired ? [validationRules.required] : []"
                    />
                  </v-col>

                  <v-col>
                    <v-text-field
                      :id="getIdByName('caregiverLastName')"
                      ref="caregiverLastName"
                      v-model="formData.caregiverInfo.caregiverLastName"
                      :label="$t('caregiverLastName')"
                      filled
                      :class="caregiverRequired && 'required-indicator'"
                      :rules="caregiverRequired ? [validationRules.required] : []"
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col>
                    <v-combobox
                      :id="getIdByName('caregiverRelation')"
                      ref="caregiverRelation"
                      v-model="formData.caregiverInfo.caregiverRelation"
                      :items="caregiverRelations"
                      filled
                      clearable
                      :no-data-text="$t('noDataAvailable')"
                      :label="$t('caregiverRelation')"
                      :class="caregiverRequired && 'required-indicator'"
                      :rules="caregiverRequired ? [validationRules.required] : []"
                    />
                  </v-col>

                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <v-text-field
                      :id="getIdByName('caregiverPhone')"
                      ref="caregiverPhone"
                      v-model="formData.caregiverInfo.caregiverPhone"
                      :label="$t('phone')"
                      filled
                      :class="caregiverRequired && 'required-indicator'"
                      :rules="caregiverRequired ? [validationRules.required] : []"
                    />
                  </v-col>

                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <v-text-field
                      :id="getIdByName('caregiverEmail')"
                      ref="caregiverEmail"
                      v-model="formData.caregiverInfo.caregiverEmail"
                      :label="$t('email')"
                      filled
                      :class="caregiverRequired && 'required-indicator'"
                      :rules="caregiverRequired ? [validationRules.required, validationRules.email] : []"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="auto">
                <v-icon class="mt-4">mdi-hospital-building</v-icon>
              </v-col>

              <v-col>
                <v-row dense>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <v-select
                      ref="patientType"
                      v-model="formData.patient_type"
                      :items="patientTypes"
                      :label="$t('patientType')"
                      item-text="name"
                      item-value="id"
                      filled
                      :no-data-text="$t('noDataAvailable')"
                      class="required-indicator"
                      :rules="[validationRules.required]"
                      @input="organizationsScopeChanged(true)"
                    />
                  </v-col>

                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <v-select
                      ref="organization"
                      v-model="formData.organizationId"
                      :items="organizations"
                      :label="$t('organization')"
                      item-text="name"
                      item-value="id"
                      filled
                      :no-data-text="$t('noDataAvailable')"
                      class="required-indicator"
                      :rules="[validationRules.required]"
                      @input="facilitiesScopeChanged(true)"
                    />
                  </v-col>

                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <v-select
                      ref="facility"
                      v-model="formData.facilityId"
                      :items="facilities"
                      :label="$t('facility')"
                      item-text="name"
                      item-value="id"
                      filled
                      :no-data-text="$t('noDataAvailable')"
                      class="required-indicator"
                      :rules="[validationRules.required]"
                      @input="roombedsScopeChanged(true)"
                    />
                  </v-col>
                </v-row>

                <v-row dense>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <DateTimePickerField
                      ref="startDate"
                      v-model="formData.startDate"
                      :label="startDateLabel"
                      picker-type="date"
                      required
                    />
                  </v-col>

                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <v-select
                      v-if="hasRoombeds"
                      ref="roombed"
                      v-model="formData.roombedId"
                      :items="roombeds"
                      :label="$t('roombed')"
                      item-text="label"
                      item-value="value"
                      filled
                      :no-data-text="$t('noDataAvailable')"
                      class="required-indicator"
                      :rules="[validationRules.required]"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row v-if="residentId">
              <v-col cols="auto">
                <v-icon class="mt-4">mdi-table-edit</v-icon>
              </v-col>

              <v-col>
                <v-row>
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 4">
                    <detail-field
                      :id="getIdByName('created_at')"
                      ref="createdDate"
                      v-model="formData.created_at"
                      :label="$t('createdDate')"
                    />
                    <detail-field
                      :id="getIdByName('created_by')"
                      ref="createdBy"
                      v-model="formData.created_by"
                      :label="$t('createdBy')"
                    />
                  </v-col>

                  <v-col>
                    <detail-field
                      :id="getIdByName('updated_at')"
                      ref="modifiedDate"
                      v-model="formData.updated_at"
                      :label="$t('modifiedDate')"
                    />
                    <detail-field
                      :id="getIdByName('updated_by')"
                      ref="modifiedBy"
                      v-model="formData.updated_by"
                      :label="$t('modifiedBy')"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <RequiredFieldsLegend />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn :id="getIdByName('save')" ref="save" text color="primary" @click.stop="createOrUpdateAction()">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import patientService from '@/services/patientService';
import validationRulesMixin from '@/validationRulesMixin';

export default {
  name: 'PatientModal',
  components: {},
  mixins: [translation, accessibility, validationRulesMixin],
  props: {
    residentId: {
      type: Number,
      required: false,
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      patientTypeFacility: { homecare: 'CLSC', residency: 'CHSLD', hospitalized: 'CLSC' },
      error: null,
      title: '',
      showWaitModal: false,
      countries: [],
      genders: [],
      languages: [],
      patientTypes: [],
      provinces: [],
      allProvinces: [],
      active: true,
      organizations: [],
      allOrganizations: [],
      facilities: [],
      facilitiesTypeFiltered: [],
      allFacilities: [],
      roombeds: [],
      allRoombeds: [],
      formData: {
        firstName: '',
        lastName: '',
        birthDate: null,
        nasm: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        postal_code: '',
        countryId: null,
        gender: null,
        language: null,
        patient_type: null,
        provinceId: null,
        active: true,
        organizationId: null,
        facilityId: null,
        roombedId: null,
        startDate: null,
        caregiverInfo: {
          caregiverLastName: null,
          caregiverFirstName: null,
          caregiverPhone: null,
          caregiverEmail: null,
          caregiverRelation: null,
        },
        created_at: '',
        created_by: '',
        updated_at: '',
        updated_by: '',
      },
      originalFormData: {},
    };
  },
  computed: {
    patientTypeCodeSelected() {
      return this.patientTypes.find(({ id }) => id === this.formData.patient_type)?.code;
    },
    facilityTypeFilter() {
      return this.patientTypeFacility[this.patientTypeCodeSelected];
    },
    hasRoombeds() {
      return this.facilities?.find(({ id }) => id === this.formData.facilityId)?.type?.code === 'CHSLD';
    },
    startDateLabel() {
      return this.patientTypeCodeSelected === 'residency' ? this.$t('admitDate') : this.$t('serviceStartDate');
    },
    provinceLabel() {
      const selectedCountry = this.countries.find((country) => country.id === this.formData.countryId);
      if (selectedCountry?.code === 'FR') {
        return this.$t('department');
      } else {
        return this.$t('province');
      }
    },
    caregiverRequired() {
      if (
        this.formData.caregiverInfo.caregiverLastName ||
        this.formData.caregiverInfo.caregiverFirstName ||
        this.formData.caregiverInfo.caregiverPhone ||
        this.formData.caregiverInfo.caregiverEmail ||
        this.formData.caregiverInfo.caregiverRelation
      ) {
        return true;
      } else {
        return false;
      }
    },
    caregiverRelations() {
      return [
        this.$t('daughter'),
        this.$t('son'),
        this.$t('spouse'),
        this.$t('daughterInLaw'),
        this.$t('sonInLaw'),
        this.$t('sister'),
        this.$t('brother'),
        this.$t('neighbor'),
        this.$t('friend'),
        this.$t('father'),
        this.$t('mother'),
      ].sort((a, b) => a.localeCompare(b));
    },
  },

  watch: {
    async show() {
      if (this.show) {
        if (this.residentId != null) {
          await this.editClicked();
        } else {
          await this.init();
        }
      }
    },
  },

  methods: {
    async init() {
      this.title = this.$t('createResident');
      this.provinces = [];
      this.formData = {
        firstName: '',
        lastName: '',
        birthDate: null,
        nasm: '',
        phone: '',
        email: '',
        address: '',
        city: '',
        postal_code: '',
        countryId: null,
        gender: null,
        language: null,
        patient_type: null,
        provinceId: null,
        active: true,
        organizationId: null,
        facilityId: null,
        roombedId: null,
        startDate: null,
        caregiverInfo: {
          caregiverLastName: null,
          caregiverFirstName: null,
          caregiverPhone: null,
          caregiverEmail: null,
          caregiverRelation: null,
        },
        created_at: '',
        created_by: '',
        updated_at: '',
        updated_by: '',
      };
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      await this.getAllSelects();
    },

    async editClicked() {
      this.title = this.$t('editResident');
      this.showWaitModal = false;

      try {
        var patient = await patientService.getPatientById(this.residentId);

        this.formData.firstName = patient.firstName;
        this.formData.lastName = patient.lastName;
        this.formData.birthDate = patient.birthDate;
        this.formData.nasm = patient.nasm;
        this.formData.phone = patient.phone;
        this.formData.email = patient.email;
        this.formData.address = patient.address;
        this.formData.city = patient.city;
        this.formData.postal_code = patient.postal_code;
        this.formData.countryId = patient.country != null ? patient.country.id : null;
        this.formData.gender = patient.gender != null ? patient.gender.id : null;
        this.formData.language = patient.language != null ? patient.language.id : null;
        this.formData.patient_type = patient.patient_type != null ? patient.patient_type.id : null;
        this.formData.provinceId = patient.province != null ? patient.province.id : null;
        this.formData.active = patient.active;
        this.formData.organizationId = patient.organization != null ? patient.organization.id : null;
        this.formData.facilityId = patient.facility != null ? patient.facility.id : null;
        this.formData.roombedId = patient.roombed != null ? patient.roombed.id : null;
        this.formData.startDate = patient.startDate;
        this.formData.caregiverInfo.caregiverLastName = patient.caregiverInfo?.caregiverLastName;
        this.formData.caregiverInfo.caregiverFirstName = patient.caregiverInfo?.caregiverFirstName;
        this.formData.caregiverInfo.caregiverPhone = patient.caregiverInfo?.caregiverPhone;
        this.formData.caregiverInfo.caregiverEmail = patient.caregiverInfo?.caregiverEmail;
        this.formData.caregiverInfo.caregiverRelation = patient.caregiverInfo?.caregiverRelation;
        this.formData.created_at = patient.created_at;
        this.formData.created_by = patient.created_by != null ? patient.created_by.username : null;
        this.formData.updated_at = patient.updated_at;
        this.formData.updated_by = patient.updated_by != null ? patient.updated_by.username : null;
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));

        await this.getAllSelects();
      } catch (error) {
        this.error = error;
      }
    },

    async createOrUpdateAction() {
      this.showWaitModal = false;
      let isValid = this.$refs.patientModalForm.validate();

      if (!isValid) {
        let firstInvalidField = this.$refs.patientModalForm.$children.find((e) => e.valid === false);

        firstInvalidField?.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });

        return;
      }

      this.showWaitModal = true;

      var data = {
        residentId: this.residentId,
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        birthDate: this.formData.birthDate,
        nasm: this.formData.nasm,
        phone: this.formData.phone,
        email: this.formData.email,
        address: this.formData.address,
        city: this.formData.city,
        postal_code: this.formData.postal_code,
        countryId: this.formData.countryId,
        genderId: this.formData.gender,
        languageId: this.formData.language,
        patient_typeId: this.formData.patient_type,
        provinceId: this.formData.provinceId,
        active: this.formData.active,
        organizationId: this.formData.organizationId,
        facilityId: this.formData.facilityId,
        roombedId: this.formData.roombedId,
        startDate: this.formData.startDate,
        caregiverInfo: {
          caregiverLastName: this.formData.caregiverInfo.caregiverLastName,
          caregiverFirstName: this.formData.caregiverInfo.caregiverFirstName,
          caregiverPhone: this.formData.caregiverInfo.caregiverPhone,
          caregiverEmail: this.formData.caregiverInfo.caregiverEmail,
          caregiverRelation: this.formData.caregiverInfo.caregiverRelation,
        },
      };

      try {
        if (this.residentId === null) {
          await patientService.createPatient(data);
        } else {
          await patientService.updatePatient(this.residentId, data);
        }
        this.closeDialog(true);
      } catch (error) {
        this.error = error;
      }
    },

    closeDialog: function (refresh) {
      this.$emit('close', refresh);
    },

    async getAllSelects() {
      this.showWaitModal = false;

      try {
        let data = await patientService.getPatientParameters();

        this.countries = data.countries;
        this.genders = data.genders;
        this.languages = data.languages;
        this.patientTypes = data.patientTypes;

        this.allOrganizations = data.organizations;
        this.allFacilities = data.facilities;

        this.allRoombeds = data.roombeds.map(this.convertToValueLabelRoombed);
        this.organizationsScopeChanged(false);

        this.allProvinces = data.provinces.map(this.convertProvinceWithConventionalName);
        this.provincesScopeChanged(false);
      } catch (error) {
        this.error = error;
      }
    },

    convertToValueLabelRoombed: function (object) {
      return {
        value: object.id,
        label: object.room + ' - ' + object.bed,
        facilityId: object.facility ? object.facility.id : null,
      };
    },

    convertProvinceWithConventionalName: function (province) {
      if (province?.country?.code === 'FR') {
        return {
          id: province.id,
          code: province.code,
          name: province.code + ' - ' + province.name,
          countryId: province?.country?.id,
        };
      } else {
        return {
          id: province.id,
          code: province.code,
          name: province.name,
          countryId: province?.country?.id,
        };
      }
    },

    organizationsScopeChanged: function (resetValue) {
      this.facilitiesTypeFiltered = this.allFacilities.filter((facility) => {
        return facility.type.code === this.facilityTypeFilter;
      });
      this.organizations = this.allOrganizations.filter((org) => {
        return this.facilitiesTypeFiltered.find(({ organization }) => organization.id === org.id);
      });

      resetValue ? (this.formData.organizationId = null) : null;
      this.facilitiesScopeChanged(resetValue);
    },

    facilitiesScopeChanged: function (resetValue) {
      this.facilities = this.facilitiesTypeFiltered.filter((facility) => {
        return (facility.organization ? facility.organization.id : null) === this.formData.organizationId;
      });

      resetValue ? (this.formData.facilityId = null) : null;
      this.roombedsScopeChanged(resetValue);
    },

    roombedsScopeChanged: function (resetValue) {
      this.roombeds = this.allRoombeds.filter((roombed) => {
        return roombed.facilityId === this.formData.facilityId;
      });
      resetValue ? ((this.formData.roombedId = null), (this.startDate = null)) : null;
    },

    provincesScopeChanged(resetValue) {
      this.provinces = this.allProvinces
        .filter((province) => {
          return province.countryId === this.formData.countryId;
        })
        .sort(this.provinceSortFunc);

      resetValue ? (this.formData.provinceId = null) : null;
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },

    provinceSortFunc(a, b) {
      let expression = /(?<nbr>[0-9]*)(?<str>.*)/;
      let matchA = a.name.match(expression);
      let matchB = b.name.match(expression);

      // If we have a province with the code 2A and another one with the code 20, we want
      // the 2A to appear before the 20 in the list

      if (matchA.groups.nbr && matchB.groups.nbr) {
        if (matchA.groups.nbr < matchB.groups.nbr) {
          return -1;
        } else if (matchA.groups.nbr > matchB.groups.nbr) {
          return 1;
        }
      }

      if (matchA.groups.nbr || matchB.groups.nbr) {
        return matchA.groups.nbr ? 1 : -1;
      }

      if (matchA.groups.str || matchB.groups.str) {
        if (matchA.groups.str && matchB.groups.str) {
          return matchA.groups.str.localeCompare(matchB.groups.str);
        }
        return matchA.groups.str ? 1 : -1;
      }

      return a.name.localeCompare(b.name);
    },
  },
};
</script>

<style scoped></style>
