<template>
  <v-container>
    <MonitoringTypeGrid />
  </v-container>
</template>

<script>
import MonitoringTypeGrid from './MonitoringTypeGrid';
export default {
  name: 'IotHome',
  components: {
    MonitoringTypeGrid,
  },
  mixins: [],
  props: {},
  data() {
    return {};
  },
  created: function () {},
  methods: {},
};
</script>

<style scoped></style>
