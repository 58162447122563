/**
 * Perform a case-insensitive and accent insensitive search to determine whether a string is contained within another string.
 * @param {String} value String in which to search for `searchString`
 * @param {String} searchString Searched value
 * @returns
 */
export const containsString = function (value, searchString) {
  if (value !== null && searchString !== null && typeof value === 'string' && typeof searchString === 'string') {
    // See https://www.davidbcalhoun.com/2019/matching-accented-strings-in-javascript/ for an explication of this code
    const removeDiacritics = (str) => {
      return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    };

    const regExp = new RegExp(removeDiacritics(searchString), 'gi');
    return regExp.test(removeDiacritics(value));
  }
  return false;
};
