<template>
  <v-container>
    <ConfirmationModal
      :question="$t('confirmDeleteMonitoringType')"
      :title="$t('delete')"
      :show="showDelete"
      @cancel="showDelete = false"
      @ok="deleteSelectedMonitoringType"
    />

    <ErrorModal :error="error" @close-error-modal="error = null" />

    <v-row>
      <v-col>
        <div class="page-title">
          {{ $t('monitoringTypes') }}
        </div>
      </v-col>
    </v-row>

    <v-row v-if="$can(permissions.actions.add, permissions.subjects.monitoringType)">
      <v-col>
        <v-btn ref="addMonitoringType" color="primary" @click="editMonitoringTypeAction(null)">
          {{ $t('add') }}
        </v-btn>
      </v-col>
    </v-row>

    <WaitModal :show="showWaitModal" />

    <v-row>
      <v-col>
        <DataGrid
          ref="dataGrid"
          :items="monitoringTypes"
          :columns="columns"
          grid-name="monitoring_types_grid"
          :show-select="false"
          :items-per-page="25"
        >
          <template #item.actions="{ item }">
            <v-menu :ref="`actionMenu_${item.id}`">
              <template #activator="{ on }">
                <v-btn :ref="`actions_${item.id}`" icon v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-if="$can(permissions.actions.change, permissions.subjects.monitoringType)"
                  :ref="`editMonitoringType_${item.id}`"
                  @click="editMonitoringTypeAction(item.id)"
                >
                  <v-list-item-icon><v-icon>mdi-pencil</v-icon></v-list-item-icon>
                  <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
                </v-list-item>
                <v-divider
                  v-if="
                    $can(permissions.actions.change, permissions.subjects.monitoringType) &&
                    $can(permissions.actions.delete, permissions.subjects.monitoringType)
                  "
                ></v-divider>

                <v-list-item
                  v-if="$can(permissions.actions.delete, permissions.subjects.monitoringType)"
                  :ref="`deleteMonitoringType_${item.id}`"
                  @click="deleteAction(item.id)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>{{ $t('delete') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </DataGrid>
      </v-col>
    </v-row>

    <MonitoringTypeModal :show.sync="showEditModal" :monitoring-type-id="selectedMonitoringTypeId" @refresh="init()" />
  </v-container>
</template>

<script>
import DataGrid from '@/components/DataGrid.vue';
import MonitoringTypeModal from './MonitoringTypeModal';
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import MonitoringTypeService from '@/services/monitoringTypeService';
import permissionMixin from '@/auth/permissionMixin';

export default {
  name: 'MonitoringTypeGrid',
  components: { DataGrid, MonitoringTypeModal },
  mixins: [translationMixin, accessibility, permissionMixin],
  props: {},
  data() {
    return {
      showDelete: false,
      showEditModal: false,
      error: null,
      selectedMonitoringTypeId: null,
      showWaitModal: false,
      monitoringTypes: [],
    };
  },
  computed: {
    columns() {
      let columns = [
        {
          text: 'name',
          value: 'name',
          filterable: true,
        },
        {
          text: 'activityType',
          value: 'activityType',
          filterable: true,
        },
        {
          text: 'createdDate',
          value: 'createdAt',
          filterable: true,
        },
        {
          text: 'createdBy',
          value: 'createdBy.username',
          filterable: true,
        },
        {
          text: 'modifiedDate',
          value: 'modifiedAt',
          filterable: true,
        },
        {
          text: 'modifiedBy',
          value: 'modifiedBy.username',
          filterable: true,
        },
      ];
      if (
        this.$can(this.permissions.actions.change, this.permissions.subjects.monitoringType) ||
        this.$can(this.permissions.actions.delete, this.permissions.subjects.monitoringType)
      ) {
        columns.push({
          text: 'Actions',
          value: 'actions',
          filterable: false,
          sortable: false,
        });
      }
      return columns;
    },
  },
  created: function () {
    this.init();
  },

  methods: {
    async init() {
      this.monitoringTypes = [];
      this.showWaitModal = true;
      this.showDelete = false;
      this.showEditModal = false;
      try {
        let monitoringTypes = await MonitoringTypeService.getMonitoringTypes();
        this.monitoringTypes = monitoringTypes.map((item) => {
          let result = {
            id: item.id,
            name: item.name,
            activityType: item.activityType.name,
            createdAt: item.createdAt,
            createdBy: item.createdBy,
            modifiedAt: item.updatedAt,
            modifiedBy: item.updatedBy,
          };
          return result;
        });
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    async deleteSelectedMonitoringType() {
      this.showDelete = false;
      this.showWaitModal = true;

      try {
        await MonitoringTypeService.deleteMonitoringType(this.selectedMonitoringTypeId);
        this.init();
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    editMonitoringTypeAction: function (id) {
      this.selectedMonitoringTypeId = id;
      this.showEditModal = true;
    },

    deleteAction: function (id) {
      this.selectedMonitoringTypeId = id;
      this.showDelete = true;
    },
  },
};
</script>
