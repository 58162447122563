import { render, staticRenderFns } from "./ResidentMessageGrid.vue?vue&type=template&id=188d6558&scoped=true&"
import script from "./ResidentMessageGrid.vue?vue&type=script&lang=js&"
export * from "./ResidentMessageGrid.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "188d6558",
  null
  
)

export default component.exports