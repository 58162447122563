import { StatusLevels } from './constants';
export default {
  data: function () {
    return {};
  },

  created: function () {},

  methods: {
    getIconColor: function (code) {
      var color = null;

      switch (code) {
        case StatusLevels.NO:
        case StatusLevels.DR:
          color = 'green';
          break;
        case StatusLevels.WA:
        case StatusLevels.LH:
          color = 'orange';
          break;
        case StatusLevels.AL:
        case StatusLevels.MH:
          color = 'red';
          break;
        case StatusLevels.WE:
          color = 'yellow';
          break;
        case StatusLevels.CH:
          color = 'purple';
          break;
        default:
          color = 'rgba(0, 0, 0, 0.54)';
          break;
      }

      return color;
    },

    getAnamAlertColor: function (code) {
      var color = null;

      switch (code) {
        case StatusLevels.WA:
          color = 'orange';
          break;
        case StatusLevels.AL:
          color = 'red';
          break;
        default:
          color = null;
          break;
      }

      return color;
    },

    getIconName: function (code) {
      var name = null;

      switch (code) {
        case StatusLevels.NO:
        case StatusLevels.DR:
          name = 'mdi-check-circle';
          break;
        case StatusLevels.ND:
        case StatusLevels.WA:
          name = 'mdi-alert';
          break;
        case StatusLevels.AL:
          name = 'mdi-alert-octagon';
          break;
        case StatusLevels.WE:
        case StatusLevels.LH:
        case StatusLevels.MH:
        case StatusLevels.CH:
          name = 'mdi-alert-circle';
          break;
        case StatusLevels.NF:
          name = 'mdi-minus-thick';
          break;
        default:
          name = '';
          break;
      }

      return name;
    },

    getAnamAlertIcon: function (code) {
      var name = null;

      switch (code) {
        case StatusLevels.NO:
          name = 'mdi-check';
          break;
        case StatusLevels.WA:
          name = 'mdi-alert';
          break;
        case StatusLevels.AL:
          name = 'mdi-alert-octagon';
          break;
        default:
          name = 'none';
          break;
      }

      return name;
    },

    getPrescriptionAlertIcon: function (code, allTaken) {
      var name = null;

      switch (code) {
        case StatusLevels.NO:
          name = allTaken ? 'mdi-checkbox-outline' : 'mdi-checkbox-blank-outline';
          break;
        default:
          name = 'mdi-alert-octagon';
          break;
      }

      return name;
    },

    getFDEAlertColor: function (code) {
      var color = null;

      switch (code) {
        case StatusLevels.AL:
          color = 'red';
          break;
        default:
          color = null;
          break;
      }

      return color;
    },
  },
};
