<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent max-width="600px">
      <v-card class="eb-white-bg">
        <ModalTitle :title="$t('iotRoomConfiguration')" />

        <WaitModal :show="showWaitModal" />

        <v-card-text>
          <v-form ref="IOTRoomModalForm">
            <v-select
              ref="IOTOrganization"
              v-model="formData.organizationId"
              :items="allOrganizations"
              clearable
              :label="$t('organization')"
              item-text="name"
              item-value="id"
              filled
              :no-data-text="$t('noDataAvailable')"
              @input="onOrganisationInput()"
            />

            <v-select
              ref="IOTFacility"
              v-model="formData.facilityId"
              :items="facilities"
              :label="$t('facility')"
              item-text="name"
              item-value="id"
              filled
              :no-data-text="$t('noDataAvailable')"
              :class="formData.organizationId && 'required-indicator'"
              :rules="formData.organizationId ? [validationRules.required] : []"
              @input="onFacilityInput()"
            />

            <v-select
              ref="IOTRoombed"
              v-model="formData.roomId"
              :items="rooms"
              :label="$t('room')"
              item-text="room"
              item-value="id"
              filled
              :no-data-text="$t('noDataAvailable')"
              :class="formData.organizationId && 'required-indicator'"
              :rules="formData.organizationId ? [validationRules.required] : []"
            />
          </v-form>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            v-if="showWaitModal == false"
            :id="getIdByName('configure')"
            ref="configure"
            text
            color="primary"
            @click.stop="configure()"
          >
            {{ $t('configure') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import iotService from '@/services/iotService';

export default {
  name: 'IotRoomModal',

  components: {},
  mixins: [translation, accessibility, validationRulesMixin],

  props: {
    iotId: {
      type: Number,
      required: false,
      default: null,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      showWaitModal: false,
      allOrganizations: [],
      allFacilities: [],
      allRooms: [],
      formData: {
        organizationId: null,
        facilityId: null,
        roomId: null,
      },
      originalFormData: {},
    };
  },

  computed: {
    facilities: function () {
      return this.allFacilities?.filter(
        (facility) => (facility.organization ? facility.organization.id : null) === this.formData.organizationId
      );
    },
    rooms: function () {
      return this.allRooms?.filter((room) => room.facility.id === this.formData.facilityId);
    },
  },

  watch: {
    show: function () {
      if (this.show) {
        this.init();
      }
    },
    'formData.organizationId': function () {
      if (!this.formData.organizationId) {
        this.formData.facilityId = null;
        this.formData.roomId = null;
      }
    },
  },

  created: function () {},
  methods: {
    async init() {
      this.showWaitModal = true;

      this.formData = {
        organizationId: null,
        facilityId: null,
        roomId: null,
      };

      await this.getAllData();
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
    },

    async getAllData() {
      this.showWaitModal = true;

      try {
        let [iotRoom, availableRooms] = await Promise.all([
          await iotService.getIotRoom(this.iotId),
          await iotService.getIotDeviceAvailableRooms(this.iotId),
        ]);

        if (iotRoom) {
          this.formData.organizationId = iotRoom.organizationId;
          this.formData.facilityId = iotRoom.facilityId;
          this.formData.roomId = iotRoom.roomId;
        }

        this.allOrganizations = availableRooms.organizations;
        this.allFacilities = availableRooms.facilities;
        this.allRooms = availableRooms.roombeds?.map((roombed) => {
          return {
            id: roombed.id,
            room: roombed.room,
            facility: roombed.facility,
          };
        });
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    onOrganisationInput: function () {
      this.formData.facilityId = null;
      this.formData.roomId = null;
    },

    onFacilityInput: function () {
      this.formData.roomId = null;
    },

    async configure() {
      let isValid = this.$refs.IOTRoomModalForm.validate();

      if (!isValid) {
        return;
      }

      let data = {
        organization: this.formData.organizationId,
        facility: this.formData.facilityId,
        room: this.formData.roomId,
      };

      this.showWaitModal = true;
      try {
        await iotService.updateIotRoom(this.iotId, data);
        this.closeDialog();
        this.$emit('refresh');
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
  },
};
</script>

<style scoped></style>
