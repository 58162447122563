<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />
    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent :max-width="computedMaxWidth">
      <v-card>
        <ModalTitle :title="title" />
        <WaitModal :show="showWaitModal" />
        <v-tabs v-model="selectedActivityTab" class="px-6" background-color="transparent">
          <v-tab> {{ $t('activity') }} </v-tab>
          <v-tab v-show="alertsTabVisible">
            {{ $t('threshold') + ` (${formData.alerts.length})` }}
          </v-tab>
          <v-tab v-show="dialoguesTabVisible"> {{ $t('dialogues') + ` (${formData.dialogues.length})` }} </v-tab>
          <v-tab v-show="triggersTabVisible"> {{ $t('triggers') + ` (${formData.triggers.length})` }} </v-tab>
          <v-tab v-show="alertsDialogueTabVisible">
            {{ $t('alertConfiguration') + ` (${formData.alerts.length})` }}
          </v-tab>
          <v-tab v-show="vmTriggersTabVisible"
            >{{ $t('vocalMessagesTriggers') + ` (${formData.vocalMessagesTriggers.length})` }}
          </v-tab>
        </v-tabs>
        <v-card-text>
          <v-tabs-items v-model="selectedActivityTab" class="activity-tabs">
            <v-tab-item>
              <v-form ref="activityForm">
                <v-row class="mt-3">
                  <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                    <v-text-field
                      ref="activityName"
                      v-model="formData.name"
                      filled
                      :label="$t('name')"
                      :rules="[validationRules.required]"
                      class="required-indicator"
                    />

                    <v-textarea
                      ref="description"
                      v-model="formData.description"
                      height="135"
                      :label="$t('description')"
                      filled
                      :rules="[validationRules.required]"
                      class="required-indicator"
                    />

                    <v-autocomplete
                      ref="monitoringType"
                      :value="formData.monitoringType"
                      :items="monitoringTypes"
                      :label="$t('monitoringType')"
                      item-text="name"
                      item-value="id"
                      filled
                      :rules="[validationRules.required]"
                      class="required-indicator"
                      @input="monitoringTypeChange"
                    />

                    <v-select
                      ref="iotDeviceType"
                      v-model="formData.iotDeviceType"
                      :items="IOTTypes"
                      :label="$t('iotDeviceType')"
                      item-text="name"
                      :no-data-text="!formData.monitoringType ? $t('monitoringTypeRequired') : undefined"
                      item-value="id"
                      filled
                      :rules="[validationRules.required]"
                      class="required-indicator"
                    />

                    <v-select
                      ref="vocalMessages"
                      v-model="formData.vocalMessagesSelected"
                      :items="vocalMessages"
                      :label="$t('vocalMessages')"
                      item-text="name"
                      item-value="id"
                      :no-data-text="!formData.iotDeviceType ? $t('iotDeviceTypeRequired') : $t('noDataAvailable')"
                      multiple
                      filled
                    />

                    <v-row>
                      <v-col cols="auto">
                        <v-checkbox ref="checkActive" v-model="formData.active" :label="$t('active')" hide-details />
                      </v-col>
                      <v-col cols="auto">
                        <v-checkbox
                          ref="checkContinous"
                          v-model="formData.continous"
                          :disabled="iotDeviceContinous"
                          :label="$t('continous')"
                        />
                      </v-col>
                      <v-col cols="auto">
                        <v-checkbox
                          ref="checkNormative"
                          v-model="formData.normative"
                          :label="$t('normative')"
                          :disabled="!$can(permissions.actions.manage, permissions.subjects.normative)"
                          @change="checkNonNormativeStuff"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col v-if="activityId != null" :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'">
                    <detail-field
                      v-if="activityId != null"
                      :id="getIdByName('createdDate')"
                      ref="createdDate"
                      v-model="formData.createdDate"
                      :label="$t('createdDate')"
                    />

                    <detail-field
                      v-if="activityId != null"
                      :id="getIdByName('createdBy')"
                      ref="createdBy"
                      v-model="formData.createdBy"
                      :label="$t('createdBy')"
                    />

                    <detail-field
                      v-if="activityId != null"
                      :id="getIdByName('modifiedDate')"
                      ref="modifiedDate"
                      v-model="formData.modifiedDate"
                      :label="$t('modifiedDate')"
                    />

                    <detail-field
                      v-if="activityId != null"
                      :id="getIdByName('modifiedBy')"
                      ref="modifiedBy"
                      v-model="formData.modifiedBy"
                      :label="$t('modifiedBy')"
                    />
                  </v-col>
                </v-row>
              </v-form>
              <RequiredFieldsLegend />
            </v-tab-item>
            <v-tab-item eager class="pt-4">
              <div v-if="activityAlertFields">
                <v-form ref="activityAlertForm">
                  <div v-for="(alert, index) in formData.alerts" :key="alert.internalId">
                    <v-row>
                      <v-col cols="auto">
                        <div class="pt-6">
                          <span class="font-weight-bold">{{ index + 1 }}.</span>
                        </div>
                      </v-col>
                      <v-col>
                        <v-row dense>
                          <v-col
                            v-if="activityAlertFields.monitoringValue"
                            :cols="alertGridSizes.monitoringValue.cols"
                            :md="alertGridSizes.monitoringValue.md"
                            :lg="alertGridSizes.monitoringValue.lg"
                          >
                            <v-select
                              ref="parametersType"
                              v-model="alert.monitoringValue"
                              :label="$t('parametersType')"
                              :items="activityAlertFields.monitoringValue"
                              filled
                              :rules="[validationRules.required]"
                              class="required-indicator"
                            />
                          </v-col>
                          <v-col
                            v-if="activityAlertFields.type"
                            :cols="alertGridSizes.type.cols"
                            :md="alertGridSizes.type.md"
                            :lg="alertGridSizes.type.lg"
                          >
                            <v-select
                              ref="alertType"
                              v-model="alert.type"
                              :label="$t('alertType')"
                              :items="activityAlertFields.type"
                              filled
                              :rules="[validationRules.required]"
                              class="required-indicator"
                              @change="changeAlertType(alert)"
                            />
                          </v-col>
                          <v-col
                            v-if="activityAlertFields.unitType"
                            :cols="alertGridSizes.threshold.cols"
                            :md="alertGridSizes.threshold.md"
                            :lg="alertGridSizes.threshold.lg"
                          >
                            <v-text-field
                              ref="thresholdText"
                              v-model.number="alert.threshold"
                              filled
                              :label="$t('thresholdText')"
                              class="align-suffix required-indicator"
                              :rules="[validationRules.required]"
                              type="number"
                              hide-spin-buttons
                              max="200"
                              :suffix="getSuffix(alert.unitType, activityAlertFields.unitType)"
                              :append-icon="
                                activityAlertFields.unitType.length > 2 ||
                                (alert.type === 'variation' && alert.monitoringValue !== 'SAT')
                                  ? 'mdi-autorenew'
                                  : undefined
                              "
                              @click:append="toggleUnitType(alert)"
                            />
                          </v-col>
                          <v-col
                            v-if="activityAlertFields.lengthType"
                            :cols="alertGridSizes.length.cols"
                            :md="alertGridSizes.length.md"
                            :lg="alertGridSizes.length.lg"
                          >
                            <v-text-field
                              ref="dateLimitText"
                              v-model.number="alert.length"
                              type="number"
                              :rules="[validationRules.required]"
                              filled
                              hide-spin-buttons
                              :label="$t('dateLimitText')"
                              :append-icon="activityAlertFields.lengthType.length > 1 ? 'mdi-autorenew' : undefined"
                              class="align-suffix required-indicator"
                              max="31"
                              :suffix="getSuffix(alert.lengthType, activityAlertFields.lengthType)"
                              @click:append="toggleLengthType(alert)"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col
                            v-if="activityAlertFields.recipients"
                            :cols="alertGridSizes.recipients.cols"
                            :md="alertGridSizes.recipients.md"
                            :lg="alertGridSizes.recipients.lg"
                          >
                            <v-select
                              ref="recipients"
                              v-model="alert.recipients"
                              :items="activityAlertFields.recipients"
                              :label="$t('addNotification')"
                              filled
                              small-chips
                              deletable-chips
                              multiple
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="auto">
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-btn
                              color="primary"
                              icon
                              :disabled="formData.alerts.length <= 1"
                              @click="deleteAlert(alert)"
                              v-on="on"
                            >
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          {{ $t('delete') }}
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <v-divider v-if="index < formData.alerts.length - 1" class="mb-6" />
                  </div>
                </v-form>
                <RequiredFieldsLegend />
              </div>
            </v-tab-item>
            <v-tab-item>
              <activity-dialogue
                :columns-assigned="dialogueColumns"
                :columns-available="allDialogueColumns"
                :all-items="allDialogues"
                :assigned-items.sync="formData.dialogues"
                :available-title="$t('availableDialogues')"
                :assigned-title="$t('currentActivityDialogues')"
              >
              </activity-dialogue>
            </v-tab-item>
            <v-tab-item eager class="pt-4">
              <v-form ref="triggerForm">
                <div v-for="(trigger, index) in formData.triggers" :key="index">
                  <v-row>
                    <v-col cols="auto">
                      <div class="pt-6">
                        <span class="font-weight-bold">{{ index + 1 }}.</span>
                      </div>
                    </v-col>
                    <v-col cols="auto">
                      <DateTimePickerField
                        ref="triggerTime"
                        v-model="trigger.startTime"
                        :label="$t('timeOnly')"
                        required
                        max="200"
                        picker-type="time"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-text-field
                        ref="triggerRepeatMinute"
                        v-model.number="trigger.repeatMinute"
                        filled
                        :label="$t('repeatMinute')"
                        class="align-suffix"
                        type="number"
                        hide-spin-buttons
                        max="200"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-text-field
                        ref="triggerRepeatCount"
                        v-model.number="trigger.repeatCount"
                        filled
                        :label="$t('repeatCount')"
                        class="align-suffix"
                        type="number"
                        hide-spin-buttons
                        max="200"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn color="primary" icon @click="deleteTrigger(trigger)" v-on="on">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        {{ $t('delete') }}
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
              <RequiredFieldsLegend />
            </v-tab-item>
            <v-tab-item eager class="pt-4">
              <div v-if="activityAlertFields && hasDialogues">
                <v-form ref="configurationAlertDialogueForm">
                  <div v-for="(alert, index) in formData.alerts" :key="alert.internalId">
                    <v-row>
                      <v-col cols="auto">
                        <div class="pt-6">
                          <span class="font-weight-bold">{{ index + 1 }}.</span>
                        </div>
                      </v-col>
                      <v-col>
                        <v-row dense>
                          <v-col
                            v-if="activityAlertFields.monitoringValue"
                            :cols="alertGridSizes.monitoringValue.cols"
                            :md="alertGridSizes.monitoringValue.md"
                            :lg="alertGridSizes.monitoringValue.lg"
                          >
                            <v-select
                              ref="dialogueAlert"
                              v-model="alert.dialogueId"
                              :label="$t('dialogue')"
                              :items="formData.dialogues"
                              item-text="dialogueName"
                              item-value="dialogueId"
                              filled
                              :rules="[validationRules.required]"
                              class="required-indicator"
                            />
                          </v-col>
                          <v-col
                            v-if="activityAlertFields.type"
                            :cols="alertGridSizes.type.cols"
                            :md="alertGridSizes.type.md"
                            :lg="alertGridSizes.type.lg"
                          >
                            <v-select
                              ref="alertType"
                              v-model="alert.type"
                              :label="$t('alertType')"
                              :items="activityAlertFields.type"
                              filled
                              :rules="[validationRules.required]"
                              class="required-indicator"
                            />
                          </v-col>
                          <v-col
                            v-if="activityAlertFields.lengthType"
                            :cols="alertGridSizes.length.cols"
                            :md="alertGridSizes.length.md"
                            :lg="alertGridSizes.length.lg"
                          >
                            <v-text-field
                              ref="dateLimitText"
                              v-model.number="alert.length"
                              type="number"
                              filled
                              hide-spin-buttons
                              :label="$t('dateLimitText')"
                              :append-icon="activityAlertFields.lengthType.length > 1 ? 'mdi-autorenew' : undefined"
                              class="align-suffix required-indicator"
                              max="31"
                              :suffix="getSuffix(alert.lengthType, activityAlertFields.lengthType)"
                              :rules="[validationRules.required]"
                              @click:append="toggleLengthType(alert)"
                            >
                            </v-text-field>
                          </v-col>
                          <v-col
                            v-if="activityAlertFields.recipients"
                            :cols="alertGridSizes.recipients.cols"
                            :md="alertGridSizes.recipients.md"
                            :lg="alertGridSizes.recipients.lg"
                          >
                            <v-select
                              ref="recipients"
                              v-model="alert.recipients"
                              :items="activityAlertFields.recipients"
                              :label="$t('addNotification')"
                              filled
                              small-chips
                              deletable-chips
                              multiple
                              :rules="[validationRules.required]"
                              class="required-indicator"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="auto">
                        <v-tooltip bottom>
                          <template #activator="{ on }">
                            <v-btn color="primary" icon @click="deleteAlert(alert)" v-on="on">
                              <v-icon>mdi-delete</v-icon>
                            </v-btn>
                          </template>
                          {{ $t('delete') }}
                        </v-tooltip>
                      </v-col>
                    </v-row>

                    <v-divider v-if="index < formData.alerts.length - 1" class="mb-6" />
                  </div>
                </v-form>
                <RequiredFieldsLegend />
              </div>
            </v-tab-item>

            <v-tab-item class="pt-4">
              <v-form ref="vocalMessagesTriggersForm">
                <div v-for="(vocalMessage, index) in formData.vocalMessagesTriggers" :key="index">
                  <v-row>
                    <v-col cols="auto">
                      <div class="pt-6">
                        <span class="font-weight-bold">{{ index + 1 }}</span>
                      </div>
                    </v-col>
                    <v-col cols="5">
                      <v-select
                        ref="vmTrigger"
                        v-model="vocalMessage.vocalMessageId"
                        :label="$t('vocalMessage')"
                        :items="
                          vocalMessages
                            ? vocalMessages.filter((val) => formData.vocalMessagesSelected.includes(val.id))
                            : []
                        "
                        item-text="name"
                        item-value="id"
                        :no-data-text="!formData.iotDeviceType ? $t('iotDeviceTypeRequired') : $t('noDataAvailable')"
                        filled
                        :rules="[validationRules.required]"
                        class="required-indicator"
                      />
                    </v-col>

                    <v-col cols="auto">
                      <DateTimePickerField
                        ref="vmTriggerTime"
                        v-model="vocalMessage.triggers.startTime"
                        :label="$t('timeOnly')"
                        required
                        max="200"
                        picker-type="time"
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-tooltip bottom>
                        <template #activator="{ on }">
                          <v-btn color="primary" icon @click="deleteVocalMessageTrigger(vocalMessage)" v-on="on">
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        {{ $t('delete') }}
                      </v-tooltip>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn v-if="selectedActivityTab === 1" color="primary" @click="addAlert">{{ $t('addAlert') }}</v-btn>
          <v-btn v-if="selectedActivityTab === 3" color="primary" @click="addTrigger">{{ $t('addTrigger') }}</v-btn>
          <v-btn v-if="selectedActivityTab === 4" color="primary" @click="addAlert">{{
            $t('addAlertConfiguration')
          }}</v-btn>

          <v-btn v-if="selectedActivityTab === 5" color="primary" @click="addVocalMessageTrigger">{{
            $t('addVocalMessagesTrigger')
          }}</v-btn>

          <v-spacer></v-spacer>
          <v-btn ref="cancel" text @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>

          <v-btn :id="getIdByName('save')" ref="save" text color="primary" @click.stop="createOrUpdateAction()">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ConfirmationModal
      :question="$t(showConfirmationModalQuestion)"
      :show="showMonitoringTypeChangeAlert || showNormativeAlert"
      :title="$t(showConfirmationModalTitle)"
      @cancel="cancelConfirmationModal(showMonitoringTypeChangeAlert, showNormativeAlert)"
      @ok="acceptConfirmationModal(showMonitoringTypeChangeAlert, showNormativeAlert)"
    />
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import virtuoseMixin from '@/virtuoseMixin';
import permissionMixin from '@/auth/permissionMixin';
import iotService from '@/services/iotService';
import vocalMessageService from '@/services/vocalMessageService';
import ActivityDialogue from './ActivityDialogue.vue';
import validationRulesMixin from '@/validationRulesMixin';

export default {
  name: 'ActivityModal',

  components: { ActivityDialogue },
  mixins: [translation, accessibility, virtuoseMixin, validationRulesMixin, permissionMixin],

  props: {
    activityId: {
      type: Number,
      required: false,
      default: null,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      showWaitModal: false,
      title: '',
      selectedActivityTab: 0,
      error: null,
      formData: {
        name: '',
        iotDeviceType: null,
        monitoringType: null,
        description: '',
        normative: false,
        active: false,
        continous: false,
        alerts: [],
        dialogues: [],
        triggers: [],
        vocalMessagesSelected: [],
        vocalMessagesTriggers: [],
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
      },
      originalFormData: {},
      parameters: null,
      cachedAlertFieldsByMonitoringType: [],
      monitoringTypes: [],
      vocalMessages: [],
      allVocalMessages: [],
      IOTTypes: [],
      showConfirmationModalTitle: '',
      showConfirmationModalQuestion: '',
      showMonitoringTypeChangeAlert: false,
      showNormativeAlert: false,
      oldMonitoringType: null,
      allDialogues: [],
      allDialoguesOriginal: [],
    };
  },

  computed: {
    iotDeviceContinous: function () {
      return this.IOTTypes.some(
        (iotType) => iotType.id === this.formData.iotDeviceType && iotType.code != 'vocalAssistant'
      );
    },
    hasDialogues: function () {
      return this.IOTTypes.some(
        (iotType) => iotType.id === this.formData.iotDeviceType && iotType.code === 'vocalAssistant'
      );
    },
    dialogueColumns: function () {
      return [
        {
          text: this.$t('dialogueOrder'),
          value: 'dialogueOrder',
          filterable: false,
          sortable: false,
        },
        {
          text: this.$t('dialogueName'),
          value: 'dialogueName',
          filterable: true,
        },
      ];
    },
    allDialogueColumns: function () {
      return [
        {
          text: 'dialogueName',
          value: 'dialogueName',
          filterable: true,
        },
      ];
    },
    activityAlertFields() {
      if (this.formData.monitoringType === null) {
        return null;
      }

      return this.cachedAlertFieldsByMonitoringType.find((x) => x.id === this.formData.monitoringType)?.fields ?? null;
    },
    computedMaxWidth() {
      if (this.selectedActivityTab === 0) {
        return this.activityId != null ? '1200px' : '1000px';
      } else {
        return '1200px';
      }
    },
    alertGridSizes() {
      let nbItemsVisible =
        (this.activityAlertFields?.monitoringValue ? 1 : 0) +
        (this.activityAlertFields?.type ? 1 : 0) +
        (this.activityAlertFields?.unitType ? 1 : 0) +
        (this.activityAlertFields?.lengthType ? 1 : 0);

      let values = {};
      switch (nbItemsVisible) {
        case 4:
          values = {
            cols: 12,
            md: 6,
            lg: 3,
          };
          break;
        case 3:
          values = {
            cols: 12,
            md: 4,
            lg: 4,
          };
          break;
        case 2:
          values = {
            cols: 12,
            md: 6,
            lg: 6,
          };
          break;
        default:
          values = {
            cols: 12,
          };
          break;
      }

      let sizes = {
        monitoringValue: values,
        type: values,
        threshold: values,
        length: values,
        recipients: {
          cols: 12,
          md: 12,
          lg: 12,
        },
      };
      return sizes;
    },
    alertsTabVisible() {
      return this.formData.monitoringType && !this.hasDialogues && this.formData.iotDeviceType;
    },
    dialoguesTabVisible() {
      return this.hasDialogues;
    },
    triggersTabVisible() {
      return this.hasDialogues;
    },
    vmTriggersTabVisible() {
      return this.formData.vocalMessagesSelected?.length > 0;
    },
    alertsDialogueTabVisible() {
      return this.formData.monitoringType && this.hasDialogues;
    },
  },

  watch: {
    show: function () {
      if (this.show) {
        this.init();
        this.getAllSelects();
        this.getAvailableDialoguesList();
        if (this.activityId != null) {
          this.editClicked();
        }
      }
    },
    'formData.vocalMessagesSelected': function () {
      if (this.formData.vocalMessagesSelected?.length === 0) {
        this.formData.vocalMessagesTriggers = [];
      }
    },
    'formData.monitoringType': function () {
      if (this.formData.monitoringType !== null) {
        this.getElementsOnMonitoringType();
      }
    },
    'formData.iotDeviceType': function () {
      if (this.formData.iotDeviceType) {
        this.getVocalMessageOnIotType();
      }

      if (this.iotDeviceContinous) {
        this.formData.continous = true;
      }
      if (this.hasDialogues) {
        this.formData.alerts = [];
      }
    },
    activityAlertFields() {
      if (this.activityAlertFields) {
        this.formData.alerts.filter((x) => !x.initialized).forEach((alert) => this.setAlertDefaultValues(alert));
      }
    },
  },

  methods: {
    init: function () {
      this.title = this.$t('createNewActivity');
      this.selectedActivityTab = 0;
      this.parameters = null;
      this.IOTTypes = [];
      this.allDialogues = [];
      this.allDialoguesOriginal = [];
      this.vocalMessages = [];
      this.allVocalMessages = [];
      this.formData = {
        name: '',
        iotDeviceType: null,
        monitoringType: null,
        description: '',
        normative: false,
        active: false,
        continous: false,
        alerts: [],
        dialogues: [],
        triggers: [],
        vocalMessagesSelected: [],
        vocalMessagesTriggers: [],
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
      };

      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
    },

    editClicked: function () {
      this.title = this.$t('editActivity');
      this.showWaitModal = true;

      let successCallback = function (response) {
        this.showWaitModal = false;
        let activity = response.data;

        this.formData.name = activity.name;
        this.formData.description = activity.description;
        this.formData.normative = activity.normative;
        this.formData.active = activity.active;
        this.formData.continous = activity.continous;
        this.formData.monitoringType = activity.monitoringType.id;
        this.formData.iotDeviceType = activity.iotDeviceType.id;
        this.formData.createdDate = activity.created_at;
        this.formData.createdBy = activity.created_by.firstName + ' ' + activity.created_by.lastName;
        this.formData.modifiedDate = activity.updated_at;
        this.formData.modifiedBy = activity.updated_by
          ? activity.updated_by.firstName + ' ' + activity.updated_by.lastName
          : ' ';
        this.formData.vocalMessagesSelected =
          activity.vocalMessages?.map((vocalMessagesActivity) => vocalMessagesActivity.id) ?? [];

        this.formData.vocalMessagesTriggers = [];

        activity.vocalMessages?.forEach((vm) => {
          if (vm.vocalMessagesTriggers?.length > 0) {
            vm.vocalMessagesTriggers?.forEach((vmt) => {
              this.formData.vocalMessagesTriggers.push({
                startTimeUneditable: true,
                vocalMessageId: vm.id,
                triggers: {
                  startTime: vmt.startTime,
                },
              });
            });
          }
        });

        if (this.formData.normative) {
          this.allDialogues = this.allDialogues.filter((dialogue) => dialogue.dialogueNormative);
        }

        this.formData.dialogues =
          activity.dialogues?.map((x) => {
            return {
              dialogueId: x.id,
              dialogueName: x.dialogueName,
              dialogueOrder: x.dialogueOrder,
              dialogueNormative: x.dialogueNormative,
            };
          }) ?? [];

        this.formData.triggers =
          activity.triggers
            ?.map((x) => {
              return {
                startTime: x.startTime,
                repeatMinute: x.repeatMinute,
                repeatCount: x.repeatCount,
              };
            })
            .sort((a, b) => a.startTime.localeCompare(b.startTime)) ?? [];

        this.formData.alerts =
          activity.parameters.alerts?.map((x) => {
            return {
              internalId: this.getNextInternalAlertId(),
              alertId: x.alertId ?? uuidv4(),
              initialized: true,
              monitoringValue: x.monitoringValue ?? null,
              type: x.type ?? null,
              threshold: x.threshold ?? null,
              lengthType: x.lengthType ?? null,
              length: x.length ?? null,
              unitType: x.unitType ?? null,
              dialogueId: x.dialogueId ?? null,
              recipients: x.recipients ?? [],
            };
          }) ?? [];
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      };

      this.getActivityById(this.activityId, successCallback, this.defaultErrorCallBack);
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
      this.showWaitModal = false;
    },

    createOrUpdateAction: function () {
      let isActivityFormValid = this.$refs.activityForm.validate();

      let isActivityAlertFormValid =
        !this.alertsTabVisible || !this.$refs.activityAlertForm || this.$refs.activityAlertForm.validate();

      let isTriggerFormValid = !this.triggersTabVisible || !this.$refs.triggerForm || this.$refs.triggerForm.validate();

      let isAlertDialogueFormValid =
        !this.dialoguesTabVisible ||
        !this.$refs.configurationAlertDialogueForm ||
        this.$refs.configurationAlertDialogueForm.validate();

      let isVocalMessagesTriggersFormValid =
        !this.vmTriggersTabVisible ||
        !this.$refs.vocalMessagesTriggersForm ||
        this.$refs.vocalMessagesTriggersForm.validate();

      if (!isActivityFormValid) {
        this.selectedActivityTab = 0;
        return;
      }

      if (!isActivityAlertFormValid) {
        this.selectedActivityTab = 1;
        return;
      }

      if (!isTriggerFormValid) {
        this.selectedActivityTab = 3;
        return;
      }

      if (!isAlertDialogueFormValid) {
        this.selectedActivityTab = 4;
        return;
      }

      if (!isVocalMessagesTriggersFormValid) {
        this.selectedActivityTab = 5;
        return;
      }

      this.showWaitModal = true;

      var successCallback = function () {
        this.closeDialog();
        this.$emit('refresh');
        this.showWaitModal = false;
      };

      let alertsToSave = this.formData.alerts.map((x) => {
        let alert = {};
        if (!this.hasDialogues) {
          alert.monitoringValue = x.monitoringValue;
        }

        if (x.alertId != null) {
          alert.alertId = x.alertId;
        }

        if (x.type !== null) {
          alert.type = x.type;
        }

        if (x.threshold !== null) {
          alert.threshold = x.threshold;
        }

        if (x.lengthType !== null) {
          alert.lengthType = x.lengthType;
        }

        if (x.length !== null) {
          alert.length = x.length;
        }

        if (x.unitType !== null) {
          alert.unitType = x.unitType;
        }

        if (x.dialogueId !== null) {
          alert.dialogueId = x.dialogueId;
        }

        if (this.activityAlertFields.recipients?.length > 0) {
          alert.recipients = x.recipients;
        }

        return alert;
      });

      if (!this.hasDialogues) {
        this.formData.dialogues = [];
        this.formData.triggers = [];
      }

      this.parameters = {
        alerts: alertsToSave,
      };

      let data = {
        name: this.formData.name,
        iotDeviceTypeId: this.formData.iotDeviceType,
        monitoringTypeId: this.formData.monitoringType,
        description: this.formData.description,
        active: this.formData.active,
        continous: this.formData.continous,
        normative: this.formData.normative,
        dialogues: this.formData.dialogues,
        triggers: this.formData.triggers,
        parameters: this.parameters,
        vocalMessages: this.formData.vocalMessagesSelected.map((vocalMessageId) => {
          let vmTriggers = this.formData.vocalMessagesTriggers
            .filter((y) => vocalMessageId === y.vocalMessageId)
            .map((x) => x.triggers);

          let distinctTimes = Array.from(new Set(vmTriggers.map((x) => x.startTime)));
          return {
            vocalMessageId: vocalMessageId,
            triggers: distinctTimes.map((x) => {
              return { startTime: x };
            }),
          };
        }),
      };

      if (this.activityId == null) {
        this.createActivity(data, successCallback, this.defaultErrorCallBack);
      } else {
        this.updateActivity(this.activityId, data, successCallback, this.defaultErrorCallBack);
      }
    },

    async getVocalMessageOnIotType() {
      try {
        let iotType = this.formData.iotDeviceType;
        let vocalMessages = await vocalMessageService.getVocalMessagesByIotType(this.formData.iotDeviceType);

        if (iotType === this.formData.iotDeviceType) {
          this.allVocalMessages = vocalMessages;
          if (this.formData.normative) {
            this.vocalMessages = vocalMessages.filter((vm) => vm.normative);
          } else {
            this.vocalMessages = vocalMessages;
          }
        }
      } catch (error) {
        this.error = error;
      }
    },

    addAlert() {
      let newAlert = {
        // the internalId is only used for the key :attribute in the template
        internalId: this.getNextInternalAlertId(),

        // indicates wheather the default values has been set in this alert
        initialized: false,

        alertId: uuidv4(),
        monitoringValue: null,
        type: null,
        threshold: null,
        lengthType: null,
        length: null,
        unitType: null,
        dialogueId: null,
        recipients: [],
      };
      this.setAlertDefaultValues(newAlert);
      this.formData.alerts.push(newAlert);
    },

    addTrigger() {
      let newTrigger = {
        startTime: '00:00:00',
        repeatMinute: null,
        repeatCount: null,
      };

      this.formData.triggers.push(newTrigger);
    },

    addVocalMessageTrigger() {
      let trigger = {
        vocalMessageId: null,
        triggers: {
          startTime: '00:00:00',
        },
      };

      this.formData.vocalMessagesTriggers.push(trigger);
    },

    deleteAlert(alert) {
      let indexToRemove = this.formData.alerts.indexOf(alert);
      this.formData.alerts.splice(indexToRemove, 1);
    },

    deleteTrigger(trigger) {
      let indexToRemove = this.formData.triggers.indexOf(trigger);
      this.formData.triggers.splice(indexToRemove, 1);
    },

    deleteVocalMessageTrigger(vocalMessage) {
      let indexToRemove = this.formData.vocalMessagesTriggers?.indexOf(vocalMessage);
      this.formData.vocalMessagesTriggers?.splice(indexToRemove, 1);
    },

    getAvailableDialoguesList: function () {
      const DialoguesSuccess = function (response) {
        this.showWaitModal = false;
        let dialogues = response.data;

        this.allDialogues = dialogues.map((item) => {
          return {
            dialogueId: item.id,
            dialogueName: item.name,
            dialogueNormative: item.normative,
          };
        });

        this.allDialoguesOriginal = this.allDialogues;
      };

      this.getAvailableDialogues(DialoguesSuccess, this.defaultErrorCallBack);
    },

    getAllSelects: function () {
      const successMonitoringTypes = function (response) {
        this.monitoringTypes = response.data.map((eachMonitoringType) => eachMonitoringType);
      };

      this.getMonitoringTypes(successMonitoringTypes);
    },

    async getElementsOnMonitoringType() {
      let vm = this;

      const successActivityAlertFields = function (response, monitoringTypeId) {
        if (!vm.cachedAlertFieldsByMonitoringType.some((x) => x.id === monitoringTypeId)) {
          let getFieldValues = (items, key) => {
            if (!items) {
              return null;
            }
            return items.map((value) => {
              return {
                value: value,
                text: vm.$t(`${key}_${value}`),
              };
            });
          };

          let fields = {
            lengthType: getFieldValues(response.data.lengthType, 'lengthType'),
            monitoringValue: getFieldValues(response.data.monitoringValue, 'monitoringValue'),
            recipients: getFieldValues(response.data.recipients, 'recipients'),
            type: getFieldValues(response.data.type, 'type'),
            unitType: getFieldValues(response.data.unitType, 'unitType'),
            dialogueId: getFieldValues(response.data.dialogueId, 'dialogueId'),
          };

          vm.cachedAlertFieldsByMonitoringType.push({
            id: monitoringTypeId,
            fields: fields,
          });
        }
      };

      let iotTypes = await iotService.getIOTDeviceTypesByMonitoringType(this.formData.monitoringType);
      this.IOTTypes = iotTypes.map((eachIOTType) => eachIOTType);

      if (!vm.cachedAlertFieldsByMonitoringType.some((x) => x.id === this.formData.monitoringType)) {
        this.getActivityAlertFieldsByMonitoringType(this.formData.monitoringType, (response) =>
          successActivityAlertFields(response, this.formData.monitoringType)
        );
      }
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },

    getNextInternalAlertId() {
      if (!this.nextInternalAlertId) {
        this.nextInternalAlertId = 1;
      }
      return this.nextInternalAlertId++;
    },
    monitoringTypeChange(newValue) {
      this.oldMonitoringType = this.formData.monitoringType;
      this.formData.monitoringType = newValue;

      // If users has alerts we must clear alerts when we change monitoring type
      let hasAlerts =
        this.formData.alerts.length > 1 ||
        this.formData.alerts.some((x) => x.threshold !== null || x.length !== null || x.recipients?.length > 0);

      if (hasAlerts) {
        this.showMonitoringTypeChangeAlert = true;
        this.showConfirmationModalTitle = 'resetAlertsTitle';
        this.showConfirmationModalQuestion = 'resetAlertsPrompt';
      } else if ((this.formData.dialogues.length > 0) & (this.formData.triggers.length > 0)) {
        this.showConfirmationModalTitle = 'resetAlertsDialoguesTriggersTitle';
        this.showConfirmationModalQuestion = 'resetAlertsDialoguesTriggersPrompt';
        this.showMonitoringTypeChangeAlert = true;
      } else if (this.formData.dialogues.length > 0) {
        this.showConfirmationModalTitle = 'resetAlertsDialoguesTitle';
        this.showConfirmationModalQuestion = 'resetAlertsDialoguesPrompt';
        this.showMonitoringTypeChangeAlert = true;
      } else if (this.formData.triggers.length > 0) {
        this.showConfirmationModalTitle = 'resetAlertsTriggersTitle';
        this.showConfirmationModalQuestion = 'resetAlertsTriggersPrompt';
        this.showMonitoringTypeChangeAlert = true;
      } else {
        // we reset the dialogues and triggers
        this.formData.dialogues = [];
        this.formData.triggers = [];
        this.formData.iotDeviceType = null;
        // we reset the alerts in order to update their default values
        this.formData.alerts = [];
        if (!this.hasDialogues) {
          this.addAlert();
        }
      }
    },
    acceptConfirmationModal(showMonitoringAlert, showNormativeAlert) {
      if (showMonitoringAlert) {
        this.showMonitoringTypeChangeAlert = false;
        this.oldMonitoringType = null;
        this.formData.iotDeviceType = null;
        this.formData.alerts = [];
        this.formData.dialogues = [];
        this.formData.triggers = [];
        if (!this.hasDialogues) {
          this.addAlert();
        }
      } else if (showNormativeAlert) {
        this.resetNormativeVocalMessagesDialogues();
      }
    },

    cancelConfirmationModal(showMonitoringAlert, showNormativeAlert) {
      if (showMonitoringAlert) {
        this.showMonitoringTypeChangeAlert = false;
        this.formData.monitoringType = this.oldMonitoringType;
      } else if (showNormativeAlert) {
        this.showNormativeAlert = false;
        this.formData.normative = false;
      }
    },

    checkNonNormativeStuff() {
      if (this.formData.normative) {
        let nonNormativeDialoguesSelected = this.formData.dialogues.some((dialogue) => !dialogue.dialogueNormative);

        let nonNormativeVMSelected = this.formData.vocalMessagesSelected.some((vmId) =>
          this.vocalMessages.some((vm) => !vm.normative && vmId === vm.id)
        );

        if (nonNormativeVMSelected || nonNormativeDialoguesSelected) {
          this.showConfirmationModalQuestion = 'changeNormativeDialogueVMQuestion';
          this.showConfirmationModalTitle = 'changeNormativeDialogueVMTitle';
          this.showNormativeAlert = true;
        } else {
          this.resetNormativeVocalMessagesDialogues();
        }
      } else {
        this.vocalMessages = this.allVocalMessages;
        this.allDialogues = this.allDialoguesOriginal;
      }
    },

    resetNormativeVocalMessagesDialogues() {
      this.formData.vocalMessagesSelected = this.formData.vocalMessagesSelected.filter((vmId) =>
        this.vocalMessages.some((vm) => vm.id === vmId && vm.normative)
      );
      this.formData.dialogues = this.formData.dialogues.filter((dialogue) => dialogue.dialogueNormative);

      this.formData.alerts = this.formData.alerts.filter((alert) =>
        this.allDialogues.some((dialogue) => alert.dialogueId === dialogue.dialogueId && dialogue.dialogueNormative)
      );

      this.formData.vocalMessagesTriggers = this.formData.vocalMessagesTriggers.filter((vmt) =>
        this.vocalMessages.some((vm) => vm.id === vmt.vocalMessageId && vm.normative)
      );

      this.vocalMessages = this.vocalMessages.filter((vm) => vm.normative);
      this.allDialogues = this.allDialogues.filter((dialogue) => dialogue.dialogueNormative);

      this.showNormativeAlert = false;
    },

    setAlertDefaultValues(alert) {
      if (this.activityAlertFields) {
        if (this.activityAlertFields.monitoringValue?.length === 1) {
          alert.monitoringValue = this.activityAlertFields.monitoringValue[0].value;
        }
        if (this.activityAlertFields.type?.length === 1) {
          alert.type = this.activityAlertFields.type[0].value;
        }

        // Since there's no dropdown to select the unitType / lengthType (it's inline in the unit / length v-text),
        // we automatically select the first one (otherwise the UI would be too confusing)
        if (this.activityAlertFields.unitType?.length > 0) {
          alert.unitType = this.activityAlertFields.unitType[0].value;
        }
        if (this.activityAlertFields.lengthType?.length > 0) {
          alert.lengthType = this.activityAlertFields.lengthType[0].value;
        }

        alert.initialized = true;
      }
    },
    getSuffix(value, items) {
      if (value !== null && items) {
        return items?.find((x) => x.value === value)?.text;
      }
    },
    toggleLengthType(alert) {
      let idx = this.activityAlertFields.lengthType.findIndex((x) => x.value === alert.lengthType) + 1;
      if (idx === this.activityAlertFields.lengthType.length) {
        idx = 0;
      }
      alert.lengthType = this.activityAlertFields.lengthType[idx].value;
    },
    toggleUnitType(alert) {
      let idx = this.activityAlertFields.unitType.findIndex((x) => x.value === alert.unitType) + 1;
      if (idx === this.activityAlertFields.unitType.length) {
        idx = 0;
      }
      alert.unitType = this.activityAlertFields.unitType[idx].value;

      if (alert.unitType === 'percentageVariation' && alert.type !== 'variation') {
        alert.unitType = this.activityAlertFields.unitType[0].value;
      }
    },

    changeAlertType(alert) {
      if (alert.unitType === 'percentageVariation' && alert.type !== 'variation') {
        alert.unitType = this.activityAlertFields.unitType[0].value;
      }
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
  },
};
</script>

<style scoped>
.activity-tabs.v-tabs-items {
  overflow: visible;
}

.align-suffix.v-input :deep(.v-text-field__suffix) {
  transition: margin-top 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.align-suffix.v-input :deep(.v-input__append-inner) {
  transition: margin-top 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.align-suffix.v-input:not(.v-input--is-focused):not(.v-input--is-dirty) :deep(.v-text-field__suffix) {
  margin-top: 0px !important;
}
.align-suffix.v-input:not(.v-input--is-focused):not(.v-input--is-dirty) :deep(.v-input__append-inner) {
  margin-top: 15px !important;
}
.align-suffix.v-input.v-input--is-focused :deep(.v-input__append-inner) {
  margin-top: 25px !important;
}
.align-suffix.v-input.v-input--is-dirty :deep(.v-input__append-inner) {
  margin-top: 25px !important;
}
</style>
