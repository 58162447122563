<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent :max-width="dialogMaxWidth">
      <v-card>
        <ModalTitle :title="title" @btn-clicked="closeDialog(false)" />

        <WaitModal :show="showWaitModal" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <v-row>
            <v-col cols="12" :md="protocolId != null ? 6 : 12">
              <v-text-field
                ref="protocolName"
                v-model="formData.name"
                filled
                :label="$t('name')"
                v-bind="getRequiredBindObject(false)"
              />

              <v-textarea
                ref="description"
                v-model="formData.description"
                height="135"
                :label="$t('description')"
                filled
                v-bind="getRequiredBindObject(false)"
              />

              <v-row>
                <v-col cols="auto">
                  <v-checkbox ref="checkActive" v-model="formData.active" :label="$t('active')" />
                </v-col>
                <v-col cols="auto">
                  <v-checkbox
                    ref="checkNormative"
                    v-model="formData.normative"
                    :label="$t('normative')"
                    :disabled="!$can(permissions.actions.manage, permissions.subjects.normative)"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="protocolId != null" cols="12" md="6">
              <detail-field
                v-if="protocolId != null"
                :id="getIdByName('createdDate')"
                ref="createdDate"
                v-model="formData.createdDate"
                :label="$t('createdDate')"
              />

              <detail-field
                v-if="protocolId != null"
                :id="getIdByName('createdBy')"
                ref="createdBy"
                v-model="formData.createdBy"
                :label="$t('createdBy')"
              />

              <detail-field
                v-if="protocolId != null"
                :id="getIdByName('modifiedDate')"
                ref="modifiedDate"
                v-model="formData.modifiedDate"
                :label="$t('modifiedDate')"
              />

              <detail-field
                v-if="protocolId != null"
                :id="getIdByName('modifiedBy')"
                ref="modifiedBy"
                v-model="formData.modifiedBy"
                :label="$t('modifiedBy')"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn :id="getIdByName('save')" ref="save" text color="primary" @click.stop="createOrUpdateAction()">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import permissionMixin from '@/auth/permissionMixin';
import virtuoseMixin from '@/virtuoseMixin';

export default {
  name: 'ProtocolModal',

  components: {},
  mixins: [translation, accessibility, virtuoseMixin, permissionMixin],

  props: {
    protocolId: {
      type: Number,
      required: false,
      default: null,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      showWaitModal: false,
      title: '',
      formData: {
        name: '',
        description: '',
        normative: false,
        active: false,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
      },
      originalFormData: {},
    };
  },
  computed: {
    dialogMaxWidth: function () {
      return this.protocolId !== null ? '1200px' : '800px';
    },
  },

  watch: {
    show: function () {
      if (this.show) {
        if (this.protocolId != null) {
          this.editClicked();
        } else {
          this.init();
        }
      }
    },
  },
  methods: {
    init: function () {
      this.title = this.$t('createNewProtocol');
      this.formData = {
        name: '',
        description: '',
        normative: false,
        active: false,
        createdDate: null,
        createdBy: null,
        modifiedDate: null,
        modifiedBy: null,
      };
      this.originalFormData = JSON.parse(JSON.stringify(this.formData));
    },

    editClicked: function () {
      this.title = this.$t('editProtocol');

      let successCallback = function (response) {
        this.showWaitModal = false;

        let protocol = response.data;
        this.formData.name = protocol.name;
        this.formData.description = protocol.description;
        this.formData.normative = protocol.normative;
        this.formData.active = protocol.active;
        this.formData.createdDate = protocol.createdAt;
        this.formData.createdBy =
          protocol.createdBy != null ? protocol.createdBy.firstName + ' ' + protocol.createdBy.lastName : '';
        this.formData.modifiedDate = protocol.updatedAt;
        this.formData.modifiedBy =
          protocol.updatedBy != null ? protocol.updatedBy.firstName + ' ' + protocol.updatedBy.lastName : '';
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      };

      this.getProtocolById(this.protocolId, successCallback, this.defaultErrorCallBack);
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
      this.showWaitModal = false;
    },

    createOrUpdateAction: function () {
      var isValid = this.validateNotEmptyParameters();

      if (isValid) {
        this.showWaitModal = true;

        var successCallback = function () {
          this.closeDialog();
          this.$emit('refresh');
          this.showWaitModal = false;
        };

        var data = {
          name: this.formData.name,
          description: this.formData.description,
          active: this.formData.active,
          normative: this.formData.normative,
        };

        if (this.protocolId == null) {
          this.createProtocol(data, successCallback, this.defaultErrorCallBack);
        } else {
          this.updateProtocol(this.protocolId, data, successCallback, this.defaultErrorCallBack);
        }
      }
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
  },
};
</script>

<style scoped>
.protocol-tabs.v-tabs-items {
  overflow: visible;
}
</style>
