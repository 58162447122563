<template>
  <v-container>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <v-row align="center">
      <v-col>
        <PageTitle :title="$t('patientVitalSigns')" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row align="start">
          <v-col align-self="start" cols="12" sm="3">
            <v-select
              :id="getIdByName('statusLevels')"
              ref="statusLevels"
              clearable
              multiple
              :label="$t('statusLevels')"
              :items="monitoringValues"
              item-text="name"
              item-value="id"
              outlined
              small-chips
              deletable-chips
              :no-data-text="$t('noDataAvailable')"
              :hide-details="$vuetify.breakpoint.xs"
            />
          </v-col>
          <v-col align-self="start" :cols="$vuetify.breakpoint.xs ? 12 : false">
            <v-select
              :id="getIdByName('cohortMonitoring')"
              ref="cohortMonitoring"
              clearable
              multiple
              :label="$t('cohortMonitoring')"
              :items="cohorts"
              item-text="name"
              item-value="id"
              outlined
              small-chips
              deletable-chips
              :no-data-text="$t('noDataAvailable')"
              :hide-details="$vuetify.breakpoint.xs"
            />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.xs ? 12 : 'auto'">
            <div class="d-flex flex-row">
              <v-btn ref="apply" color="primary" :text="!$vuetify.breakpoint.xs" class="mt-2 ml-auto">
                <v-icon>mdi-filter</v-icon>{{ $t('apply') }}</v-btn
              >
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <iframe :src="leomedUrl" frameborder="0" width="100%" class="iframe-height" title="Vital Signs Data"> </iframe
        ></v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import virtuoseMixin from '@/virtuoseMixin';
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import cohortsService from '@/services/cohortsService';
import applicationService from '@/services/applicationService';
export default {
  name: 'PatientVitalSigns',
  mixins: [translationMixin, virtuoseMixin, accessibility],
  data() {
    return {
      error: null,
      cohorts: [],
      monitoringValues: [],
      leomedUrl: '',
    };
  },
  async created() {
    const success = function (response) {
      this.monitoringValues = response.data;
    };
    this.getStatusLevels(success, this.defaultErrorCallBack);
    try {
      this.cohorts = await cohortsService.getCohorts();
    } catch (error) {
      this.error = error;
    }
    let demoFeatureConfig = await applicationService.getDemoFeatureConfig();
    this.leomedUrl = demoFeatureConfig.leomedUrl;
  },
};
</script>
<style scoped>
.iframe-height {
  height: calc(100vh - 250px);
}
</style>
