import capacitorMixin from '@/CapaHttp';

export default {
  mixins: [capacitorMixin],
  data: function () {
    return {};
  },

  created: function () {
    this.virtuoseHandle = this.getCapaHttp('api');
  },

  methods: {
    getResidents: function (successCallBack, errorCallback) {
      var url = 'patients';
      this.virtuoseHandle.get(url, successCallBack, errorCallback);
    },

    getResidentById: function (patientId, successCallBack, errorCallback) {
      var url = 'patient/' + patientId;
      this.virtuoseHandle.get(url, successCallBack, errorCallback);
    },

    deleteResident: function (id, successCallBack, errorCallback) {
      var url = 'patient/' + id + '/delete/';
      this.virtuoseHandle.delete(url, successCallBack, errorCallback);
    },

    getPatientDialoguesResults: function (data, successCallBack, errorCallback) {
      var url = 'patientdialogueresults/';
      this.virtuoseHandle.post(url, data, successCallBack, errorCallback);
    },

    createActivity: function (data, successCallBack, errorCallback) {
      var url = 'activity/';
      this.virtuoseHandle.post(url, data, successCallBack, errorCallback);
    },

    updateActivity: function (id, data, successCallBack, errorCallback) {
      var url = 'activity/' + id + '/';
      this.virtuoseHandle.put(url, data, successCallBack, errorCallback);
    },

    getActivities: function (successCallBack, errorCallback) {
      var url = 'activities';
      this.virtuoseHandle.get(url, successCallBack, errorCallback);
    },

    getActivityById: function (id, successCallBack, errorCallback) {
      var url = 'activity/' + id;
      this.virtuoseHandle.get(url, successCallBack, errorCallback);
    },

    deleteActivity: function (id, successCallBack, errorCallback) {
      var url = 'activity/' + id + '/delete/';
      this.virtuoseHandle.delete(url, successCallBack, errorCallback);
    },

    getAvailableDialogues: function (successCallBack, errorCallback) {
      var url = 'availabledialogues';
      this.virtuoseHandle.get(url, successCallBack, errorCallback);
    },

    getUserGridConfiguration: function (gridName, successCallBack, errorCallback) {
      var url = 'usergridconfiguration/' + gridName;
      this.virtuoseHandle.get(url, successCallBack, errorCallback);
    },

    updateUserGridConfiguration: function (gridName, data, successCallBack, errorCallback) {
      var url = 'usergridconfiguration/' + gridName + '/';
      this.virtuoseHandle.put(url, data, successCallBack, errorCallback);
    },

    getPatientMonitoringSummary: function (data, successCallBack, errorCallback) {
      var url = 'patientmonitoringsummary/';
      this.virtuoseHandle.post(url, data, successCallBack, errorCallback);
    },

    getStatusLevels: function (successCallBack, errorCallback) {
      var url = 'statuslevels';
      this.virtuoseHandle.get(url, successCallBack, errorCallback);
    },

    getMonitoringTypes: function (successCallBack, errorCallback) {
      var url = 'monitoringtypes';
      this.virtuoseHandle.get(url, successCallBack, errorCallback);
    },

    getActivityAlertFieldsByMonitoringType: function (id, successCallBack, errorCallback) {
      var url = 'activityalertfields/' + id;
      this.virtuoseHandle.get(url, successCallBack, errorCallback);
    },

    getMessages: function (patientId, successCallBack, errorCallback) {
      var url = 'messages/' + patientId;
      this.virtuoseHandle.get(url, successCallBack, errorCallback);
    },

    getMessageById: function (id, successCallBack, errorCallback) {
      var url = 'message/' + id;
      this.virtuoseHandle.get(url, successCallBack, errorCallback);
    },

    getProtocols: function (data, successCallBack, errorCallback) {
      var url = 'protocols';
      this.virtuoseHandle.get(url, data, successCallBack, errorCallback);
    },

    deleteProtocol: function (id, successCallBack, errorCallback) {
      var url = 'protocol/' + id + '/delete/';
      this.virtuoseHandle.delete(url, successCallBack, errorCallback);
    },

    getProtocolById: function (id, successCallBack, errorCallback) {
      var url = 'protocol/' + id;
      this.virtuoseHandle.get(url, successCallBack, errorCallback);
    },

    createProtocol: function (data, successCallBack, errorCallback) {
      var url = 'protocol/';
      this.virtuoseHandle.post(url, data, successCallBack, errorCallback);
    },

    updateProtocol: function (id, data, successCallBack, errorCallback) {
      var url = 'protocol/' + id + '/';
      this.virtuoseHandle.put(url, data, successCallBack, errorCallback);
    },

    updateProtocolActivity: function (id, data, successCallBack, errorCallback) {
      var url = 'protocolactivity/' + id + '/';
      this.virtuoseHandle.put(url, data, successCallBack, errorCallback);
    },

    getProtocolActivities: function (protocolId, successCallBack, errorCallback) {
      var url = 'protocolactivities/' + protocolId;
      this.virtuoseHandle.get(url, successCallBack, errorCallback);
    },
  },
};
