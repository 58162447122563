import Plugin0 from '@/components/Notification/translation.js';
import Plugin1 from '@/components/User/translation.js';
import Plugin2 from '@/components/Resident/translation.js';
import Plugin3 from '@/components/Protocol/translation.js';
import Plugin4 from '@/components/MonitoringTypes/translation.js';
import Plugin5 from '@/components/HealthcareProfessional/translation.js';
import Plugin6 from '@/components/Activity/translation.js';
import Plugin7 from '@/components/VocalMessages/translation.js';
import Plugin8 from '@/components/Dialogue/translation.js';
import Plugin9 from '@/components/Iot/translation.js';
import Plugin10 from '@/components/Cohorts/translation.js';

export default function getTranslations(userLang, key) {
if(Plugin0[userLang] && Plugin0[userLang].hasOwnProperty(key)) return Plugin0[userLang][key];
if(Plugin1[userLang] && Plugin1[userLang].hasOwnProperty(key)) return Plugin1[userLang][key];
if(Plugin2[userLang] && Plugin2[userLang].hasOwnProperty(key)) return Plugin2[userLang][key];
if(Plugin3[userLang] && Plugin3[userLang].hasOwnProperty(key)) return Plugin3[userLang][key];
if(Plugin4[userLang] && Plugin4[userLang].hasOwnProperty(key)) return Plugin4[userLang][key];
if(Plugin5[userLang] && Plugin5[userLang].hasOwnProperty(key)) return Plugin5[userLang][key];
if(Plugin6[userLang] && Plugin6[userLang].hasOwnProperty(key)) return Plugin6[userLang][key];
if(Plugin7[userLang] && Plugin7[userLang].hasOwnProperty(key)) return Plugin7[userLang][key];
if(Plugin8[userLang] && Plugin8[userLang].hasOwnProperty(key)) return Plugin8[userLang][key];
if(Plugin9[userLang] && Plugin9[userLang].hasOwnProperty(key)) return Plugin9[userLang][key];
if(Plugin10[userLang] && Plugin10[userLang].hasOwnProperty(key)) return Plugin10[userLang][key];

return null;}
