<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog
      v-if="show"
      ref="dialogRef"
      value="show"
      scrollable
      persistent
      :max-width="vocalMessageId !== null ? '1000px' : '800px'"
    >
      <v-card>
        <ModalTitle :title="title" />

        <WaitModal :show="showWaitModal" />

        <v-card-text>
          <v-form ref="vocalMessageModalForm">
            <v-row class="mt-3">
              <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : undefined">
                <v-text-field
                  :id="getIdByName('vocalMessageName')"
                  ref="vocalMessageName"
                  v-model="formData.name"
                  :label="$t('vocalMessageName')"
                  filled
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />

                <v-textarea
                  ref="description"
                  v-model="formData.description"
                  height="135"
                  :label="$t('description')"
                  filled
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />

                <v-autocomplete
                  ref="iotDeviceType"
                  v-model="formData.iotDeviceTypeId"
                  :items="IOTDevicesTypes"
                  :label="$t('iotDeviceType')"
                  item-text="name"
                  item-value="id"
                  filled
                  :no-data-text="$t('noDataAvailable')"
                  class="required-indicator"
                  :rules="[validationRules.required]"
                />

                <v-row>
                  <v-col cols="auto">
                    <v-checkbox ref="checkActive" v-model="formData.active" :label="$t('active')" />
                  </v-col>
                  <v-col cols="auto">
                    <v-checkbox
                      ref="checkNormative"
                      v-model="formData.normative"
                      :label="$t('normative')"
                      :disabled="!$can(permissions.actions.manage, permissions.subjects.normative)"
                    />
                  </v-col>
                </v-row>
              </v-col>

              <v-col v-if="vocalMessageId !== null" :cols="$vuetify.breakpoint.smAndDown ? 12 : 'auto'">
                <detail-field
                  v-if="vocalMessageId !== null"
                  :id="getIdByName('createdDate')"
                  ref="createdDate"
                  v-model="formData.createdDate"
                  :label="$t('createdDate')"
                />

                <detail-field
                  v-if="vocalMessageId !== null"
                  :id="getIdByName('createdBy')"
                  ref="createdBy"
                  v-model="formData.createdBy"
                  :label="$t('createdBy')"
                />

                <detail-field
                  v-if="vocalMessageId !== null"
                  :id="getIdByName('modifiedDate')"
                  ref="modifiedDate"
                  v-model="formData.modifiedDate"
                  :label="$t('modifiedDate')"
                />

                <detail-field
                  v-if="vocalMessageId !== null"
                  :id="getIdByName('modifiedBy')"
                  ref="modifiedBy"
                  v-model="formData.modifiedBy"
                  :label="$t('modifiedBy')"
                />
              </v-col>
            </v-row>

            <v-col>
              <v-row v-for="(eachMessage, index) in formData.parameters.messages" :key="index">
                <v-textarea
                  ref="messageContent"
                  v-model="eachMessage.value"
                  rows="2"
                  :background-color="!eachMessage.value ? 'rgba(251, 140, 0, 0.3)' : undefined"
                  auto-grow
                  filled
                  :maxlength="200"
                  :label="`${$t('vocalMessage')} - ${$t(displayLanguage[index])}`"
                  :class="eachMessage.language === 'fr' && 'required-indicator'"
                  :rules="eachMessage.language === 'fr' ? [validationRules.required] : []"
                />
              </v-row>
            </v-col>

            <RequiredFieldsLegend />
            <v-row dense>
              <v-col>
                <div class="d-flex align-baseline">
                  <div class="translation-legend-icon"></div>
                  <div class="text-caption ml-2">{{ $t('translationMissingLegend') }}</div>
                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            v-if="showWaitModal == false"
            :id="getIdByName('save')"
            ref="save"
            text
            color="primary"
            @click.stop="createOrUpdateAction()"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import validationRulesMixin from '@/validationRulesMixin';
import permissionMixin from '@/auth/permissionMixin';
import ModificationWarnModal from '@/components/ModificationWarnModal.vue';
import vocalMessageService from '@/services/vocalMessageService';
import iotService from '@/services/iotService';
import CommonService from '@/services/commonService';

export default {
  name: 'VocalMessageModal',

  components: {
    ModificationWarnModal,
  },
  mixins: [translationMixin, accessibility, validationRulesMixin, permissionMixin],

  props: {
    vocalMessageId: {
      type: Number,
      required: false,
      default: null,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      title: '',
      showWaitModal: false,
      formData: {
        name: '',
        description: '',
        active: false,
        normative: false,
        iotDeviceTypeId: null,
        parameters: { messages: [] },
        createdDate: '',
        createdBy: '',
        modifiedDate: '',
        modifiedBy: '',
      },
      originalFormData: {},
      IOTDevicesTypes: [],
      languages: [],
      displayLanguage: [],
    };
  },

  watch: {
    show: function () {
      if (this.show) {
        this.init();
      }
    },
  },

  methods: {
    async init() {
      this.title = this.$t('createVocalMessage');
      this.displayLanguage = [];

      this.formData = {
        name: '',
        description: '',
        iotDeviceTypeId: null,
        active: !this.vocalMessageId && true,
        normative: false,
        parameters: {
          messages: [],
        },
        createdDate: '',
        createdBy: '',
        modifiedDate: '',
        modifiedBy: '',
      };

      this.originalFormData = JSON.parse(JSON.stringify(this.formData));

      try {
        let [IOTDevicesTypes, languages] = await Promise.all([
          await iotService.getIotDeviceTypes(),
          await CommonService.getLanguages(),
        ]);

        this.IOTDevicesTypes = IOTDevicesTypes;
        this.languages = languages;
      } catch (error) {
        this.error = error;
      }

      if (this.vocalMessageId) {
        this.editClicked();
        return;
      }

      this.languages.forEach((language) => {
        this.displayLanguage.push(language.name);

        this.formData.parameters.messages.push({
          language: language.code,
          value: '',
        });
      });
    },

    async editClicked() {
      this.title = this.$t('editVocalMessage');
      this.formData.parameters.messages = [];
      this.showWaitModal = true;

      try {
        let vocalMessage = await vocalMessageService.getVocalMessageById(this.vocalMessageId);

        this.formData.name = vocalMessage.name;
        this.formData.description = vocalMessage.description;
        this.formData.active = vocalMessage.active;
        this.formData.normative = vocalMessage.normative;
        this.formData.iotDeviceTypeId = vocalMessage.iotDeviceType.id;
        this.formData.createdBy = vocalMessage.createdBy.firstName + ' ' + vocalMessage.createdBy.lastName;
        this.formData.createdDate = vocalMessage.createdAt;
        this.formData.modifiedBy = vocalMessage.updatedBy
          ? vocalMessage.updatedBy.firstName + ' ' + vocalMessage.updatedBy.lastName
          : '';
        this.formData.modifiedDate = vocalMessage.updatedAt ? vocalMessage.updatedAt : '';

        this.languages.forEach((language) => {
          let sameLanguageMessage = vocalMessage.parameters.messages.find(
            (message) => message['language'] === language.code
          );

          this.displayLanguage.push(language.name);

          this.formData.parameters.messages.push({
            language: sameLanguageMessage?.language ?? language.code,
            value: sameLanguageMessage?.value ?? '',
          });
        });

        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    async createOrUpdateAction() {
      let isValid = this.$refs.vocalMessageModalForm.validate();

      if (!isValid) return;

      this.showWaitModal = true;

      try {
        let data = {
          id: this.vocalMessageId,
          name: this.formData.name,
          description: this.formData.description,
          active: this.formData.active,
          normative: this.formData.normative,
          parameters: this.formData.parameters,
          iotDeviceTypeId: this.formData.iotDeviceTypeId,
        };

        if (!this.vocalMessageId) {
          await vocalMessageService.createVocalMessage(data);
        } else {
          await vocalMessageService.updateVocalMessage(this.vocalMessageId, data);
        }

        this.closeDialog();
        this.$emit('refresh');
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },

    closeDialog: function () {
      this.$emit('update:show', false);
    },
  },
};
</script>

<style scoped>
.translation-legend-icon {
  background: #fedcb2;
  border: #dc5f26 solid 1px;
  height: 12px;
  width: 12px;
}
</style>
