<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />
    <virtual-assistant
      :activity-id="virtualAssistantActivityId"
      :patient-id="patientId"
      :visible.sync="showVirtualAssistant"
    ></virtual-assistant>
    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent :max-width="'1800px'">
      <v-card>
        <ModalTitle :title="$t('patientActivitiesProtocols')" />

        <WaitModal :show="showWaitModal" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <PatientAssignDetails ref="patientDetails" class="px-6 mb-4" :patient-info="patientInfo" />

        <v-tabs v-model="selectedActivityTab" class="px-6" background-color="transparent">
          <v-tab> {{ $t('activity') }} </v-tab>
          <v-tab> {{ $t('threshold') }} </v-tab>
        </v-tabs>

        <v-card-text>
          <v-tabs-items v-model="selectedActivityTab" class="overflow-visible">
            <v-tab-item :value="0" class="pt-0">
              <v-form ref="patientAssignForm">
                <div>
                  <div class="">
                    <v-radio-group ref="allRadioButtons" v-model="selectedCategory" row>
                      <v-radio ref="allActAndProtocols" :label="$t('allActAndProtocols')" value="all"></v-radio>
                      <v-radio ref="activitiesButton" :label="$t('activities')" value="activities"></v-radio>
                      <v-radio ref="protocolsButton" :label="$t('protocols')" value="protocols"></v-radio>
                    </v-radio-group>
                  </div>
                  <list-item-selector
                    :columns-assigned="columnsAssigned"
                    :columns-available="columnsAvailable"
                    :all-items="allItems"
                    item-key="uniqueId"
                    :assigned-items="assignedItems"
                    :available-title="$t('availableActivities')"
                    :assigned-title="$t('currentPatientActivities')"
                    :show-expand="true"
                    @update:assigned-selected="assignedSelectionChange"
                    @update:assignedItems="assignedItemsChange"
                    @available-item-expanded="itemExpanded"
                    @assigned-item-expanded="itemExpanded"
                  >
                    <template #expanded-item="{ item }">
                      <td class="pa-4" :colspan="columnsAssigned.length">
                        <template v-if="item.type === 'protocol'">
                          <div class="text--secondary">{{ $t('activitiesInProtocol') }}</div>
                          <div v-for="activity in item.assignedProtocolActivities" :key="activity.id">
                            {{ activity.name }}

                            <span v-if="checkActivityProtocolAlreadyAssigned(activity)" class="ml-1">
                              <v-menu offset-y>
                                <template #activator="{ on: onMenu }">
                                  <v-tooltip bottom>
                                    <template #activator="{ on: onTooltip, attrs }">
                                      <v-btn
                                        ref="dialogueButtonTest"
                                        icon
                                        v-bind="attrs"
                                        v-on="{ ...onMenu, ...onTooltip }"
                                      >
                                        <v-icon>mdi-play</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>{{ $t('testDialoguesActivity') }}</span>
                                  </v-tooltip>
                                </template>
                                <v-list>
                                  <v-list-item @click="testDialogueActivity(activity.id)">
                                    <v-list-item-title>Assistant vocal</v-list-item-title>
                                  </v-list-item>
                                  <v-list-item @click="doShowVirtualAssistant(activity.id)">
                                    <v-list-item-title>Virtuose assistant virtuel</v-list-item-title>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                            </span>
                          </div>
                        </template>
                      </td>
                    </template>
                    <template #availableItem.data-table-expand="{ expand, item, isExpanded }">
                      <div v-if="item.type === 'protocol'">
                        <v-icon
                          ref="iconItemExpanded"
                          class="v-data-table__expand-icon"
                          :class="{ 'v-data-table__expand-icon--active': isExpanded }"
                          @click="expand(!isExpanded)"
                          >mdi-chevron-down</v-icon
                        >
                      </div>
                    </template>

                    <template #assignedItem.dialoguesTest="{ item }">
                      <div v-if="checkActivityAlreadyAssigned(item)">
                        <v-menu offset-y>
                          <template #activator="{ on: onMenu }">
                            <v-tooltip bottom>
                              <template #activator="{ on: onTooltip, attrs }">
                                <v-btn ref="dialogueButtonTest" icon v-bind="attrs" v-on="{ ...onMenu, ...onTooltip }">
                                  <v-icon>mdi-play</v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('testDialoguesActivity') }}</span>
                            </v-tooltip>
                          </template>
                          <v-list>
                            <v-list-item @click="testDialogueActivity(item.id)">
                              <v-list-item-title>{{ $t('vocalAssistant') }}</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="doShowVirtualAssistant(item.id)">
                              <v-list-item-title>{{ $t('virtualAssistant') }}</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </template>

                    <template #assignedItem.data-table-expand="{ expand, item, isExpanded }">
                      <div v-if="item.type === 'protocol'">
                        <v-icon
                          ref="iconItemExpanded"
                          class="v-data-table__expand-icon"
                          :class="{ 'v-data-table__expand-icon--active': isExpanded }"
                          @click="expand(!isExpanded)"
                          >mdi-chevron-down</v-icon
                        >
                      </div>
                    </template>

                    <template #assignedItem.startDate="{ item }">
                      <v-edit-dialog :return-value.sync="item.startDate">
                        <DateTimePickerField
                          ref="startDate"
                          v-model="item.startDate"
                          :label="$t('startDate')"
                          :max-date="item.endDate"
                          picker-type="dateTime"
                          single-line
                          required
                        ></DateTimePickerField>
                      </v-edit-dialog>
                    </template>
                    <template #assignedItem.endDate="{ item }">
                      <v-edit-dialog :return-value.sync="item.endDate">
                        <DateTimePickerField
                          v-model="item.endDate"
                          :label="$t('endDate')"
                          :min-date="item.startDate"
                          single-line
                          picker-type="dateTime"
                        ></DateTimePickerField>
                      </v-edit-dialog>
                    </template>
                  </list-item-selector>
                </div>
              </v-form>
            </v-tab-item>

            <v-tab-item :value="1" eager class="pt-4">
              <v-card>
                <v-row class="pa-4" justify="space-between">
                  <v-col cols="5">
                    <v-treeview
                      ref="treeview"
                      :items="formData.activitiesForThreshold"
                      :active.sync="active"
                      :open.sync="open"
                      color="warning"
                      activatable
                      return-object
                    >
                    </v-treeview>
                  </v-col>

                  <v-divider vertical></v-divider>

                  <v-col class="d-flex text-center">
                    <v-scroll-y-transition mode="out-in">
                      <div
                        v-if="!selected || !activityAlertFields"
                        ref="noSelection"
                        class="text-h6 grey--text text--lighten-1 font-weight-light"
                        style="align-self: center"
                      >
                        {{ $t('selectToCustomize') }}
                      </div>

                      <v-card
                        v-else-if="!canCustomizeMonitoringType(selected.monitoringType)"
                        class="pt-6 mx-auto"
                        flat
                        max-width="1000"
                      >
                        <div class="text-h6 grey--text text--lighten-1 font-weight-light" style="align-self: center">
                          {{ $t('noCustomization') }}
                        </div>
                      </v-card>

                      <v-card
                        v-else
                        :key="selected.id"
                        ref="selectedActivity"
                        class="pt-6 mx-auto"
                        flat
                        max-width="1000"
                      >
                        <v-checkbox
                          v-model="selected.overriden"
                          :label="labelActivityValue"
                          hide-details
                          class="shrink mr-2 mt-0"
                        />
                        <v-card-text>
                          <h3 class="text-h5 pb-6 mb-2">
                            {{ selected.monitoringType.name }}
                          </h3>
                          <div
                            v-for="(alert, index) in selected.overriden
                              ? selected.overridenParameters.alerts
                              : selected.defaultParameters.alerts"
                            :key="index"
                          >
                            <v-row>
                              <v-col cols="auto">
                                <div class="pt-6">
                                  <span class="font-weight-bold">{{ index + 1 }}.</span>
                                </div>
                              </v-col>
                              <v-col>
                                <v-row dense>
                                  <v-col>
                                    <detail-field
                                      ref="parametersType"
                                      :value="$t(`monitoringValue_${alert.monitoringValue}`)"
                                      :label="$t('parametersType')"
                                      readonly
                                      v-bind="getRequiredBindObject(false)"
                                    />
                                  </v-col>
                                  <v-col>
                                    <detail-field
                                      ref="alertType"
                                      :value="$t(`type_${alert.type}`)"
                                      :label="$t('alertType')"
                                      readonly
                                      v-bind="getRequiredBindObject(false)"
                                    />
                                  </v-col>
                                  <v-col>
                                    <v-text-field
                                      ref="thresholdText"
                                      v-model.number="alert.threshold"
                                      :disabled="!selected.overriden"
                                      filled
                                      :label="$t('thresholdText')"
                                      class="align-suffix"
                                      v-bind="getRequiredBindObject(false)"
                                      type="number"
                                      hide-spin-buttons
                                      max="200"
                                      :suffix="getSuffix(alert.unitType, activityAlertFields.unitType)"
                                      :append-icon="
                                        activityAlertFields.unitType.length > 1 ? 'mdi-autorenew' : undefined
                                      "
                                      @click:append="toggleUnitType(alert)"
                                    />
                                  </v-col>
                                  <v-col>
                                    <v-text-field
                                      ref="dateLimitText"
                                      v-model.number="alert.length"
                                      :disabled="!selected.overriden"
                                      type="number"
                                      v-bind="getRequiredBindObject(false)"
                                      filled
                                      hide-spin-buttons
                                      :label="$t('dateLimitText')"
                                      :append-icon="
                                        activityAlertFields.lengthType.length > 1 ? 'mdi-autorenew' : undefined
                                      "
                                      class="align-suffix"
                                      max="31"
                                      :suffix="getSuffix(alert.lengthType, activityAlertFields.lengthType)"
                                      @click:append="toggleLengthType(alert)"
                                    >
                                    </v-text-field>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-scroll-y-transition>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            :id="getIdByName('save')"
            ref="save"
            text
            color="primary"
            @click.stop="savePatientActivitiesAndProtocols()"
          >
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import virtuoseMixin from '@/virtuoseMixin';
import PatientAssignDetails from '@/components/Resident/PatientAssignDetails';
import ListItemSelector from '@/components/ListItemsSelector.vue';
import DateTimePickerField from '../DateTimePickerField.vue';
import protocolsAndActivitiesService from '@/services/protocolsAndActivitiesService';
import VirtualAssistant from '@/components/Dialogue/VirtualAssistant.vue';
import { ActivityTypes } from '@/components/Resident/constants';

export default {
  name: 'PatientActivityAssign',

  components: {
    PatientAssignDetails,
    ListItemSelector,
    DateTimePickerField,
    VirtualAssistant,
  },
  mixins: [translation, accessibility, virtuoseMixin],

  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },

    patientInfo: {
      type: Object,
      required: false,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      defaultPatientId: null,

      selectedActivityTab: 0,
      active: [],
      open: [],
      alerts: [],

      monitoringTypes: [],
      cachedAlertFieldsByMonitoringType: [],

      loadedPatientId: null,

      error: null,
      showWaitModal: false,

      formData: {
        assignedActivities: [],
        assignedProtocols: [],
        activitiesForThreshold: [],
      },
      originalFormData: {},

      allActivities: [],
      selectedActivity: null,

      allProtocols: [],
      selectedProtocol: null,

      selectedCategory: 'all',
      showVirtualAssistant: false,
      virtualAssistantActivityId: null,
    };
  },
  computed: {
    columnsAssigned: function () {
      return [
        {
          text: 'activitiesName',
          value: 'name',
          filterable: true,
        },
        {
          text: 'activitiesDescription',
          value: 'description',
          filterable: true,
        },
        {
          text: 'startDate',
          value: 'startDate',
          filterable: true,
          width: '300px',
        },
        {
          text: 'endDate',
          value: 'endDate',
          width: '300px',
          filterable: true,
        },
        {
          value: 'data-table-expand',
          filterable: false,
          sortable: false,
        },
        {
          value: 'dialoguesTest',
          filterable: false,
          sortable: false,
        },
      ];
    },

    columnsAvailable: function () {
      return [
        {
          text: 'activitiesName',
          value: 'name',
          filterable: true,
        },
        {
          text: 'activitiesDescription',
          value: 'description',
          filterable: true,
        },
        {
          value: 'data-table-expand',
          filterable: false,
          sortable: false,
        },
        {
          value: 'dialoguesTest',
          filterable: false,
          sortable: false,
        },
      ];
    },

    allItems: function () {
      switch (this.selectedCategory) {
        case 'protocols':
          return this.allProtocols;

        case 'activities':
          return this.allActivities;

        case 'all':
          return [...this.allActivities, ...this.allProtocols];

        default:
          return [];
      }
    },

    assignedItems: function () {
      switch (this.selectedCategory) {
        case 'protocols':
          return this.formData.assignedProtocols;

        case 'activities':
          return this.formData.assignedActivities;

        case 'all':
          return [...this.formData.assignedActivities, ...this.formData.assignedProtocols];

        default:
          return [];
      }
    },

    labelActivityValue() {
      return this.selected?.overriden ? this.$t('uncheckToReset') : this.$t('checkToCustomize');
    },

    selected() {
      if (!this.active.length) return undefined;
      return this.active[0];
    },

    activityAlertFields() {
      let monitoringType = this.selected?.monitoringType?.id;
      if ((monitoringType ?? null) === null) {
        return null;
      }

      return this.cachedAlertFieldsByMonitoringType.find((x) => x.id === monitoringType)?.fields ?? null;
    },

    computedPatientId: function () {
      return this.patientId || this.defaultPatientId;
    },
  },

  watch: {
    patientId: function () {
      this.init();
    },
    show() {
      if (this.show) {
        this.init();
      } else {
        this.loadedPatientId = null;
      }
    },
    'selected.monitoringType.id': function (newValue) {
      if ((newValue ?? null) !== null) {
        this.getElementsOnMonitoringType();
      }
    },
    activityAlertFields() {
      if (this.activityAlertFields) {
        this.alerts.filter((x) => !x.initialized).forEach((alert) => this.setAlertDefaultValues(alert));
      }
    },
  },
  methods: {
    init: function () {
      if (!this.patientId || !this.show || this.loadedPatientId === this.patientId) {
        return;
      }
      this.selectedActivityTab = 0;

      this.loadedPatientId = this.patientId;
      this.showWaitModal = true;
      this.selectedCategory = 'all';
      this.formData.assignedActivities = [];
      this.formData.assignedProtocols = [];
      this.active = [];
      this.formData.activitiesForThreshold = [];
      this.loadAvailableActivities();
      this.loadAvailableProtocols();
    },

    async loadAvailableActivities() {
      try {
        const allActivitiesResponse = await protocolsAndActivitiesService.getActivities();
        this.allActivities = allActivitiesResponse.map((item) => {
          return {
            ...item,
            uniqueId: 'activity' + item.id,
            type: 'activity',
          };
        });
        this.loadPatientActivities();
      } catch (error) {
        this.defaultErrorCallBack(error);
      }
      this.showWaitModal = false;
    },

    async loadAvailableProtocols() {
      try {
        const allProtocolsResponse = await protocolsAndActivitiesService.getProtocols();
        this.allProtocols = allProtocolsResponse.map((item) => {
          return {
            ...item,
            uniqueId: 'protocol' + item.id,
            type: 'protocol',
            assignedProtocolActivities: [],
          };
        });
        this.loadPatientProtocols();
      } catch (error) {
        this.defaultErrorCallBack(error);
      }
    },

    isThereActivity: function (activity) {
      if (!this.formData.activitiesForThreshold.some((x) => x.activityId === activity.activityId)) {
        let defaultParameters,
          overriden = false,
          overridenParameters;
        const foundActivity = this.allActivities.find((x) => x.id === activity.activityId);
        if (foundActivity) {
          defaultParameters = JSON.parse(JSON.stringify(foundActivity.parameters));
          if (!activity.parameters) {
            overriden = false;
            overridenParameters = foundActivity.parameters;
          } else {
            overriden = true;
            overridenParameters = activity.parameters;
          }

          this.formData.activitiesForThreshold.push({
            id: activity.activityId,
            activityId: activity.activityId,
            name: foundActivity.name,
            monitoringType: foundActivity.monitoringType,
            defaultParameters: defaultParameters,
            overridenParameters: overridenParameters,
            overriden: overriden,
          });
        }
      }
    },

    handleActivity: function (activity) {
      this.isThereActivity({
        id: activity.id,
        activityId: activity.id,
        name: activity.name,
        monitoringType: activity.monitoringType,
      });
    },

    async loadPatientActivities() {
      try {
        const activitiesResponse = await protocolsAndActivitiesService.getPatientActivities(this.computedPatientId);

        this.formData.assignedActivities = this.allActivities.filter((availableItem) =>
          activitiesResponse.some(
            (assignedItem) => assignedItem.activityId === availableItem.id && !assignedItem.protocolActivityId
          )
        );
        activitiesResponse.forEach((assignedActivity) => {
          this.isThereActivity(assignedActivity);
        });
        this.formData.assignedActivities.forEach((activity) => {
          var patientActivity = activitiesResponse.find(
            (x) => x.activityId === activity.id && x.protocolActivityId === null
          );
          activity.startDate = patientActivity.startDate;
          activity.endDate = patientActivity.endDate;
          activity.patientId = patientActivity.patientId;
        });
        this.selectedActivity = null;
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      } catch (error) {
        this.defaultErrorCallBack(error);
      }
      this.showWaitModal = false;
    },

    async loadProtocolActivities(item) {
      try {
        const data = await protocolsAndActivitiesService.getProtocolActivities(item.id);
        item.assignedProtocolActivities = data;
      } catch (error) {
        this.defaultErrorCallBack(error);
      }
    },

    async loadPatientProtocols() {
      try {
        const protocolsResponse = await protocolsAndActivitiesService.getPatientProtocols(this.computedPatientId);

        this.formData.assignedProtocols = this.allProtocols.filter((availableItem) =>
          protocolsResponse.some((assignedItem) => assignedItem.protocolId === availableItem.id)
        );

        this.formData.assignedProtocols.forEach((element) => {
          let patientProtocol = protocolsResponse.find((x) => x.protocolId === element.id);

          element.startDate = patientProtocol.startDate;
          element.endDate = patientProtocol.endDate;
        });

        this.selectedProtocol = null;
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      } catch (error) {
        this.defaultErrorCallBack(error);
      }
      this.showWaitModal = false;
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
      this.showWaitModal = false;
    },

    closeDialog: function (refresh) {
      this.$emit('update:show', false);
      if (refresh) {
        this.$emit('saved');
      }
    },

    async savePatientActivitiesAndProtocols() {
      let isValid = this.$refs.patientAssignForm.validate();

      if (!isValid) return;

      if (
        this.formData.assignedActivities.some((activity) => !activity.startDate) ||
        this.formData.assignedProtocols.some((protocol) => !protocol.startDate)
      ) {
        this.selectedCategory = 'all';
        return;
      }

      try {
        var dataActivities = {
          patientActivities: this.formData.assignedActivities.map((activity) => {
            return {
              activity_id: activity.id,
              startDate: activity.startDate,
              endDate: activity.endDate,
            };
          }),
        };

        var dataThreshold = {
          activitiesForThreshold: this.formData.activitiesForThreshold
            .filter((x) => x.overriden)
            .map((x) => {
              return { id: x.activityId, parameters: x.overridenParameters };
            }),
        };

        var dataProtocols = {
          patientProtocols: this.formData.assignedProtocols.map((protocol) => {
            return { protocolId: protocol.id, startDate: protocol.startDate, endDate: protocol.endDate };
          }),
        };

        var allDataActivities = {
          patientActivities: [...dataActivities.patientActivities],
          activitiesForThreshold: dataThreshold.activitiesForThreshold,
          patientProtocols: dataProtocols.patientProtocols,
        };

        await protocolsAndActivitiesService.updatePatientActivitiesAndProtocols(this.patientId, allDataActivities);

        this.closeDialog(true);
        this.$emit('refresh');
      } catch (error) {
        this.defaultErrorCallBack(error);
      }
      this.showWaitModal = false;
    },

    async testDialogueActivity(activityId) {
      let data = {
        activityId: activityId,
      };
      try {
        this.showWaitModal = true;
        await protocolsAndActivitiesService.testCodeExecutorForPatient(this.patientId, data);
      } catch (error) {
        this.error = error;
      }
      this.showWaitModal = false;
    },

    assignedSelectionChange: function (selection) {
      if (selection?.length === 1) {
        this.selectedActivity = selection[0];
      } else {
        this.selectedActivity = null;
      }
    },

    async assignedItemsChange(items) {
      let protocols = items.filter((x) => x.type === 'protocol');
      let activitiesIds = new Set();

      for (let protocol of protocols) {
        if (protocol.assignedProtocolActivities.length === 0) {
          try {
            let data = await protocolsAndActivitiesService.getProtocolActivities(protocol.id);
            protocol.assignedProtocolActivities = data;
            for (let activity of protocol.assignedProtocolActivities) {
              this.handleActivity(activity);
              activitiesIds.add(activity.id);
            }
          } catch (error) {
            this.defaultErrorCallBack(error);
          }
        } else {
          protocol.assignedProtocolActivities.forEach((activity) => {
            this.handleActivity(activity);
            activitiesIds.add(activity.id);
          });
        }
      }

      let assignedActivities = items.filter((x) => x.type === 'activity');

      assignedActivities.forEach((activity) => {
        this.handleActivity(activity);
        activitiesIds.add(activity.id);
      });

      this.formData.activitiesForThreshold = this.formData.activitiesForThreshold.filter((x) =>
        activitiesIds.has(x.activityId)
      );

      if (this.selectedCategory !== 'protocols') {
        this.formData.assignedActivities = items.filter((x) => x.type === 'activity');
      }

      if (this.selectedCategory !== 'activities') {
        this.formData.assignedProtocols = items.filter((x) => x.type === 'protocol');
      }
    },

    async itemExpanded(data) {
      let item = data.item;
      if (data.value && data.item.type === 'protocol' && data.item.assignedProtocolActivities.length === 0) {
        this.loadProtocolActivities(item);
      }
    },

    canCustomizeMonitoringType(monitoringType) {
      const uncustomizable = [ActivityTypes.FDE, ActivityTypes.DLG, ActivityTypes.INC];
      return !uncustomizable.includes(monitoringType.activityType.code);
    },
    setAlertDefaultValues(alert) {
      if (this.activityAlertFields) {
        if (this.activityAlertFields.monitoringValue?.length === 1) {
          alert.monitoringValue = this.activityAlertFields.monitoringValue[0].value;
        }
        if (this.activityAlertFields.type?.length === 1) {
          alert.type = this.activityAlertFields.type[0].value;
        }

        // Since there's no dropdown to select the unitType / lengthType (it's inline in the unit / length v-text),
        // we automatically select the first one (otherwise the UI would be too confusing)
        if (this.activityAlertFields.unitType?.length > 0) {
          alert.unitType = this.activityAlertFields.unitType[0].value;
        }
        if (this.activityAlertFields.lengthType?.length > 0) {
          alert.lengthType = this.activityAlertFields.lengthType[0].value;
        }

        alert.initialized = true;
      }
    },

    checkActivityAlreadyAssigned: function (activity) {
      return (
        activity.type === 'activity' &&
        activity?.iotDeviceType?.code === 'vocalAssistant' &&
        (activity?.patientId === this.patientId ||
          this.originalFormData.activitiesForThreshold?.some(
            (assignedActivityThreshold) => assignedActivityThreshold.name === activity.name
          ))
      );
    },

    checkActivityProtocolAlreadyAssigned: function (activity) {
      return (
        activity.iotDeviceType.code === 'vocalAssistant' &&
        this.formData.assignedProtocols?.some((assignedProtocol) =>
          assignedProtocol?.assignedProtocolActivities?.some(
            (assignedProtocolActivity) => assignedProtocolActivity.id === activity.id
          )
        ) &&
        (this.originalFormData.activitiesForThreshold?.some(
          (assignedActivityThreshold) => assignedActivityThreshold.activityId === activity.id
        ) ||
          this.originalFormData.assignedActivities?.some((assignedActivity) => assignedActivity.id === activity.id))
      );
    },

    async getElementsOnMonitoringType() {
      let vm = this;

      const successActivityAlertFields = function (response, monitoringTypeId) {
        if (!vm.cachedAlertFieldsByMonitoringType.some((x) => x.id === monitoringTypeId)) {
          let getFieldValues = (items, key) => {
            if (!items) {
              return null;
            }
            return items.map((value) => {
              return {
                value: value,
                text: vm.$t(`${key}_${value}`),
              };
            });
          };

          let fields = {
            lengthType: getFieldValues(response.data.lengthType, 'lengthType'),
            monitoringValue: getFieldValues(response.data.monitoringValue, 'monitoringValue'),
            recipients: getFieldValues(response.data.recipients, 'recipients'),
            type: getFieldValues(response.data.type, 'type'),
            unitType: getFieldValues(response.data.unitType, 'unitType'),
          };

          vm.cachedAlertFieldsByMonitoringType.push({
            id: monitoringTypeId,
            fields: fields,
          });
        }
      };

      let monitoringType = this.selected?.monitoringType?.id;
      if (!vm.cachedAlertFieldsByMonitoringType.some((x) => x.id === monitoringType)) {
        this.getActivityAlertFieldsByMonitoringType(monitoringType, (response) =>
          successActivityAlertFields(response, monitoringType)
        );
      }
    },

    getSuffix(value, items) {
      if (value !== null && items) {
        return items?.find((x) => x.value === value)?.text;
      }
    },

    toggleLengthType(alert) {
      let idx = this.activityAlertFields.lengthType.findIndex((x) => x.value === alert.lengthType) + 1;
      if (idx === this.activityAlertFields.lengthType.length) {
        idx = 0;
      }

      alert.lengthType = this.activityAlertFields.lengthType[idx].value;
    },

    toggleUnitType(alert) {
      let idx = this.activityAlertFields.unitType.findIndex((x) => x.value === alert.unitType) + 1;
      if (idx === this.activityAlertFields.unitType.length) {
        idx = 0;
      }

      alert.unitType = this.activityAlertFields.unitType[idx].value;
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
    doShowVirtualAssistant(activityId) {
      this.virtualAssistantActivityId = activityId;
      this.showVirtualAssistant = true;
    },
  },
};
</script>

<style scoped></style>
