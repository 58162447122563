<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="page-title">
          {{ $t('administration') }}
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col> </v-col>
    </v-row>

    <object type="text/html" width="100%" height="2000px" data="/admin" />
  </v-container>
</template>

<script>
import translationMixin from '@/translationMixin';

export default {
  name: 'AdminHome',
  components: {},
  mixins: [translationMixin],
  data() {
    return {
      error: null,
    };
  },
  created: function () {
    this.init();
  },

  methods: {
    init: function () {},

    defaultErrorCallBack: function (error) {
      this.error = error;
    },
  },
};
</script>
<style scoped>
.tenant-name {
  font-weight: bold;
  font-size: 16px;
}

.fullsize {
  overflow: hidden;
  height: 100%;
}
</style>
