<template>
  <div ref="chartContainer" class="pa-4">
    <div ref="chartBody">
      <LineChart ref="lineChart" :chart-options="chartOptions" :chart-data="chartData" class="lineChart" />
    </div>
  </div>
</template>

<script>
import translationMixin from '@/translationMixin';

import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
  PointElement,
  TimeScale,
} from 'chart.js';
import { format } from 'date-fns';
import { Line as LineChart } from 'vue-chartjs/legacy';
import annotationPlugin from 'chartjs-plugin-annotation';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';
import { chartColor } from './chartColor';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  LineElement,
  LineController,
  PointElement,
  annotationPlugin,
  ChartDataLabels,
  zoomPlugin,
  TimeScale
);

export default {
  name: 'ChartDetails',
  components: {
    LineChart,
  },
  mixins: [translationMixin],
  props: {
    chartTitle: {
      type: String,
      default: '',
    },

    values: {
      type: Array,
      default: () => [],
    },

    yAxis: {
      type: Object,
      default() {
        return {
          Ymax: 150,
          Ymin: 0,
          YStepSize: 20,
          title: '',
        };
      },
    },

    series: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      chartData: {
        datasets: [],
      },
      chartOptions: {
        animation: false,
        spanGaps: true,
        elements: {
          point: {
            radius: 0,
          },
        },
        scales: {
          y: {
            title: {
              display: true,
            },
            min: this.yAxis.Ymin,
            max: this.yAxis.Ymax,
            ticks: {
              stepSize: this.yAxis.YStepSize,
            },
          },
          x: {
            type: 'time',
            time: {
              unit: 'hour',
            },
            min: 0,
            max: 0,
            ticks: { source: this.chartTitle === 'weightDetails' ? 'data' : 'auto' },
          },
          x2: {
            type: 'time',
            time: {
              unit: 'day',
              displayFormats: {
                day: 'yyyy-MM-dd',
              },
            },
            title: {
              display: true,
            },
            min: 0,
            max: 0,
            grid: {
              color: () => chartColor.delimiterBorderColor,
            },
          },
        },
        plugins: {
          tooltip: {
            titleAlign: 'center',
            callbacks: {
              title: (data) =>
                format(
                  new Date(data[0].raw.x),
                  this.getLanguage() === 'fr' ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd hh:mm:ss b'
                ),
            },
          },
          datalabels: {
            display: false,
          },
          legend: {
            onClick: () => null,
            title: {
              display: true,
            },
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              mode: 'x',
            },
            pan: {
              enabled: true,
              mode: 'x',
            },
          },
          autocolors: false,
          annotation: {
            annotations: {
              limit24h: {
                type: 'box',
                xMin: 0,
                xMax: 0,
                yMin: 0,
                yMax: 0,
                borderWidth: 1,
                borderColor: chartColor.delimiterBorderColor,
                backgroundColor: chartColor.delimiterBackgroundColor,
              },
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  watch: {
    values: function () {
      this.init();
    },
  },

  mounted() {
    this.init();
  },

  methods: {
    init: function () {
      this.setTextLanguage();

      if (!this.series) return;
      this.showChart();

      if (this.values?.length < 1) return;
      this.insertChartValues();
    },

    showChart: function () {
      this.chartData.datasets = [];

      this.chartOptions.scales.y.min = this.yAxis.Ymin;
      this.chartOptions.scales.y.max = this.yAxis.Ymax;
      this.chartOptions.scales.y.ticks.stepSize = this.yAxis.YStepSize;

      this.chartData.datasets = this.series.map((serie) => {
        return {
          label: this.$t(serie.title),
          borderWidth: 2,
          data: [],
          pointStyle: 'circle',
          pointHoverRadius: 5,
          datalabels:
            serie.title === 'weight'
              ? {
                  display: true,
                  formatter: (val) => val.y,
                  clip: true,
                  backgroundColor: (context) => context.dataset.backgroundColor,
                  borderRadius: 4,
                  color: chartColor.textLabel,
                  font: {
                    weight: 'bold',
                  },
                  padding: 2,
                }
              : null,
          backgroundColor: [],
          normalized: true,
          parsing: false,
          fill: true,
          segment: {
            borderColor: null,
          },
        };
      });

      let thresholds = this.series.map((serie, datasetIndex) => {
        return {
          label: this.$t(serie.thresholds.title),
          borderWidth: 1.5,
          borderColor: datasetIndex === 0 ? chartColor.mainBorderLegendColor : chartColor.correctSecondaryDataColor,
          backgroundColor: chartColor.backgroundThreshold,
          borderDash: [12, 5],
        };
      });

      this.chartData.datasets.push(...thresholds);

      for (let thresholdIndex = 0; thresholdIndex < this.series.length; thresholdIndex++) {
        if (this.series[thresholdIndex]?.thresholds.minThreshold) {
          this.chartOptions.plugins.annotation.annotations['limit' + thresholdIndex] = {
            type: 'line',
            mode: 'horizontal',
            borderDash: [13, 7],
            yMin: this.series[thresholdIndex].thresholds.minThreshold,
            yMax: this.series[thresholdIndex].thresholds.minThreshold,
            borderColor:
              thresholdIndex === 0 ? chartColor.mainBorderLegendColor : chartColor.secondaryBorderLegendColor,
            borderWidth: 1,
          };
        }

        if (this.series[thresholdIndex]?.thresholds.maxThreshold) {
          this.chartOptions.plugins.annotation.annotations['limit' + (thresholdIndex + 2)] = {
            type: 'line',
            mode: 'horizontal',
            borderDash: [13, 7],
            yMin: this.series[thresholdIndex].thresholds.maxThreshold,
            yMax: this.series[thresholdIndex].thresholds.maxThreshold,
            borderColor:
              thresholdIndex === 0 ? chartColor.mainBorderLegendColor : chartColor.secondaryBorderLegendColor,
            borderWidth: 1,
          };
        }
      }
    },

    insertChartValues: function () {
      this.values?.forEach((eachAlert) => {
        for (let alertIndex = 0; alertIndex < eachAlert?.alertValues?.length; alertIndex++) {
          this.chartData.datasets[alertIndex].data.push({
            x: new Date(eachAlert.alertDatetime).getTime(),
            y: eachAlert.alertValues[alertIndex],
          });

          if (
            eachAlert.alertValues[alertIndex] > this.series[alertIndex].thresholds.maxThreshold ||
            eachAlert.alertValues[alertIndex] < this.series[alertIndex].thresholds.minThreshold
          ) {
            this.chartData.datasets[alertIndex].backgroundColor.push(chartColor.incorrectDataColor);
          } else {
            this.chartData.datasets[alertIndex].backgroundColor.push(
              alertIndex === 0 ? chartColor.correctFirstDataColor : chartColor.correctSecondaryDataColor
            );
          }
        }
      });

      if (this.chartData.datasets[0]?.data[0]) {
        this.chartOptions.scales.x.min = this.addTime(this.chartData.datasets[0].data[0].x, -2);
        this.chartOptions.scales.x.max = this.addTime(this.chartData.datasets[0].data.at(-1).x, 2);
        this.chartOptions.scales.x2.min = this.addTime(this.chartData.datasets[0].data[0].x, -2);
        this.chartOptions.scales.x2.max = this.addTime(this.chartData.datasets[0].data.at(-1).x, 2);

        this.chartOptions.plugins.zoom.limits = {
          x: {
            min: new Date(this.chartOptions.scales.x.min).getTime(),
            max: new Date(this.chartOptions.scales.x.max).getTime(),
          },
        };
      }

      for (let datasetIndex = 0; datasetIndex < this.series.length; datasetIndex++) {
        this.chartData.datasets[datasetIndex].segment.borderColor = (ctx) => {
          let incorrectData =
            ctx.p0.raw?.y > this.series[datasetIndex].thresholds.maxThreshold ||
            ctx.p1.raw?.y > this.series[datasetIndex].thresholds.maxThreshold ||
            ctx.p0.raw?.y < this.series[datasetIndex].thresholds.minThreshold ||
            ctx.p1.raw?.y < this.series[datasetIndex].thresholds.minThreshold;

          return incorrectData
            ? chartColor.incorrectDataColor
            : datasetIndex === 0
            ? chartColor.correctFirstDataColor
            : chartColor.correctSecondaryDataColor;
        };
      }

      if (this.getLanguage() === 'fr') {
        this.chartOptions.scales.x.time.displayFormats = {
          hour: 'HH:mm',
        };
      } else {
        this.chartOptions.scales.x.time.displayFormats = {
          hour: 'h:mm b',
        };
      }

      if (this.values?.length > 1) {
        this.chartData.datasets.push({
          label: this.$t('delimiterLegend'),
          borderWidth: 1.5,
          backgroundColor: chartColor.delimiterBackgroundColor,
          borderColor: chartColor.delimiterBorderColor,
        });

        this.chartOptions.plugins.annotation.annotations.limit24h.xMin = this.get24hDate();
        this.chartOptions.plugins.annotation.annotations.limit24h.xMax = this.values.at(-1).alertDatetime;
        this.chartOptions.plugins.annotation.annotations.limit24h.yMax = this.yAxis.Ymax;
      } else {
        this.chartOptions.plugins.annotation.annotations.limit24h.xMin = 0;
        this.chartOptions.plugins.annotation.annotations.limit24h.xMax = 0;
      }
    },

    get24hDate: function () {
      let newDate = new Date();
      let date24H = new Date(newDate.setHours(newDate.getHours() - 24));

      return format(date24H, 'yyyy-MM-dd HH:mm:ss');
    },

    addTime: function (currentDate, hours) {
      let newDate = new Date(currentDate);
      let editedDate = new Date(newDate.setHours(newDate.getHours() + hours));

      return format(editedDate, 'yyyy-MM-dd HH:ss');
    },

    setTextLanguage: function () {
      this.chartOptions.scales.x2.title.text = this.$t('time');
      this.chartOptions.scales.y.title.text = this.$t(this.yAxis.title);
      this.chartOptions.plugins.legend.title.text = this.$t(this.chartTitle);
    },
  },
};
</script>

<style scoped>
.lineChart {
  height: 500px;
}
</style>
