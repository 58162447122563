<template>
  <v-dialog v-if="show" value="show" eager scrollable persistent max-width="400">
    <v-card>
      <ModalTitle :title="!!title ? title : $t('confirmation')" />

      <v-card-text>
        <v-row>
          <v-col>
            <span ref="question">{{ question }}</span>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-btn ref="cancel" text @click="cancel()">{{ $t('cancel') }}</v-btn>
        <v-btn ref="ok" text color="primary" @click="ok()">{{ !!title ? title : $t('yes') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import translation from '@/translationMixin';
export default {
  name: 'ConfirmationModal',
  mixins: [translation],
  props: {
    question: {
      type: String,
      required: true,
    },
    objectToConfirm: {
      type: Object,
      required: false,
      default: null,
    },
    show: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      description: '',
    };
  },

  created: function () {},

  methods: {
    cancel: function () {
      this.$emit('cancel', true);
    },
    ok: function () {
      this.$emit('ok', this.objectToConfirm);
    },
  },
};
</script>
