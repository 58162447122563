export default {
  en: {
    cohorts: 'Cohorts',
    assignCohort: 'Cohort Assignation',
    cohortName: 'Name',
    description: 'Description',
    autoUpdate: 'Automatic Update',
    createCohort: 'Add Cohort',
    editCohort: 'Modify Cohort',
    deleteCohortQuestion: 'Are you sure you want to delete this cohort?',

    patientCohorts: 'Assign Cohort(s)',

    currentPatientCohorts: 'Patient Cohort(s)',
    availableCohorts: 'Available Cohort(s)',

    addCohort: 'Add cohort',
    addPatientToCohort: 'Add patient to cohort',
    deleteCohortPatientQuestion: 'Are you sure you want to remove patient from the cohort?',
    editCohortPatientList: 'Edit cohort patient list',
    cohortPatients: 'Cohort patients',
    availablePatients: 'Available patients',
  },
  fr: {
    cohorts: 'Cohortes',
    assignCohort: 'Assignation cohorte',
    cohortName: 'Nom',
    description: 'Description',
    autoUpdate: 'MAJ automatique',
    createCohort: 'Ajouter une cohorte',
    editCohort: 'Modifier la cohorte',
    deleteCohortQuestion: 'Êtes-vous certain de vouloir supprimer cette cohorte?',

    patientCohorts: 'Assignation cohorte(s)',

    currentPatientCohorts: 'Cohorte(s) du patient',
    availableCohorts: 'Cohorte(s) disponible(s)',

    addCohort: 'Ajouter une cohorte',
    addPatientToCohort: 'Ajouter un patient à la cohorte',
    deleteCohortPatientQuestion: 'Êtes-vous certain de vouloir enlever ce patient de la cohorte?',
    editCohortPatientList: 'Modifier les patients de la cohorte',
    cohortPatients: 'Patients de la cohorte',
    availablePatients: 'Patients disponibles',
  },
};
