<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <WaitModal :show="showWaitModal" />
    <v-data-table
      :items="items"
      :expanded="items.filter((x) => x.statusLevelCode !== statusLevelsNO)"
      :headers="headers"
      :footer-props="footerProps"
      :items-per-page="25"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      item-key="alertDate"
      class="elevation-1 mt-4"
      mobile-breakpoint="0"
    >
      <template #item.statusLevelId="{ item }">
        <div>
          <v-icon :color="getIconColor(item.statusLevelCode)">
            {{ getIconName(item.statusLevelCode) }}
          </v-icon>
        </div>
      </template>

      <template #expanded-item="{ item }">
        <td :colspan="headers.length + 1">
          <div class="my-2 ml-10">
            <v-alert
              v-for="(child, index) in item.resultDetails"
              :key="index"
              :text="item.statusLevelCode !== statusLevelsNO"
              :color="getFDEAlertColor(item.statusLevelCode)"
              dense
              class="mb-0"
            >
              <div>
                <span>
                  <span class="d-inline text-body-2">{{ child.alertTime }}</span>
                </span>
                <div
                  v-if="child.alertDetails && item.statusLevelCode !== statusLevelsNO"
                  class="d-inline text-body-2 text--secondary"
                >
                  - {{ $t('fallDetected') }}
                  <span><v-icon>mdi-file-video-outline</v-icon></span>
                </div>
              </div>
            </v-alert>
          </div>
        </td>
      </template>
    </v-data-table>

    <div class="legend my-4">
      <div class="legend-section">
        <div class="font-weight-bold text--secondary text-body-2 mb-1">{{ $t('fdeValues') }}</div>
        <div class="legend-items-container">
          <div class="legend-item">
            <v-icon color="green" class="mr-2" small>mdi-check-circle</v-icon>
            <span class="text-body-2">{{ $t('noFallDetected') }}</span>
          </div>
          <div class="legend-item">
            <v-icon color="red" class="mr-2" small>mdi-alert-octagon</v-icon>
            <span class="text-body-2">{{ $t('fallDetected') }}</span>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import translationMixin from '@/translationMixin';
import patientMonitoringService from '@/services/patientMonitoringService';
import iconMixin from './iconMixin';
import { StatusLevels } from './constants';
import { ActivityTypes } from '@/components/Resident/constants';

export default {
  name: 'PatientFallDetection',
  mixins: [translationMixin, iconMixin],
  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },

    filters: {
      type: Object,
      required: false,
      default: null,
      validator(value) {
        return value == null || (typeof value === 'object' && !Array.isArray(value));
      },
    },
  },
  data: () => ({
    items: [],
    statusLevels: [],
    sortBy: 'alertDate',
    sortDesc: true,
    error: null,
    showWaitModal: false,
    statusLevelsNO: StatusLevels.NO,
  }),
  computed: {
    footerProps: function () {
      return {
        itemsPerPageOptions: [5, 10, 25, 50, -1],
        showFirstLastPage: true,
        'items-per-page-text': this.$t('rowsByPage'),
      };
    },
    headers() {
      return [
        {
          text: this.$t('statusLevelId'),
          textalign: 'center',
          value: 'statusLevelId',
          align: 'center',
          width: '95px',
          sortable: false,
        },
        {
          text: this.$t('alertDate'),
          value: 'alertDate',
          sortable: false,
          order: 'desc',
        },
      ];
    },
  },
  watch: {
    patientId: function () {
      this.getPatientFallDetectionData();
    },
    filters: function () {
      this.getPatientFallDetectionData();
    },
  },
  created: function () {
    this.init();
  },
  methods: {
    init: function () {
      this.getPatientFallDetectionData();
    },
    async getPatientFallDetectionData() {
      let patientInfo = {
        patientId: this.patientId,
        timeFilterType: this.filters?.timeFilterType ?? null,
        timeFilterValue: this.filters?.timeFilterValue ?? null,
        dateFrom: this.filters?.dateFrom ?? null,
        dateTo: this.filters?.dateTo ?? null,
        activityTypeCode: ActivityTypes.FDE,
      };

      try {
        const fdeResponse = await patientMonitoringService.getPatientFallDetection(patientInfo);

        this.statusLevels = fdeResponse.statusLevels;

        var statusLevelMap = this.statusLevels.reduce((map, level) => map.set(level.id, level.code), new Map());

        this.items = fdeResponse.results.map((item) => {
          return {
            ...item,
            statusLevelCode: statusLevelMap.get(item.statusLevelId),
          };
        });
      } catch (error) {
        this.error = error;
        this.showWaitModal = false;
      }
    },
  },
};
</script>

<style scoped>
.legend {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  column-gap: 80px;
  row-gap: 16px;
}
.legend-items-container {
  display: flex;
  list-style: none;
  flex-direction: row;
  align-items: baseline;
  column-gap: 24px;
}
</style>
