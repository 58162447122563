import ResidentHome from './ResidentHome';
import PatientMonitoringGrid from './PatientMonitoringGrid';

export default {
  moduleName: 'resident',
  routes: [
    {
      path: '/resident',
      name: 'ResidentHome',
      component: ResidentHome,
    },
    {
      path: '/patientmonitoring/:patientId?/:detailType?',
      name: 'PatientMonitoringGrid',
      component: PatientMonitoringGrid,
      props: (route) => {
        let props = {};

        if (route.params?.patientId) {
          props.patientId = Number.parseInt(route.params.patientId);
        }

        if (route.params?.detailType) {
          props.detailType = route.params.detailType;
        }

        return props;
      },
    },
  ],
};
