<template>
  <span>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <ModificationWarnModal :has-modifications="hasModifications" />

    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent :max-width="dialogMaxWidth">
      <v-card>
        <ModalTitle :title="$t('protocolActivities')" />

        <WaitModal :show="showWaitModal" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />

        <v-card-text>
          <div>
            <list-item-selector
              :columns-assigned="columns"
              :columns-available="columns"
              :all-items="allActivities"
              :assigned-items.sync="formData.assignedActivities"
              :available-title="$t('availableActivity')"
              :assigned-title="$t('currentProtocolActivity')"
            ></list-item-selector>
          </div>

          <v-row v-if="isProtocolNormative" class="justify-end">
            <v-col cols="auto">
              <div class="text-caption">{{ $t('onlyNormativeActivities') }}</div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="justify-end">
          <v-btn ref="cancel" text @click="closeDialog()">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn :id="getIdByName('save')" ref="save" text color="primary" @click.stop="updateProtocolActivities()">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import translation from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import virtuoseMixin from '@/virtuoseMixin';
import ListItemSelector from '@/components/ListItemsSelector.vue';

export default {
  name: 'ProtocolActivity',

  components: {
    ListItemSelector,
  },
  mixins: [translation, accessibility, virtuoseMixin],

  props: {
    protocolId: {
      type: Number,
      required: false,
      default: null,
    },

    isProtocolNormative: {
      type: Boolean,
      required: false,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      loadedProtocolId: null,

      error: null,
      showWaitModal: false,

      allActivities: [],
      formData: {
        assignedActivities: [],
      },
      originalFormData: {},
    };
  },
  computed: {
    columns: function () {
      return [
        {
          text: 'activityProtocolName',
          value: 'name',
          filterable: true,
        },
        {
          text: 'activityProtocolDescription',
          value: 'description',
          filterable: true,
        },
        {
          text: 'monitoringType',
          value: 'monitoringTypeName',
          filterable: true,
        },
        {
          text: 'iotDeviceType',
          value: 'iotDeviceTypeName',
          filterable: true,
        },
      ];
    },
    dialogMaxWidth: function () {
      return this.protocolId !== null ? '1200px' : '800px';
    },
  },

  watch: {
    protocolId: function () {
      this.init();
    },
    show() {
      if (this.show) {
        this.init();
      } else {
        this.loadedProtocolId = null;
      }
    },
  },
  methods: {
    init: function () {
      if (!this.protocolId || !this.show || this.loadedProtocolId === this.protocolId) {
        return;
      }

      this.loadedProtocolId = this.protocolId;
      this.showWaitModal = true;
      this.formData.assignedActivities = [];
      this.getAvailableActivities();
    },
    loadProtocolActivities: function () {
      this.showWaitModal = true;

      const activitiesSuccess = function (response) {
        this.showWaitModal = false;

        let assignedIds = response.data.map((x) => x.id);
        this.formData.assignedActivities = this.allActivities.filter((x) => assignedIds.includes(x.id));
        this.originalFormData = JSON.parse(JSON.stringify(this.formData));
      };
      this.getProtocolActivities(this.protocolId, activitiesSuccess, this.defaultErrorCallBack);
    },

    getAvailableActivities: function () {
      const activitiesSuccess = function (response) {
        this.showWaitModal = false;

        this.allActivities = response.data
          .filter((activity) => (this.isProtocolNormative ? activity.normative : activity))
          .map((item) => {
            return {
              id: item.id,
              name: item.name,
              description: item.description,
              monitoringTypeName: item.monitoringType.name,
              iotDeviceTypeName: item.iotDeviceType.name,
            };
          });
        if (this.protocolId != null) {
          this.loadProtocolActivities();
        }
      };

      this.getActivities(activitiesSuccess, this.defaultErrorCallBack);
    },

    defaultErrorCallBack: function (error) {
      this.error = error;
      this.showWaitModal = false;
    },

    closeDialog: function () {
      this.$emit('close');
    },

    updateProtocolActivities: function () {
      var successCallback = function () {
        this.closeDialog();
        this.$emit('refresh');
        this.showWaitModal = false;
      };

      var data = {
        activitiesIds: this.formData.assignedActivities.map((x) => x.id),
      };

      this.updateProtocolActivity(this.protocolId, data, successCallback, this.defaultErrorCallBack);
    },

    hasModifications: function () {
      if (this.show) {
        return JSON.stringify(this.formData) !== JSON.stringify(this.originalFormData);
      }
    },
  },
};
</script>

<style scoped></style>
