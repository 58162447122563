export default {
  data() {
    return {
      validationRules: {
        required: (value) =>
          (!isEmptyOrWhitespace(value) && !isEmptyArray(value)) || this.$t('validationRulesRequired'),

        email: (value) => isEmpty(value) || isValidEmail(value) || this.$t('validationRulesEmail'),
        atMost: (max) => (value) => {
          if (isEmpty(value)) {
            return true;
          }
          if (!isNumber(value)) {
            return this.$t('validationRulesGeneral');
          }
          return value <= max || this.$t('validationRulesAtMost').replace('{max}', max);
        },
        atLeast: (min) => (value) => {
          if (isEmpty(value)) {
            return true;
          }
          if (!isNumber(value)) {
            return this.$t('validationRulesGeneral');
          }
          return value >= min || this.$t('validationRulesAtLeast').replace('{min}', min);
        },
        inRange: (min, max) => (value) => {
          if (isEmpty(value)) {
            return true;
          }
          if (!isNumber(value)) {
            return this.$t('validationRulesGeneral');
          }
          return (
            (value >= min && value <= max) ||
            this.$t('validationRulesInRange').replace('{min}', min).replace('{max}', max)
          );
        },
      },
    };
  },
};

function isString(value) {
  return typeof value === 'string' || value instanceof String;
}

function isNumber(value) {
  return !Number.isNaN(Number.parseFloat(value));
}

function isValidEmail(value) {
  var regex = /(?!.*\.\.)^[\w][\w-.]+[\w]@([\w-]+\.)+[\w-]{2,6}$/;
  return regex.test(value);
}

function isEmpty(value) {
  return value === null || value === undefined || value === '';
}

function isEmptyOrWhitespace(value) {
  return value === null || value === undefined || (isString(value) && value.trim() === '');
}

function isEmptyArray(value) {
  return Array.isArray(value) && value.length === 0;
}
