<template>
  <span>
    <v-dialog v-if="show" ref="dialogRef" value="show" scrollable persistent max-width="800px">
      <v-card>
        <WaitModal :show="showWaitModal" />

        <UserError :error-array="requiredErrorMessages" @clearMsgs="clearErrorMessages" />
        <v-card-text class="mt-3">
          <v-row no-gutters>
            <v-col>
              <span class="text-h6 text--primary">{{ $t('senderMessage') }}</span>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <detail-field
                v-if="messageId != null"
                :id="getIdByName('senderLastName')"
                ref="senderLastName"
                :value="senderLastName"
                :label="$t('senderLastName')"
              />
              <detail-field
                v-if="messageId != null"
                :id="getIdByName('senderFirstName')"
                ref="senderFirstName"
                :value="senderFirstName"
                :label="$t('senderFirstName')"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <detail-field
                v-if="messageId != null"
                :id="getIdByName('messageDatetime')"
                ref="messageDatetime"
                :value="messageDatetime"
                :label="$t('messageDatetime')"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="message-content-background">
              <detail-field
                v-if="messageId != null"
                :id="getIdByName('messageContent')"
                ref="messageContent"
                :value="messageContent"
                readonly
                :label="$t('messageContent')"
                vertical
              />
            </v-col>
          </v-row>

          <v-row no-gutters class="mt-8">
            <v-col>
              <span class="text-h6 text--primary">{{ $t('recipientMessage') }}</span>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6">
              <detail-field
                v-if="messageId != null"
                :id="getIdByName('healthworker')"
                ref="healthworkerLastName"
                :value="healthworker.lastName"
                readonly
                :label="$t('lastName')"
              />
              <detail-field
                v-if="messageId != null"
                :id="getIdByName('healthworker')"
                ref="healthworkerFirstName"
                :value="healthworker.firstName"
                readonly
                :label="$t('firstName')"
              />
            </v-col>

            <v-col cols="12" sm="6">
              <detail-field
                v-if="messageId != null"
                :id="getIdByName('responseDatetime')"
                ref="responseDatetime"
                :value="responseDatetime"
                readonly
                :label="$t('responseDatetime')"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col class="message-content-background">
              <detail-field
                v-if="messageId != null"
                :id="getIdByName('responseContent')"
                ref="responseContent"
                class="message-area"
                :value="responseContent"
                :label="$t('responseContent')"
                vertical
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn ref="close" text @click="closeDialog">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import virtuoseMixin from '@/virtuoseMixin';
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
export default {
  name: 'ResidentMessageModal',

  components: {},
  mixins: [translationMixin, virtuoseMixin, accessibility],

  props: {
    messageId: {
      type: Number,
      required: false,
      default: null,
    },

    patientId: {
      type: Number,
      required: false,
      default: null,
    },

    show: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      error: null,
      title: '',
      showWaitModal: false,
      activeProximite: false,
      messageDatetime: null,
      messageContent: '',
      senderFirstName: '',
      senderLastName: '',
      responseDatetime: '',
      responseContent: null,
      healthworker: '',
    };
  },

  watch: {
    show: function () {
      if (this.messageId != null) {
        this.answerMessage();
      }
    },
  },

  methods: {
    answerMessage: function () {
      this.showWaitModal = true;

      var successCallback = function (response) {
        this.showWaitModal = false;
        var message = response.data;
        this.activeProximite = message.activeProximite;
        this.messageDatetime = message.messageDatetime;
        this.messageContent = message.messageContent;
        this.senderFirstName = message.senderFirstName;
        this.senderLastName = message.senderLastName;
        this.responseDatetime = message.responseDatetime;
        this.responseContent = message.responseContent;
        this.healthworker = message.healthworker;
      };

      this.getMessageById(this.messageId, successCallback);
    },

    closeDialog: function (refresh) {
      this.$emit('close', refresh);
    },
  },
};
</script>

<style scoped>
.message-content-background {
  background-color: #0000000a;
}
</style>
