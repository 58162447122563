<template>
  <div>{{ $t('notification_redirecting') }}</div>
</template>

<script>
import notificationService from '@/services/notificationService';
import translationMixin from '@/translationMixin';
import { NotificationTypes } from './constants';

export default {
  name: 'NotificationRedirect',
  mixins: [translationMixin],
  props: {
    notificationId: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      notification: null,
    };
  },
  created: function () {},
  mounted: async function () {
    await this.init();
  },
  methods: {
    init: async function () {
      this.notification = await notificationService.getNotificationById(this.notificationId);

      if (!this.notification) {
        this.$router.replace({ name: 'Home' });
        return;
      }

      if (this.notification.notificationType === NotificationTypes.ACT_ALERT) {
        let patientId = this.notification.data.patientId;
        let monitoringTypeId = this.notification.data.monitoringTypeId;
        this.$router.replace({
          name: 'PatientMonitoringGrid',
          params: { patientId, detailType: monitoringTypeId },
        });
      }

      notificationService.updateNotificationReadStatus(this.notificationId, { read: true });
    },
  },
};
</script>
