<template>
  <div>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <v-dialog v-model="showChat" max-width="600px">
      <v-card>
        <iframe v-if="showChat" allow="camera;microphone" style="height: 80vh; width: 100%" :src="chatUrl"> </iframe>
        <v-card-actions class="justify-end">
          <v-btn text @click="showChat = false">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showVideo">
      <v-sheet>
        <iframe v-if="showVideo" allow="camera;microphone" style="height: 80vh; width: 100%" :src="videoUrl"> </iframe>
        <v-card-actions class="justify-end">
          <v-btn text @click="showVideo = false">{{ $t('close') }}</v-btn>
        </v-card-actions>
      </v-sheet>
    </v-dialog>
    <v-row>
      <v-col>
        <v-menu v-if="demoFeature" offset-y>
          <template #activator="{ on }">
            <v-btn color="primary" v-on="on">{{ $t('newMessage') }}<v-icon right>mdi-menu-down</v-icon></v-btn>
          </template>
          <v-list>
            <v-list-item v-if="videoConfigured" @click="showVideo = !showVideo">
              <v-list-item-icon><v-icon>mdi-video</v-icon></v-list-item-icon>
              <v-list-item-title>{{ $t('newVideo') }}</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="chatConfigured" @click="showChat = !showChat">
              <v-list-item-icon><v-icon>mdi-chat</v-icon></v-list-item-icon>
              <v-list-item-title>{{ $t('newChat') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <DataGrid :items="messages" :columns="columns" :show-select="false" :items-per-page="25">
      <template #item.activeProximite="{ item }">
        <v-simple-checkbox v-model="item.activeProximite" disabled />
      </template>

      <template #item.actions="{ item }">
        <v-row>
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                ref="answerResidentMessage"
                small
                icon
                v-bind="attrs"
                @click="answerResidentMessage(item.id)"
                v-on="on"
              >
                <v-icon small>mdi-message-reply</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('seeBtnTooltip') }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn ref="videoCallPatient" small icon v-bind="attrs" v-on="on">
                <v-icon small>mdi-video</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('videoCall') }}</span>
          </v-tooltip>
        </v-row>

        <!-- <span>{{ $t('answer') }}</span> -->
      </template>
    </DataGrid>
    <ResidentMessageModal
      :show="showEditModal"
      :message-id="selectedMessageId"
      @close="showEditModal = false"
      @refresh="init()"
    />
  </div>
</template>

<script>
import DataGrid from '@/components/DataGrid.vue';
import ResidentMessageModal from './ResidentMessageModal.vue';
import virtuoseMixin from '@/virtuoseMixin';
import translationMixin from '@/translationMixin';
import accessibility from '@/accessibilityMixin';
import applicationService from '@/services/applicationService';

export default {
  name: 'ResidentMessageGrid',
  components: { DataGrid, ResidentMessageModal },

  mixins: [translationMixin, virtuoseMixin, accessibility],

  props: {
    patientId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      showEditModal: false,
      showWaitModal: false,
      messages: null,
      selectedMessageId: null,
      maximumMessageLength: 100,
      error: null,
      columns: [
        {
          text: 'senderLastName',
          value: 'senderLastName',
          filterable: true,
        },
        {
          text: 'senderFirstName',
          value: 'senderFirstName',
          filterable: true,
        },

        {
          text: 'activeProximite',
          value: 'activeProximite',
          filterable: true,
        },
        {
          text: 'messageDatetime',
          value: 'messageDatetime',
          filterable: true,
        },
        {
          text: 'messageContent',
          value: 'messageContent',
          filterable: true,
        },
        {
          text: 'responseContent',
          value: 'responseContent',
          filterable: true,
        },
        {
          text: 'responseDatetime',
          value: 'responseDatetime',
          filterable: true,
        },
        {
          text: 'answeredBy',
          value: 'healthworker',
          filterable: true,
        },
        {
          text: this.$t('actions'),
          value: 'actions',
          filterable: false,
          sortable: false,
        },
      ],
      showChat: false,
      showVideo: false,
      demoFeature: false,
      chatUrl: '',
      videoUrl: '',
      chatConfigured: false,
      videoConfigured: false,
    };
  },

  watch: {
    patientId: function () {
      this.loadResidentMessages();
    },
  },

  created: function () {
    this.init();
  },

  methods: {
    init: function () {
      if (this.patientId != null) {
        this.loadResidentMessages();
        this.loadDemoFeatureConfig();
      }
    },

    answerResidentMessage: function (id) {
      this.selectedMessageId = id;
      this.showEditModal = true;
    },

    loadResidentMessages: function () {
      this.showWaitModal = true;

      var successCallback = function (response) {
        this.showWaitModal = false;

        let rawMessages = response.data;
        this.messages = rawMessages.map((r) => {
          var message = Object.assign({}, r);
          message.healthworker = r.healthworker ? r.healthworker.firstName + ' ' + r.healthworker.lastName : '';
          message.messageContent =
            r.messageContent.length > this.maximumMessageLength
              ? r.messageContent.substring(0, this.maximumMessageLength) + '...'
              : r.messageContent;

          message.responseContent =
            r.responseContent.length > this.maximumMessageLength
              ? r.responseContent.substring(0, this.maximumMessageLength) + '...'
              : r.responseContent;
          return message;
        });
      };

      let errorCallback = (error) => {
        this.error = error;
      };

      this.getMessages(this.patientId, successCallback, errorCallback);
    },
    async loadDemoFeatureConfig() {
      try {
        let demoFeatureConfig = await applicationService.getDemoFeatureConfig();
        this.demoFeature = demoFeatureConfig.demoFeature;
        this.chatUrl = demoFeatureConfig.chatUrl;
        this.videoUrl = demoFeatureConfig.videoUrl;
        this.chatConfigured = this.chatUrl.length > 0;
        this.videoConfigured = this.videoUrl.length > 0;
        if (!this.chatConfigured && !this.videoConfigured) {
          this.demoFeature = false;
        }
      } catch (error) {
        this.error = error;
      }
    },
  },
};
</script>

<style scoped></style>
